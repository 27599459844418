import styled from "styled-components";
import { StyledProps } from "./types";
import Text from "../Text";

export const ToggleContainer = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ size }) => (size === "large" ? "42px" : "28px")};
  height: ${({ size }) => (size === "large" ? "24px" : "16px")};
  border-radius: 16px;
  background-color: ${({ checked, colors }) =>
    checked ? colors.backgroundChecked : colors.background};
  border: 1px solid ${({ colors }) => colors.border};
  cursor: pointer;
  outline-color: ${({ colors }) => colors.focus};

  &:hover {
    background-color: ${({ checked, colors }) =>
      checked ? colors.hoverChecked : colors.hover};
  }
`;

export const ToggleButton = styled.div<StyledProps>`
  width: ${({ size }) => (size === "large" ? "18px" : "12px")};
  height: ${({ size }) => (size === "large" ? "18px" : "12px")};
  border-radius: 50%;
  background-color: ${({ checked, colors }) =>
    checked ? colors.buttonChecked : colors.button};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  transform: ${({ checked, size }) => {
    const start = size === "large" ? "2px" : "1px";
    const end = size === "large" ? "20px" : "13px";
    return checked ? `translateX(${end})` : `translateX(${start})`;
  }};
`;

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledText = styled(Text)`
  cursor: pointer;
`;
