import { AxiosStatic } from "axios";

const checkOtp =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (otp: string): Promise<void> => {
    return await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/otp/check`,
      {
        otp,
      },
      {
        withCredentials: true,
      }
    );
  };

export default checkOtp;
