import { theme } from "../../styles/legacy-theme";
import React from "react";
import styled from "styled-components";
import { ButtonStates } from "../Button";
import Button from "../Button/Button.component";
import FormNavigationButton, {
  NavigationDirections,
} from "../FormNavigationButton/FormNavigationButton.component";

const StyledDisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  padding-bottom: 1rem;
  text-align: left;
  color: ${theme.colors.Grey02};
  a {
    :hover {
      text-decoration: underline;
      color: ${theme.colors.Primary03};
    }
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  width: 1.2rem;
  max-width: 1.2rem;
  margin-right: 1rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

const StyledExtendedActions = styled.div`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  padding-top: 2rem;
  text-align: center;

  & * {
  }
`;

const StyledOneArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  display: block;
  color: #bcbcbc;
  font-family: "Inter", sans-serif;
  padding-top: 1rem;
  text-align: center;
  width: 100%;
`;

const StyledTwoArrowsContainer = styled.div`
  display: flex;
  color: #bcbcbc;
  font-family: "Inter", sans-serif;
  padding-top: 1rem;
  width: 100%;
  & button:first-of-type {
    justify-content: flex-start;
    & svg {
      padding-left: 0;
    }
  }

  & button:last-of-type {
    justify-content: flex-end;
    & svg {
      padding-right: 0;
    }
  }
`;

type FormButtonProps = {
  text: string;
  testId?: string;
  active?: boolean;
  type?: "button" | "submit" | "reset";
  disclaimerIcon?: React.ReactNode;
  disclaimerText?: string | React.ReactNode;
  extendedActions?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  leftArrowOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  leftArrowLabel?: string;
  rightArrowOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  rightArrowLabel?: string;
};

const FormButton = ({
  testId,
  text,
  type = "button",
  active = true,
  disclaimerIcon,
  disclaimerText,
  extendedActions,
  onClick,
  leftArrowOnClick,
  leftArrowLabel,
  rightArrowOnClick,
  rightArrowLabel,
}: FormButtonProps): JSX.Element => {
  const ArrowsContainer =
    leftArrowOnClick && rightArrowOnClick
      ? StyledTwoArrowsContainer
      : StyledOneArrowContainer;

  const buttonVersion = active ? ButtonStates.PRIMARY : ButtonStates.INACTIVE;

  return (
    <>
      {disclaimerText && (
        <StyledDisclaimerWrapper>
          <StyledIconWrapper>{disclaimerIcon}</StyledIconWrapper>
          <div data-testid="disclaimer-text">{disclaimerText}</div>
        </StyledDisclaimerWrapper>
      )}

      <Button
        type={type}
        text={text}
        onClick={onClick}
        version={buttonVersion}
        testId={testId}
      />

      {extendedActions ? (
        <StyledExtendedActions data-testid="extended-actions">
          {extendedActions}
        </StyledExtendedActions>
      ) : (
        <ArrowsContainer>
          {leftArrowLabel && (
            <FormNavigationButton
              onClick={leftArrowOnClick}
              text={leftArrowLabel || ""}
              direction={NavigationDirections.BACKWARD}
            />
          )}

          {rightArrowLabel && (
            <FormNavigationButton
              onClick={rightArrowOnClick}
              text={rightArrowLabel || ""}
              direction={NavigationDirections.FORWARD}
            />
          )}
        </ArrowsContainer>
      )}
    </>
  );
};

export default FormButton;
