import { gql } from "@apollo/client";

export const BEQUEST_INDIVIDUAL_DATA = gql`
  fragment BequestIndividualData on BequestIndividualObject {
    id
    emailAddress
    firstName
    lastName
    relationship
    itemsToLeave
    islamicInheritor
    includedAsShare
    money
    message
    partOfInheritance
    percentageEstate
    address {
      id
      addressLine1
      addressLine2
      city
      region
      country
      postcode
    }
  }
`;

export const BEQUEST_CHARITY_DATA = gql`
  fragment BequestCharityData on BequestCharityObject {
    id
    charityID
    fullName
    description
    message
    money
    percentageEstate
    partnerCharity {
      id
    }
    partnerCharityCauses {
      id
      nameOfCause
    }
    address {
      id
      addressLine1
      addressLine2
      city
      region
      country
      postcode
    }
  }
`;

export const BEQUEST_DATA = gql`
  ${BEQUEST_INDIVIDUAL_DATA}
  ${BEQUEST_CHARITY_DATA}
  fragment BequestData on BequestObject {
    id
    memberDisabled
    memberReceivingTestedBenefits
    bequestIndividuals {
      ...BequestIndividualData
    }
    bequestCharity {
      ...BequestCharityData
    }
  }
`;
