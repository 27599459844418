import { createGlobalStyle } from "styled-components";
import { tailwindReset } from "../../styles/tailwind-css-reset";
import { theme } from "@ifgengineering/component-library";

const GlobalStyle = createGlobalStyle`
  ${tailwindReset}
  html {
    background-color: ${theme.colors.SLATE200};
  }
  .Toastify {
    height: 0 !important;
  }
  .Toastify__toast-container {
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  .Toastify__toast {
    overflow-x: hidden;
  }

  span[data-testid="security-layer"] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
  }
`;

export default GlobalStyle;
