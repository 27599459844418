import React from "react";
import styled from "styled-components";
import { formatValue } from "react-currency-input-field";
import { theme } from "../../../../styles/legacy-theme";
import Button, {
  ButtonStates,
} from "../../../../legacyComponents/Button/Button.component";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import DeleteIcon from "../../../../assets/icons/rubbish_can_icon.svg";

const StyledBequestItemsContainer = styled.div`
  display: flex;
  margin-top 25px;
`;

const StyledFamilyRelationship = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
`;

const StyledName = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
`;

const StyledBequestItems = styled.div`
  color: ${theme.colors.Font01};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
`;

const StyledItemsText = styled.div`
  display: -webkit-box;
  max-width: calc(100vw - 150px);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledButton = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  & > button {
    max-width: 70%;
  }
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  height: 20px;
  width: 20px;
  background: none;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

type BequestIndividualCardProps = {
  familyRelationship: string;
  name: string;
  money?: number;
  percentageOfEstate?: number;
  items?: string[];
  addBequests?: () => void;
  editBequests?: () => void;
  deleteBequest?: () => void;
};

const BequestIndividualCard = ({
  familyRelationship,
  name,
  money,
  percentageOfEstate,
  items = [],
  addBequests,
  editBequests,
  deleteBequest,
}: BequestIndividualCardProps): JSX.Element => {
  const noBequests = !money && !percentageOfEstate && !items?.length;
  const buttonCallback = noBequests ? addBequests : editBequests;
  const buttonText = noBequests ? "Add bequests" : "Edit";
  const formattedValue = formatValue({
    value: `${money || 0}`,
    intlConfig: { locale: "en-GB", currency: "GBP" },
  });
  const itemsText = items?.join(", ");

  return (
    <CardContainer>
      <StyledFamilyRelationship>{familyRelationship}</StyledFamilyRelationship>
      <StyledName>{name}</StyledName>
      <StyledBequestItemsContainer>
        {!noBequests && (
          <StyledBequestItems>
            {!!money && <div>{formattedValue}</div>}
            {!!percentageOfEstate && <div>{percentageOfEstate}% of estate</div>}
            <StyledItemsText>{itemsText}</StyledItemsText>
          </StyledBequestItems>
        )}
        <StyledButton>
          <Button
            text={buttonText}
            version={ButtonStates.PRIMARY_GHOST_LARGE}
            onClick={buttonCallback}
          />
          <IconButton onClick={deleteBequest} data-testid="delete-button">
            <DeleteIcon />
          </IconButton>
        </StyledButton>
      </StyledBequestItemsContainer>
    </CardContainer>
  );
};

export default BequestIndividualCard;
