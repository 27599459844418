import * as Yup from "yup";

export const newIndividualSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  addressLine1: Yup.string().required("First line of address is required"),
  city: Yup.string().required("City is required"),
  postcode: Yup.string().required("Postcode is required"),
  relationship: Yup.string().required("Relationship is required"),
});

export const existingInheritor = Yup.object().shape({
  addressLine1: Yup.string().required("First line of address is required"),
  city: Yup.string().required("City is required"),
  postcode: Yup.string().required("Postcode is required"),
  emailAddress: Yup.string().nullable().email("Invalid email"),
});

export const newCharitySchema = Yup.object().shape({
  fullName: Yup.string().required("Charity name is required"),
  description: Yup.string().required("Description is required"),
});

const topValue = Yup.number().max(
  33.33,
  "You cannot leave more than 1/3 (33.33%) of your estate as a bequest. Please enter an amount smaller than this."
);

export const leaveBequestSchema = Yup.object().shape(
  {
    money: Yup.mixed().when(
      ["percentageEstate", "itemsToLeave"],
      (percentageEstate: number, itemsToLeave: string[]) => {
        if (!percentageEstate && !itemsToLeave?.length) {
          return Yup.number()
            .nullable()
            .moreThan(0, "Please enter at least one bequest");
        }

        return Yup.number().nullable();
      }
    ),
    percentageEstate: Yup.number().when(
      ["money", "itemsToLeave"],
      (money: number, itemsToLeave: string[]) => {
        if (!money && !itemsToLeave?.length) {
          return topValue.moreThan(0, "Please enter at least one bequest");
        }

        return topValue;
      }
    ),
    itemsToLeave: Yup.array().when(
      ["money", "percentageEstate"],
      (money: number, percentageEstate: number) => {
        if (!money && !percentageEstate) {
          return Yup.array().min(1, "Please enter at least one bequest");
        }

        return Yup.array().nullable();
      }
    ),
  },
  [
    ["percentageEstate", "itemsToLeave"],
    ["money", "itemsToLeave"],
    ["money", "percentageEstate"],
  ]
);
