const theme = {
  colors: {
    B50: "#F3F6FA",
    BLACK: "#000000",
    BLUE100: "#DBE3FF",
    BLUE200: "#D8DEF3",
    BLUE300: "#B2BBFF",
    BLUE600: "#305CFF",
    BLUE700: "#062AAD",
    BLUE800: "#001C80",
    BLUE900: "#01114C",
    BLUE999: "#010C34",
    CAUTION100: "#FFF8DB",
    CAUTION200: "#FFF0B3",
    CAUTION300: "#B8A200",
    CAUTION800: "#756800",
    CAUTION900: "#4D4400",
    ERROR100: "#FFE7E0",
    ERROR200: "#FFBEAD",
    ERROR300: "#FF9175",
    ERROR500: "#FF542E",
    ERROR800: "#941E00",
    G50: "#E3FCEF",
    G75: "#ADF5D2",
    G100: "#78F2BF",
    G200: "#59D9A4",
    G300: "#36B580",
    G400: "#008558",
    G500: "#006644",
    GOLD100: "#F9F8F6",
    GOLD200: "#F1E9D5",
    GOLD500: "#D1BA82",
    GOLD800: "#7F6629",
    GOLD900: "#7F6629",
    GREEN100: "#ECFBEA",
    GREEN200: "#D8F7D4",
    GREEN300: "#B6F0AD",
    GREEN400: "#82FF6F",
    GREEN500: "#46DA2F",
    GREEN600: "#33B21F",
    GREEN800: "#278717",
    INDIGO100: "#F5F6FF",
    INDIGO200: "#E5E8FF",
    INDIGO500: "#B2BBFF",
    INDIGO900: "#000F80",
    P50: "#EAE6FF",
    P75: "#BFB5F2",
    P100: "#988CD9",
    P200: "#8878D9",
    P300: "#6454C0",
    P400: "#5243A8",
    P500: "#413394",
    PEBBLE100: "#F9F7F6",
    PEBBLE200: "#F0E9E3",
    PEBBLE400: "#DCD3CB",
    PEBBLE500: "#C8B9AC",
    PEBBLE600: "#846B58",
    PEBBLE700: "#6B5747",
    PEBBLE800: "#4C3E33",
    PEBBLE900: "#2D261F",
    PINK100: "#FFF5FF",
    PINK200: "#FFE0FF",
    PINK500: "#FEA9FF",
    PINK800: "#A600A8",
    PINK900: "#310132",
    R50: "#FFEBE6",
    R75: "#FFBEAD",
    R100: "#FF9175",
    R200: "#FF7452",
    R300: "#FF542E",
    R400: "#E0350B",
    R500: "#BD2600",
    SLATE100: "#F7F9FA",
    SLATE200: "#EAEEF1",
    SLATE300: "#D4DADE",
    SLATE400: "#B7C1C8",
    SLATE450: "#9AA8B1",
    SLATE500: "#7C8F9C",
    SLATE600: "#5F7686",
    SLATE700: "#435D70",
    SLATE800: "#26445A",
    SLATE900: "#192A36",
    SUCCESS200: "#C8F9E1",
    SUCCESS300: "#59D9A4",
    SUCCESS500: "#00DB92",
    SUCCESS800: "#00754E",
    T50: "#E6FCFF",
    T75: "#B3F5FF",
    T100: "#78E2F2",
    T200: "#00C7E6",
    T300: "#00BADB",
    T400: "#00A0BD",
    T500: "#008FA8",
    WHITE: "#FFFFFF",
    Y50: "#FFFAE6",
    Y75: "#FFF0B3",
    Y100: "#FFE380",
    Y200: "#FFC400",
    Y300: "#FFAA00",
    Y400: "#FF9A1F",
    Y500: "#FF8C00",
    transparent: "transparent",
    initial: "initial",
    inherit: "inherit",
    none: "none",
  },
};

export { theme };

export type ColorType = keyof typeof theme.colors;
