import styled from "styled-components";
import {
  RadioInputDirection,
  RadioInputGroupProps,
} from "../RadioInputGroup.component";

const StyledRadioInputGroup = styled.div<RadioInputGroupProps>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === RadioInputDirection.VERTICAL ? "column" : "row"};
  gap: ${({ gap }) => gap + "px"};
`;
export default StyledRadioInputGroup;
