import { GuardianInfo, WillsState } from "../constants/types";

const updateGuardiansReducer = (state: WillsState, payload: GuardianInfo) => {
  const { guardianList } = state.will.overseer;
  const guardianExists = guardianList.find((item) => item.id === payload.id);
  if (guardianExists) {
    state.will.overseer.guardianList = guardianList.map((guard) =>
      guard.id === payload.id ? payload : guard
    );
  } else {
    state.will.overseer.guardianList = [...guardianList, payload];
  }
};

export default updateGuardiansReducer;
