import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { Form } from "formik";
import styled from "styled-components";

export const FormikForm = styled(Form)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0 20px 20px 20px;
  }
`;
