import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { VERSION, SENTRY_DSN } from "gatsby-env-variables";
import { CurrencyContextProvider } from "../components/CurrencyContext/index";
import GlobalStyle from "./styles/global-styles";
import { theme } from "./styles/theme";
import store, { persistor } from "./store";
import { client } from "../graphql";
import { initSentry } from "../helpers/logException";
import axios from "axios";

const Startup = ({ element }) => {
  console.log("VERSION:", VERSION);
  initSentry(SENTRY_DSN);

  useEffect(() => {
    axios.defaults.headers.post["x-application-id"] = "web-app";
    axios.defaults.headers.common["x-application-id"] = "web-app";
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <title>IFG Wills</title>
          <meta
            name="description"
            content="Islamic Finance Guru Halal Investment Platform"
          />
          <html lang="en-GB" />
          <script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            async
          />
        </Helmet>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CurrencyContextProvider>{element}</CurrencyContextProvider>
          </ThemeProvider>
          <ToastContainer />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <Startup element={element} />;
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  );
};

export default { wrapRootElement, wrapPageElement };
