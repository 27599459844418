import { Formik } from "formik";
import React from "react";
import BequestAllocationPerCent from "../../../../components/Cards/BequestAllocationPerCent/BequestAllocationPerCent.component";
import BequestAllocationMoney from "../../../../components/Cards/BequestAllocationMoney/BequestAllocationMoney.component";
import Input from "../../../../../legacyComponents/Form/input/Input/Input.component";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import { Container, PageTexts, AllocationsContainer } from "../styles";
import { leaveBequestSchema } from "../formValidations";
import BequestAllocationItem from "../../../../components/Cards/BequestAllocationItem/BequestAllocationItem.component";

export type WhatWouldYouLikeToLeaveBequest = {
  percentageEstate?: number;
  money?: number;
  message?: string;
  itemsToLeave?: string[];
};

type Props = {
  bequest: WhatWouldYouLikeToLeaveBequest;
  charityName: string;
  onSubmit: (bequest: WhatWouldYouLikeToLeaveBequest) => void;
  showItems?: boolean;
  leftArrowOnClick: () => void;
};

const formDefaultValue: WhatWouldYouLikeToLeaveBequest = {
  percentageEstate: 0,
  money: 0,
};

const WhatWouldYouLikeToLeave: React.FC<Props> = ({
  bequest,
  onSubmit,
  charityName,
  leftArrowOnClick,
  showItems,
}) => {
  const { money, percentageEstate, message, itemsToLeave } = bequest;

  return (
    <Formik
      initialValues={{
        ...formDefaultValue,
        // Prevent set value and percentageEstate as null
        ...(money ? { money } : {}),
        ...(percentageEstate ? { percentageEstate } : {}),
        message,
        // Formik won't consider itemsToLeave as a item to validate if its value is undefined
        itemsToLeave: !itemsToLeave && showItems ? [] : itemsToLeave,
      }}
      validationSchema={leaveBequestSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, setFieldValue, values, submitForm }) => (
        <Container>
          <PageTexts
            heading={`What would you like to leave ${charityName}?`}
            subheading="You can leave multiple kinds of gifts. You can either express it as a sum of money or a percentage of your estate. Remember, you can’t go over 33%!"
            help
          />
          <AllocationsContainer>
            <BequestAllocationMoney
              title="Some money"
              value={values.money?.toString() || "0"}
              onChange={(value) => setFieldValue("money", Number(value))}
              errorMessage={errors.money ? errors.money : ""}
            />
            <BequestAllocationPerCent
              title="% of your estate"
              percentageValue={values.percentageEstate}
              onChange={(value) =>
                setFieldValue("percentageEstate", Number(value))
              }
              errorMessage={
                errors.percentageEstate ? errors.percentageEstate : ""
              }
            />
            {showItems && (
              <BequestAllocationItem
                title="Items"
                description="You can add multiple items by a comma separation. Click on Add Items to confirm your entry."
                fieldPlaceholder="wedding ring, vintage watch, etc.."
                buttonText="Add Items"
                items={values.itemsToLeave?.join(", ")}
                onAddItem={(item) => {
                  if (item) {
                    setFieldValue("itemsToLeave", item.split(","));
                  } else {
                    setFieldValue("itemsToLeave", []);
                  }
                }}
                errorMessage={errors.itemsToLeave ? errors.itemsToLeave : ""}
              />
            )}
            <div>
              <Input
                heading="Leave a message (optional)"
                isLong
                placeholder="Enter your message"
                value={values.message || ""}
                onChange={(e) => setFieldValue("message", e.target.value)}
              />
            </div>
            <FormButton
              text="Save & Continue"
              leftArrowLabel="Back"
              onClick={submitForm}
              leftArrowOnClick={leftArrowOnClick}
            />
          </AllocationsContainer>
        </Container>
      )}
    </Formik>
  );
};

export default WhatWouldYouLikeToLeave;
