import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  FETCH_ZAKAT,
  CREATE_ZAKAT,
  UPDATE_ZAKAT,
  FETCH_OR_CREATE_ZAKAT,
} from "../constants/actionTypes";
import { ZakatState } from "../reducers/reducers";
import { CreateZakatInput, PartialZakatInput } from "../types";
import { logException } from "@helpers/logException";

export const fetchZakatAPI = createAsyncThunk<ZakatState, string>(
  FETCH_ZAKAT,
  async (email) => {
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query ($email: String!) {
            findZakatByEmail(userEmail: $email) {
              id
              topUp
              amountDue
              nisab
              amountAllocated
              allocation {
                id
                percentage
                amount
              }
              giftAid {
                id
                title
                firstName
                lastName
                addressLine1
                addressLine2
                city
                postCode
              }
            }
          }
        `,
        variables: {
          email,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.findZakatByEmail;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");
      Sentry.captureException(e);
      throw Error(e);
    }
  }
);

export const createZakatAPI = createAsyncThunk<
  ZakatState,
  {
    data: ZakatState & { currency: "gbp" | "ngn" | "usd" };
    email: string;
  }
>(CREATE_ZAKAT, async ({ data, email }) => {
  try {
    const response = await client.mutate({
      fetchPolicy: "no-cache",
      mutation: gql`
        mutation ($data: CreateZakatInput!, $email: String!) {
          createZakat(data: $data, userEmail: $email) {
            id
            topUp
            amountDue
            nisab
            amountAllocated
            allocation {
              id
              percentage
              amount
            }
            giftAid {
              id
              title
              firstName
              lastName
              addressLine1
              addressLine2
              city
              postCode
            }
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.createZakat;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createZakatAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const findOrCreateZakatAPI = createAsyncThunk<
  ZakatState,
  { data: CreateZakatInput; email: string }
>(FETCH_OR_CREATE_ZAKAT, async ({ data, email }) => {
  try {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query ($data: CreateZakatInput!, $email: String!) {
          findZakatOrCreate(data: $data, userEmail: $email) {
            id
            topUp
            amountDue
            nisab
            amountAllocated
            allocation {
              id
              percentage
              amount
            }
            giftAid {
              id
              title
              firstName
              lastName
              addressLine1
              addressLine2
              city
              postCode
            }
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.findZakatOrCreate;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "findOrCreateZakatAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateZakatAPI = createAsyncThunk<
  ZakatState,
  {
    data: PartialZakatInput;
    email: string;
  }
>(UPDATE_ZAKAT, async ({ data, email }) => {
  try {
    const response = await client.mutate({
      fetchPolicy: "no-cache",
      mutation: gql`
        mutation ($data: PartialZakatInput!, $email: String!) {
          updateZakat(data: $data, userEmail: $email) {
            id
            topUp
            amountDue
            nisab
            amountAllocated
            allocation {
              id
              percentage
              amount
            }
            giftAid {
              id
              title
              firstName
              lastName
              addressLine1
              addressLine2
              city
              postCode
            }
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.updateZakat;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "updateZakatAPI",
      exception: e,
    });

    throw Error(e);
  }
});
