import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PageTexts } from "../../styles";
import Causes from "../../../../../components/Causes/Causes.component";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import BequestAllocationPerCent from "../../../../../components/Cards/BequestAllocationPerCent/BequestAllocationPerCent.component";
import { showBQPage } from "@willsStore/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import { getCharity } from "@components/Charity/selectors";
import {
  createCharityBequestAPI,
  updateCharityBequestAPI,
} from "@components/Wills/actions/bequest";
import { getUserEmail } from "@components/Login/selectors";
import { bqCharitiesSelector } from "@components/Wills/selectors";
import {
  bqAddCharityToList,
  bqUpdateCharityInList,
} from "@components/Wills/actions";
import store from "@Wills/store";
import { theme } from "@willsTheme";

const SponsoredCharityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3em;
  align-items: center;
`;

const CharityLogoContainer = styled.div`
  width: 300px;
  height: 150px;
`;

const CharityLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const HeadingTextContainer = styled.div`
  max-width: 327px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5em;
  max-width: 320px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  gap: 0.5em;
  width: 327px;
`;

const SkipButton = styled.button`
  color: ${theme.colors.Primary01};
  text-decoration-color: ${theme.colors.Primary01};
  text-decoration: underline;
`;

const ERROR_MESSAGE =
  "You cannot leave more than 1/3 (33.33%) of your estate as a bequest. Please enter an amount smaller than this.";

const toFloat = (arr: string[]) => arr && arr.map((x) => parseFloat(x));

const SponsoredCharity: React.FC = () => {
  const { SPONSORED_CHARITY_DONATION } = GiftsAndBequestPageEnum;
  const charity = useSelector(getCharity);
  const bequestCharities = useSelector(bqCharitiesSelector);

  const existingPartnerBequest =
    bequestCharities &&
    bequestCharities.find((bq) => bq?.partnerCharity?.id === charity.id);

  const preSelectedCauses =
    existingPartnerBequest &&
    existingPartnerBequest.partnerCharityCauses.map((cause) => cause.id);

  const [value, setValue] = useState(
    existingPartnerBequest?.percentageEstate || 0
  );
  const [selectedCauses, setSelectedCauses] = useState<Set<string>>(
    preSelectedCauses ? new Set(preSelectedCauses) : new Set()
  );
  const [showErrMsg, setShowErrMsg] = useState(false);
  const userEmail = useSelector(getUserEmail);

  const handleChange = (val: string) => {
    const convertedValue = parseFloat(val || "0");
    setValue(convertedValue);

    if (convertedValue > 33.33) {
      setShowErrMsg(true);
    } else {
      setShowErrMsg(false);
    }
  };

  const dispatch = useDispatch<typeof store.dispatch>();

  const handleSelect = (causeId?: string) => {
    if (!causeId) {
      return;
    }

    const selectedCausesCopy = new Set(selectedCauses);
    if (selectedCausesCopy.has(causeId)) {
      selectedCausesCopy.delete(causeId);
      setSelectedCauses(selectedCausesCopy);
    } else {
      selectedCausesCopy.add(causeId);
      setSelectedCauses(selectedCausesCopy);
    }
  };
  const handleSubmit = async () => {
    dispatch(showBQPage(GiftsAndBequestPageEnum.LANDING));
    if (existingPartnerBequest) {
      const responseData = await dispatch(
        updateCharityBequestAPI({
          partnerCharityCauseIds: toFloat([...selectedCauses]),
          partnerCharityId: charity.id,
          data: {
            id: existingPartnerBequest.id,
            percentageEstate: value,
          },
        })
      ).unwrap();
      dispatch(bqUpdateCharityInList(responseData));
    } else {
      const responseData = await dispatch(
        createCharityBequestAPI({
          email: userEmail,
          partnerCharityCauseIds: toFloat([...selectedCauses]),
          partnerCharityId: charity.id,
          data: { percentageEstate: value },
        })
      ).unwrap();
      dispatch(bqAddCharityToList(responseData));
    }
  };

  return (
    <SponsoredCharityContainer>
      <ContentContainer>
        <CharityLogoContainer>
          <CharityLogo
            src={charity.logoKey}
            alt={charity.displayName + " logo"}
          />
        </CharityLogoContainer>
        <HeadingTextContainer>
          <PageTexts
            heading={`${charity.displayName} has kindly sponsored your will. A bequest from you today will echo throughout time.`}
            subheading="Bequests are a Sadaqah Jariyah for you with the potential to helps thousands after you pass away."
          />
        </HeadingTextContainer>
        <BequestAllocationPerCent
          percentageValue={value}
          title="How much would you like to gift?"
          onChange={handleChange}
          errorMessage={showErrMsg ? ERROR_MESSAGE : undefined}
        />
      </ContentContainer>
      <Causes
        data={charity.causesList}
        handleSelect={handleSelect}
        selectedCauses={selectedCauses}
      />
      <ContentContainer>
        <ButtonsContainer>
          <FormButton
            active={!!value && !showErrMsg}
            text="Next"
            leftArrowLabel="Back"
            onClick={handleSubmit}
            leftArrowOnClick={() => {
              dispatch(showBQPage(GiftsAndBequestPageEnum.LANDING));
            }}
          />
          {!existingPartnerBequest && (
            <SkipButton
              onClick={() => dispatch(showBQPage(SPONSORED_CHARITY_DONATION))}
            >
              Skip
            </SkipButton>
          )}
        </ButtonsContainer>
      </ContentContainer>
    </SponsoredCharityContainer>
  );
};

export default SponsoredCharity;
