import { ButtonsContainer, Spacer, Text } from "../../../signup/styled";
import { Field, Formik } from "formik";
import { ContactSchema } from "../constants/validation";
import Input from "../../../../../legacyComponents/Form/input/Input/Input.component";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import React from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { AboutYouChild, AboutYouPages } from "../index";
import { navigate } from "@reach/router";
import { onUpdateUserInfo } from "../../../../../components/Wills/actions";
import { useDispatch } from "react-redux";
import AddressFields from "../../../../components/AddressFields";
import { submitUserInfo } from "../../../../../components/Wills/actions/submitUserInfo";
import { FormikForm } from "../../../../../legacyComponents/Form/input/styled/Form";
import store from "../../../../store";

export const Separator = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.Grey01};
  margin: 2rem 0;
`;

export const Link = styled.div`
  text-align: right;
  font-size: 14px;
  color: ${theme.colors.BrandBlue};
  cursor: pointer;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: {
      addressLine1: string;
      city: string;
      postcode: string;
      mobileNumber: string;
    };
    touched: {
      addressLine1: boolean;
      city: boolean;
      postcode: boolean;
      mobileNumber: boolean;
    };
  };
};

const Contact: React.FC<AboutYouChild> = ({ setPage, initialState }) => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const handleSubmit = async (values: {
    addressLine1: any;
    addressLine2: any;
    city: any;
    postcode: any;
    mobileNumber: any;
  }) => {
    const { addressLine1, addressLine2, city, postcode, mobileNumber } = values;

    const userInfo = await dispatch(
      submitUserInfo({
        address: { addressLine1, addressLine2, city, postcode },
        mobileNumber,
      })
    ).unwrap();

    navigate("/app/wills");
    dispatch(onUpdateUserInfo(userInfo));
  };

  return (
    <>
      <Text align="left">Your contact details</Text>
      <Formik
        enableReinitialize
        validationSchema={ContactSchema}
        initialValues={{
          addressLine1: initialState?.address?.addressLine1 || "",
          addressLine2: initialState?.address?.addressLine2 || "",
          city: initialState?.address?.city || "",
          postcode: initialState?.address?.postcode || "",
          mobileNumber: initialState?.mobileNumber || "",
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <FormikForm>
            <AddressFields isOpen={!!initialState?.address?.addressLine1} />
            <Separator />
            <Field name="mobileNumber">
              {({ field, form: { errors, touched } }: FieldType) => (
                <Spacer>
                  <Input
                    heading="Phone number"
                    description="We'll only call to help you with your Will"
                    errorMessage={
                      touched.mobileNumber ? errors.mobileNumber : undefined
                    }
                    placeholder="07..."
                    {...field}
                  />
                </Spacer>
              )}
            </Field>

            <ButtonsContainer>
              <FormButton
                type="submit"
                text="Save & Continue"
                leftArrowOnClick={() => setPage(AboutYouPages.general)}
                leftArrowLabel={"Back"}
              />
            </ButtonsContainer>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default Contact;
