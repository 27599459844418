import { createReducer } from "@reduxjs/toolkit";
import { setPage, setTrackPage, updateProducts } from "./discover.actions";
import { DiscoverState } from "./discover.types";

export const initialState: DiscoverState = {
  loading: false,
  page: "track",
  trackPage: "track",
  products: [],
};

export const discoverReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPage, (state, { payload }) => ({
      ...state,
      page: payload,
    }))
    .addCase(setTrackPage, (state, { payload }) => ({
      ...state,
      trackPage: payload,
    }))
    .addCase(updateProducts, (state, { payload }) => ({
      ...state,
      products: payload,
    }));
});
