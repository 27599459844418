import { createReducer } from "@reduxjs/toolkit";
import {
  clearStore,
  isLoggedInAction,
  loginAction,
  logoutAction,
  updateUserInfo,
} from "../../../components/Login/actions";
import { initialState } from "../../../components/Login/constants/initialState";
import {
  authResetStateReducer,
  authFulfilledReducer,
  authPendingReducer,
  authRejectedReducer,
} from "../../../components/Login/reducers";
import { fetchUserAPI } from "../user/user.actions";
import { updateAppRecord } from "@components/Login/actions";

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginAction.pending, authPendingReducer)
    .addCase(loginAction.rejected, authRejectedReducer)
    .addCase(loginAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.rejected, authResetStateReducer)
    .addCase(logoutAction.pending, authPendingReducer)
    .addCase(logoutAction.rejected, authRejectedReducer)
    .addCase(logoutAction.fulfilled, authResetStateReducer)
    .addCase(clearStore, authResetStateReducer)
    .addCase(updateUserInfo, authFulfilledReducer)
    .addCase(fetchUserAPI.fulfilled, authFulfilledReducer)
    .addCase(updateAppRecord, (state, action) => {
      if (state.user.appRecord) {
        state.user.appRecord[action.payload.app] = true;
      } else {
        state.user.appRecord = {
          [action.payload.app]: true,
        };
      }
    });
});

export default authReducer;
