import styled from "styled-components";
import { theme } from "../../styles";

export const PasswordInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const PasswordIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  cursor: pointer;
`;

type InnerBarProps = {
  hasError?: boolean;
  isValid?: boolean;
};

export const Bar = styled.div`
  color: ${theme.colors.SLATE700};
  width: 100%;
  height: 0.25rem;
  border-radius: 8px;
  background-color: ${theme.colors.SLATE200};
  margin: 0.5rem 0 0 0;
`;

export const InnerBar = styled.div<InnerBarProps>`
  color: ${theme.colors.SLATE700};
  height: 0.25rem;
  width: ${({ hasError, isValid }) =>
    hasError || isValid ? "100%" : "0.25rem"};
  border-radius: 8px;
  background-color: ${({ hasError }) =>
    hasError ? theme.colors.ERROR800 : theme.colors.BLUE600};
  margin: 0.5rem 0 0 0;
`;
