import React from "react";

export const validateNumerical = (
  e: React.KeyboardEvent<HTMLInputElement>
): void => {
  (e.key === "e" ||
    e.key === "E" ||
    e.key === "+" ||
    e.key === "-" ||
    e.key === "ArrowUp" ||
    e.key === "ArrowDown") &&
    e.preventDefault();
};

export default { validateNumerical };
