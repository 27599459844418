export const FETCH_PORTFOLIO_API = "PORTFOLIO:FETCH_PORTFOLIO_API";
export const CREATE_PORTFOLIO_API = "PORTFOLIO:CREATE_PORTFOLIO_API";

export const UPDATE_PORTFOLIO = "PORTFOLIO:UPDATE_PORTFOLIO";

export const FETCH_FULL_PORTFOLIO = "PORTFOLIO:FETCH_FULL_PORTFOLIO";

export const UPDATE_CASH = "PORTFOLIO:UPDATE_CASH";

export const CREATE_CASH_API = "PORTFOLIO:CREATE_CASH_API";
export const UPDATE_CASH_API = "PORTFOLIO:UPDATE_CASH_API";

export const UPDATE_BANK_ACCOUNTS = "PORTFOLIO:UPDATE_BANK_ACCOUNTS";
export const UPDATE_BANK_ACCOUNT_ITEM = "PORTFOLIO:UPDATE_BANK_ACCOUNT_ITEM";
export const DELETE_BANK_ACCOUNT_ITEM = "PORTFOLIO:DELETE_BANK_ACCOUNT_ITEM";

export const CREATE_BANK_ACCOUNT_API = "PORTFOLIO:CREATE_BANK_ACCOUNT_API";
export const UPDATE_BANK_ACCOUNT_API = "PORTFOLIO:UPDATE_BANK_ACCOUNT_API";
export const DELETE_BANK_ACCOUNT_API = "PORTFOLIO:DELETE_BANK_ACCOUNT_API";

export const DELETE_COMMODITY = "PORTFOLIO:DELETE_COMMODITY";
export const UPDATE_COMMODITY = "PORTFOLIO:UPDATE_COMMODITY";
export const UPDATE_COMMODITIES = "PORTFOLIO:UPDATE_COMMODITIES";

export const CREATE_COMMODITY_API = "PORTFOLIO:CREATE_COMMODITY_API";
export const UPDATE_COMMODITY_API = "PORTFOLIO:UPDATE_COMMODITY_API";
export const DELETE_COMMODITY_API = "PORTFOLIO:DELETE_COMMODITY_API";

export const DELETE_DEBT = "PORTFOLIO:DELETE_DEBT";
export const UPDATE_DEBT = "PORTFOLIO:UPDATE_DEBT";
export const UPDATE_DEBTS = "PORTFOLIO:UPDATE_DEBTS";

export const CREATE_DEBT_API = "PORTFOLIO:CREATE_DEBT_API";
export const UPDATE_DEBT_API = "PORTFOLIO:UPDATE_DEBT_API";
export const DELETE_DEBT_API = "PORTFOLIO:DELETE_DEBT_API";

export const DELETE_CHILD_TRUST = "PORTFOLIO:DELETE_CHILD_TRUST";
export const UPDATE_CHILD_TRUST = "PORTFOLIO:UPDATE_CHILD_TRUST";
export const UPDATE_CHILD_TRUSTS = "PORTFOLIO:UPDATE_CHILD_TRUSTS";

export const CREATE_CHILD_TRUST_API = "PORTFOLIO:CREATE_CHILD_TRUST_API";
export const UPDATE_CHILD_TRUST_API = "PORTFOLIO:UPDATE_CHILD_TRUST_API";
export const DELETE_CHILD_TRUST_API = "PORTFOLIO:DELETE_DEBT_API";

export const DELETE_FUND = "PORTFOLIO:DELETE_STOCK";
export const UPDATE_FUND = "PORTFOLIO:UPDATE_STOCK";
export const UPDATE_FUNDS = "PORTFOLIO:UPDATE_FUNDS";

export const CREATE_FUND_API = "PORTFOLIO:CREATE_FUND_API";
export const UPDATE_FUND_API = "PORTFOLIO:UPDATE_FUND_API";
export const DELETE_FUND_API = "PORTFOLIO:DELETE_FUND_API";

export const DELETE_SHARE = "PORTFOLIO:DELETE_SHARE";
export const UPDATE_SHARE = "PORTFOLIO:UPDATE_SHARE";
export const UPDATE_SHARES = "PORTFOLIO:UPDATE_SHARES";

export const CREATE_SHARE_API = "PORTFOLIO:CREATE_SHARE_API";
export const UPDATE_SHARE_API = "PORTFOLIO:UPDATE_SHARE_API";
export const DELETE_SHARE_API = "PORTFOLIO:DELETE_SHARE_API";

export const DELETE_AGRICULTURE = "PORTFOLIO:DELETE_AGRICULTURE";
export const UPDATE_AGRICULTURE = "PORTFOLIO:UPDATE_AGRICULTURE";
export const UPDATE_AGRICULTURES = "PORTFOLIO:UPDATE_AGRICULTURES";

export const CREATE_AGRICULTURE_API = "PORTFOLIO:CREATE_AGRICULTURE_API";
export const UPDATE_AGRICULTURE_API = "PORTFOLIO:UPDATE_AGRICULTURE_API";
export const DELETE_AGRICULTURE_API = "PORTFOLIO:DELETE_AGRICULTURE_API";

export const DELETE_CRYPTO = "PORTFOLIO:DELETE_CRYPTO";
export const UPDATE_CRYPTO = "PORTFOLIO:UPDATE_CRYPTO";
export const UPDATE_CRYPTOS = "PORTFOLIO:UPDATE_CRYPTOS";

export const CREATE_CRYPTO_API = "PORTFOLIO:CREATE_CRYPTO_API";
export const UPDATE_CRYPTO_API = "PORTFOLIO:UPDATE_CRYPTO_API";
export const DELETE_CRYPTO_API = "PORTFOLIO:DELETE_CRYPTO_API";

export const DELETE_STARTUP = "PORTFOLIO:DELETE_STARTUP";
export const UPDATE_STARTUP = "PORTFOLIO:UPDATE_STARTUP";
export const UPDATE_STARTUPS = "PORTFOLIO:UPDATE_STARTUPS";

export const CREATE_STARTUP_API = "PORTFOLIO:CREATE_STARTUP_API";
export const UPDATE_STARTUP_API = "PORTFOLIO:UPDATE_STARTUP_API";
export const DELETE_STARTUP_API = "PORTFOLIO:DELETE_STARTUP_API";

export const DELETE_ALTERNATIVE_INVESTMENT =
  "PORTFOLIO:DELETE_ALTERNATIVE_INVESTMENT";
export const UPDATE_ALTERNATIVE_INVESTMENT =
  "PORTFOLIO:UPDATE_ALTERNATIVE_INVESTMENT";
export const UPDATE_ALTERNATIVE_INVESTMENTS =
  "PORTFOLIO:UPDATE_ALTERNATIVE_INVESTMENTS";

export const CREATE_ALTERNATIVE_INVESTMENT_API =
  "PORTFOLIO:CREATE_ALTERNATIVE_INVESTMENT_API";
export const UPDATE_ALTERNATIVE_INVESTMENT_API =
  "PORTFOLIO:UPDATE_ALTERNATIVE_INVESTMENT_API";
export const DELETE_ALTERNATIVE_INVESTMENT_API =
  "PORTFOLIO:DELETE_ALTERNATIVE_INVESTMENT_API";

export const DELETE_OWED_ASSET = "PORTFOLIO:DELETE_OWED_ASSET";
export const UPDATE_OWED_ASSET = "PORTFOLIO:UPDATE_OWED_ASSET";
export const UPDATE_OWED_ASSETS = "PORTFOLIO:UPDATE_OWED_ASSETS";

export const CREATE_OWED_ASSET_API = "PORTFOLIO:CREATE_OWED_ASSET_API";
export const UPDATE_OWED_ASSET_API = "PORTFOLIO:UPDATE_OWED_ASSET_API";
export const DELETE_OWED_ASSET_API = "PORTFOLIO:DELETE_OWED_ASSET_API";

export const DELETE_BUSINESS = "PORTFOLIO:DELETE_BUSINESS";
export const UPDATE_BUSINESS = "PORTFOLIO:UPDATE_BUSINESS";
export const UPDATE_BUSINESSES = "PORTFOLIO:UPDATE_BUSINESSES";

export const CREATE_BUSINESS_API = "PORTFOLIO:CREATE_BUSINESS_API";
export const UPDATE_BUSINESS_API = "PORTFOLIO:UPDATE_BUSINESS_API";
export const DELETE_BUSINESS_API = "PORTFOLIO:DELETE_BUSINESS_API";

export const DELETE_PENSION = "PORTFOLIO:DELETE_PENSION";
export const UPDATE_PENSION = "PORTFOLIO:UPDATE_PENSION";
export const UPDATE_PENSIONS = "PORTFOLIO:UPDATE_PENSIONS";

export const CREATE_PENSION_API = "PORTFOLIO:CREATE_PENSION_API";
export const UPDATE_PENSION_API = "PORTFOLIO:UPDATE_PENSION_API";
export const DELETE_PENSION_API = "PORTFOLIO:DELETE_PENSION_API";

export const DELETE_PROPERTY = "PORTFOLIO:DELETE_PROPERTY";
export const UPDATE_PROPERTY = "PORTFOLIO:UPDATE_PROPERTY";
export const UPDATE_PROPERTIES = "PORTFOLIO:UPDATE_PROPERTIES";

export const CREATE_PROPERTY_API = "PORTFOLIO:CREATE_PROPERTY_API";
export const UPDATE_PROPERTY_API = "PORTFOLIO:UPDATE_PROPERTY_API";
export const DELETE_PROPERTY_API = "PORTFOLIO:DELETE_PROPERTY_API";

export const UPDATE_ESTIMATED_ESTATE_VALUE =
  "PORTFOLIO:UPDATE_ESTIMATED_ESTATE_VALUE";
export const UPDATE_ESTIMATED_ESTATE_VALUE_API =
  "PORTFOLIO:UPDATE_ESTIMATED_ESTATE_VALUE_API";

export const UPDATE_CURRENCY = "PORTFOLIO:UPDATE_CURRENCY";
export const UPDATE_CURRENCY_API = "PORTFOLIO:UPDATE_CURRENCY_API";
