import { FilledButtonProps } from "../FilledButton/FilledButton.component";
import {
  ButtonColor,
  ButtonStates,
  buttonStatesColors,
} from "../types/buttonTypes";

type SelectedButtonType = Pick<
  FilledButtonProps,
  "selected" | "color" | "disabled" | "customBackgroundColors"
>;

export const getColours = (
  color: ButtonColor,
  stateColors?: ButtonStates
): ButtonStates => (stateColors ? stateColors : buttonStatesColors[color]);

export const getBackgroundColor = ({
  selected,
  disabled,
  color,
  customBackgroundColors,
}: SelectedButtonType) => {
  if (selected) return getColours(color, customBackgroundColors).selected;
  if (disabled) return getColours(color, customBackgroundColors).disabled;
  return getColours(color, customBackgroundColors).default;
};

export const getHoverBackgroundColor = ({
  color,
  disabled,
  customBackgroundColors,
}: SelectedButtonType) => {
  if (disabled) return "";
  return getColours(color, customBackgroundColors).hover;
};
