import { AxiosStatic } from "axios";

const passwordlessVerify =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (email: string, otp: string, isSignup?: boolean): Promise<void> => {
    return await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/passwordless/verify`,
      {
        email,
        otp,
        isSignup,
      },
      {
        withCredentials: true,
      }
    );
  };

export default passwordlessVerify;
