import { MEDIA_QUERIES } from "../../../styles";
import { ColorType } from "../../../../types";
import { theme } from "../../../styles";
import styled, { keyframes } from "styled-components";
import { INavigationItem } from "../types";
import Icon from "@icon-park/react/es/all";

export const NavigationBarContainer = styled.div<{ bgColor: ColorType }>`
  display: grid;
  grid-template-columns: auto 6fr auto;
  height: 74px;
  background-color: ${({ bgColor }) => theme.colors[bgColor]};
  padding: 0 86px;
  align-items: center;
  position: relative;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    grid-template-columns: auto auto;
    justify-content: space-between;
    padding: 0;
  }
`;

export const PageNameContainer = styled.div`
  height: 22px;
  min-width: 100px;
`;

export const LogoContainer = styled.div`
  height: 22px;
  min-width: 100px;
  & > img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  height: 74px;
  width: 100%;
`;

export const NavItemsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const NavItemContainer = styled.button<INavigationItem>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  padding: 8px 16px;
  background-color: ${({ isSelected, selectedBgColor }) =>
    isSelected
      ? selectedBgColor && theme.colors[selectedBgColor]
      : "transparent"};
  color: ${({ textColor }) => textColor && theme.colors[textColor]};
  border-radius: 8px;
  border: none;
  cursor: pointer;
`;

export const MobileNavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 36px;
`;

export const MobileNavItemContainer = styled.button<INavigationItem>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 0;
  gap: 40px;
  align-items: center;
  background: transparent;
  border: none;
  border-color: ${({ isSelected, selectedBgColor }) =>
    isSelected ? selectedBgColor && theme.colors[selectedBgColor] : "none"};
  color: ${({ textColor }) => textColor && theme.colors[textColor]};
`;

export const Highlighter = styled.div<INavigationItem>`
  width: ${({ isSelected }) => (isSelected ? "4px" : "0")};
  height: 50px;
  background-color: ${theme.colors.BLUE700};
  border-radius: 0 8px 8px 0;
  border-right: ${({ isSelected }) => (isSelected ? "solid 4px" : "none")};
  border-color: ${({ isSelected, selectedBgColor }) =>
    isSelected ? selectedBgColor && theme.colors[selectedBgColor] : "none"};
  color: ${({ textColor }) => textColor && theme.colors[textColor]};
`;

export const HamburgerButtonContainer = styled.div`
  display: flex;
  padding-left: 16px;
  gap: 18px;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;

  & button {
    background: transparent;
    border: none;
  }
`;

const fadeIn = keyframes`
  0% { display: none; opacity: 0; }
  1% { display: block; opacity: 0; }
  100% { display: block; opacity: 0.7; }
`;

export const Overlay = styled.div`
  width: 100%;
  background-color: ${theme.colors.SLATE900};
  opacity: 0.7;
  position: fixed;
  height: 100vh;
  overflow-y: hidden;
  animation: ${fadeIn} 500ms linear;
  z-index: 1;
`;

export const Wrapper = styled.div<{
  bgColor: ColorType;
}>`
  position: fixed;
  background-color: ${({ bgColor }) => theme.colors[bgColor]};
  width: 80%;
  height: 100vh;
  padding: 24px 0;
  overflow-y: hidden;
  z-index: 1;

  @media ${MEDIA_QUERIES.PHONE} {
    left: 0;
  }
`;
export const TopLogoWrapper = styled.div`
  padding: 0 24px;
`;
export const BottomLogoWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 24px;
`;
export const ChildrenWrapper = styled.div`
  margin: 40px 0;
  height: 80%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors["GOLD100"]};
  &::-webkit-scrollbar {
    background: black;
    border-radius: 10px;
    width: 3px;
  }
  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors["GOLD200"]};
    border-radius: 6px;
  }
`;
export const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
`;
