import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { SUBMIT_USER_INFO } from "../constants/actionTypes";
import { UserInfo, UserInfoInput } from "../constants/types";

export const submitUserInfo = createAsyncThunk<UserInfo, UserInfoInput>(
  SUBMIT_USER_INFO,
  async (userInfo) => {
    try {
      const response = await client.mutate<UserInfoInput>({
        mutation: gql`
          mutation ($data: WillUpdateInput!) {
            updateWill(data: $data) {
              userInfo {
                firstName
                lastName
                gender
                mobileNumber
                dob
                address {
                  id
                  addressLine1
                  addressLine2
                  city
                  region
                  country
                  postcode
                }
              }
            }
          }
        `,
        variables: {
          data: {
            userInfo,
          },
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
      }

      return response?.data?.updateWill.userInfo;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "submitUserInfo",
        exception: e,
      });

      return e;
    }
  }
);
