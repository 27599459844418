import {
  Highlighter,
  MobileNavItemContainer,
  MobileNavItemsContainer,
  Overlay,
} from "../styled";
import { NavigationDrawer } from "./NavigationDrawer.component";
import { ColorType } from "../../../../types";
import Text from "../../Text";
import React from "react";
import { IMobileNav } from "../types";

const MobileNav = ({
  bgColor,
  primaryLogo,
  secondaryLogo,
  navItems,
  selectedItemId,
  navItemsTextColor,
  selectedNavItemsBgColor,
  onSelectNavItem,
  onClose,
}: IMobileNav) => (
  <div data-testid="mobile-nav-drawer">
    <Overlay />
    <NavigationDrawer
      primaryLogo={primaryLogo as string}
      secondaryLogo={secondaryLogo}
      onClose={onClose}
      bgColor={bgColor}
    >
      <MobileNavItemsContainer>
        {navItems?.map((item) => {
          const isItemSelected: boolean = selectedItemId === item.id;
          const textColor: ColorType = isItemSelected
            ? selectedNavItemsBgColor
            : navItemsTextColor;
          return (
            <MobileNavItemContainer
              onClick={() => onSelectNavItem?.(item)}
              key={item.id}
              data-testid={item.id}
              selectedBgColor={selectedNavItemsBgColor}
              isSelected={isItemSelected}
              textColor={textColor}
              selectedTextColor={textColor}
              {...item}
            >
              <Highlighter
                {...item}
                textColor={textColor}
                isSelected={isItemSelected}
                selectedBgColor={selectedNavItemsBgColor}
              />
              <Text type="H24" color={textColor}>
                {item.title}
              </Text>
            </MobileNavItemContainer>
          );
        })}
      </MobileNavItemsContainer>
    </NavigationDrawer>
  </div>
);

export default MobileNav;
