import { init } from "@ifgengineering/client-analytics";
import {
  APP_NAME,
  GOOGLE_ADS_ID,
  GOOGLE_ADS_SIGNUP_TAG_ID,
  MIXPANEL_TOKEN,
} from "gatsby-env-variables";

const useAnalytics = init({
  APP_NAME,
  GOOGLE_ADS_ID,
  GOOGLE_ADS_SIGNUP_TAG_ID,
  MIXPANEL_TOKEN,
});

export default useAnalytics;
