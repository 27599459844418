import { createAction } from "@reduxjs/toolkit";
import { GiftsAndBequestPageEnum } from "../../views/forms/giftsAndBequest/types";
import * as actions from "./constants/actionTypes";

export const onShowModal = createAction(actions.SHOW_MODAL);
export const onHideModal = createAction(actions.HIDE_MODAL);

/**
 * show bequest page action
 * @param page_key
 * the page to be renedered
 */
export const showBQPage = createAction<GiftsAndBequestPageEnum>(
  actions.BQ_PAGE_CHANGE
);
export const storePrevBQPage = createAction(actions.STORE_PREV_BQ_PAGE);
