import { navigate } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { showBQPage } from "@willsStore/ui/actions";
import { HeadingText } from "../../styles";
import { GiftsAndBequestPageEnum } from "../../types";
import withResponsiveModal from "../../utils/withResponsiveModal";
import Checkbox from "@assets/icons/check.svg";
import Button, {
  ButtonStates,
} from "@legacyComponents/Button/Button.component";
import { theme } from "@willsTheme";
import DropDown, {
  DropDownValue,
} from "@legacyComponents/DropDown/DropDown.component";
import { getCharity, getDonation } from "@components/Charity/selectors";
import {
  createPartnerCharityDonation,
  updateCharityDonation,
} from "@components/Charity/actions";
import { getUserEmail } from "@components/Login/selectors";

const GiftAidItContainer = styled.div<{ active: boolean }>`
  display: flex;
  gap: 0.7em;
  & > p {
    font-size: 0.75em;
    color: ${({ active }) =>
      active ? theme.colors.Primary01 : theme.colors.Grey02};
  }
  & > div > svg {
    stroke: ${({ active }) =>
      active ? theme.colors.Primary01 : theme.colors.Grey02};
  }
`;

const SponsoredCharityDonation: React.FC = () => {
  const donation = useSelector(getDonation);

  const [value, setValue] = useState<number>(donation?.amount || 0);
  const [giftAid, setGiftAid] = useState<{
    selected: boolean;
    title: string;
  }>({ selected: true, title: donation?.title || "" });
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);

  const sponsoredCharity = useSelector(getCharity);

  const titles: DropDownValue[] = [
    {
      label: "Mr.",
      value: "mr",
    },
    {
      label: "Mrs.",
      value: "mrs",
    },
    {
      label: "Miss",
      value: "miss",
    },
    {
      label: "Ms.",
      value: "ms",
    },
    {
      label: "Dr.",
      value: "dr",
    },
    {
      label: "Prof.",
      value: "prof",
    },
  ];

  const { LANDING, SPONSORED_CHARITY_THANKS } = GiftsAndBequestPageEnum;

  const handleSubmit = async () => {
    if (!donation) {
      dispatch(
        createPartnerCharityDonation({
          email: userEmail,
          data: {
            amount: value,
            giftAid: giftAid.selected,
            title: giftAid.title,
          },
        })
      );
    }

    if (donation?.amount) {
      dispatch(
        updateCharityDonation({
          email: userEmail,
          data: {
            amount: value,
            giftAid: giftAid.selected,
            title: giftAid.title,
          },
        })
      );
    }

    dispatch(showBQPage(SPONSORED_CHARITY_THANKS));
  };

  const isGiftAidTitleRequired = () =>
    giftAid.selected && !giftAid.title?.trim();

  const canSkip = value == 0;

  return (
    <div>
      <HeadingText fontSize="1rem">
        Thank you so much for supporting {sponsoredCharity.displayName}!
      </HeadingText>
      <br />
      <HeadingText fontSize="1rem">
        To add barakah to your will do you want to add a one off donation right
        now?
      </HeadingText>
      <MoneyInput
        heading="How much would you like to donate?"
        description="This amount will be added at checkout"
        value={value.toString()}
        onChange={(value) => setValue(Number(value))}
      />
      {!canSkip && (
        <React.Fragment>
          <GiftAidItContainer active={giftAid.selected}>
            <div
              onClick={() => {
                giftAid.selected
                  ? setGiftAid({
                      ...giftAid,
                      selected: !giftAid.selected,
                      title: "",
                    })
                  : setGiftAid({
                      ...giftAid,
                      selected: !giftAid.selected,
                    });
              }}
            >
              <Checkbox />
            </div>
            <p>
              <b>Gift Aid it!</b> This will enable the charity to claim 25% on
              top of your donation - at no extra expense to you!
            </p>
          </GiftAidItContainer>
          {giftAid.selected && (
            <div style={{ width: "100%", position: "relative" }}>
              <DropDown
                options={titles}
                placeholder="Select your title (required for GiftAid)"
                value={{
                  label: titles.find((title) => title.value == giftAid.title)
                    ?.label as string,
                  value: giftAid.selected ? giftAid.title : "",
                }}
                onChange={(value) =>
                  setGiftAid({ ...giftAid, title: value?.value as string })
                }
              />
            </div>
          )}
        </React.Fragment>
      )}
      <br />
      {canSkip && (
        <>
          <Button
            version={ButtonStates.PRIMARY_GHOST_LARGE}
            text="Skip One Off donation"
            onClick={() => {
              navigate("/app/wills");
              dispatch(showBQPage(LANDING));
            }}
          />
          <br />
        </>
      )}
      <br />
      <FormButton
        text="Save & Continue"
        active={value > 0 && !isGiftAidTitleRequired()}
        leftArrowLabel="Back"
        onClick={handleSubmit}
        leftArrowOnClick={() => dispatch(showBQPage(LANDING))}
      />
    </div>
  );
};

export default withResponsiveModal(SponsoredCharityDonation, {});
