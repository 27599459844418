import React from "react";
import { useDispatch, useSelector } from "react-redux";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import store from "../../../../store";
import { updateBequestAPI } from "../../../../../components/Wills/actions/bequest";
import { bqSelector } from "../../../../../components/Wills/selectors";
import { showBQPage } from "../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../types";

const MeansTestedBenefits: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { id } = useSelector(bqSelector);
  const { LANDING, DISABLED_BENEFICIARIES } = GiftsAndBequestPageEnum;

  return (
    <div>
      <YesNoQuestions
        title="Are any of your potential beneficiaries receiving means-tested benefits (e.g. Council Tax Support)?"
        description="We ask this to make sure your beneficiaries don’t inadvertently lose their benefit by receiving this inheritance."
        onChange={(value) => {
          dispatch(
            updateBequestAPI({
              data: {
                id,
                memberReceivingTestedBenefits: value,
              },
            })
          ).unwrap();
          dispatch(showBQPage(DISABLED_BENEFICIARIES));
        }}
        onBack={() => dispatch(showBQPage(LANDING))}
      />
    </div>
  );
};

export default MeansTestedBenefits;
