import { createReducer } from "@reduxjs/toolkit";
import {
  clearStore,
  isLoggedInAction,
  loginAction,
  logoutAction,
  updateUserInfo,
} from "../../../components/Login/actions";
import { initialState } from "../../../components/Login/constants/initialState";
import {
  authResetStateReducer,
  authFulfilledReducer,
  authPendingReducer,
  authRejectedReducer,
} from "../../../components/Login/reducers";

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginAction.pending, authPendingReducer)
    .addCase(loginAction.rejected, authRejectedReducer)
    .addCase(loginAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.rejected, authResetStateReducer)
    .addCase(logoutAction.pending, authPendingReducer)
    .addCase(logoutAction.rejected, authRejectedReducer)
    .addCase(logoutAction.fulfilled, authResetStateReducer)
    .addCase(clearStore, authResetStateReducer)
    .addCase(updateUserInfo, authFulfilledReducer);
});

export default authReducer;
