import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

type Props = {
  hasError?: boolean;
};

const Text = styled.p<Props>`
  color: ${({ hasError }) =>
    hasError ? theme.colors.ErrorRed : theme.colors.Font02};
  font-size: 0.75rem;
  margin-bottom: ${({ hasError }) => (hasError ? "0" : "0.5rem")};
`;

export default Text;
