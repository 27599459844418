import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { sentryReduxEnhancer } from "@helpers/logException";

import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export const persistor = persistStore(store);

export default store;
