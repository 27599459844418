import styled from "styled-components";
import { theme } from "../../../styles/theme";

type StyledCheckboxInputProps = {
  checked: boolean;
  value: string;
  name?: string;
  onClick: () => void;
};

export const StyledCheckboxInput = styled.div<StyledCheckboxInputProps>`
  cursor: ${({ checked }) => (checked ? "default" : "pointer")};
  width: 18px;
  height: 18px;
  margin: 0.625rem 0.625rem 0.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ checked }) =>
    checked ? theme.colors.BLUE600 : theme.colors.WHITE};
  border: 1px solid
    ${({ checked }) => (checked ? theme.colors.BLUE600 : theme.colors.SLATE400)};

  &::after {
    content: "";
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    left: 4px;
    top: 18px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`;

export default StyledCheckboxInput;
