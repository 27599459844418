import { AxiosStatic } from "axios";

const verifyOTP =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (otp: string): Promise<void> => {
    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/2fa/verify`,
      {
        otp,
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  };

export default verifyOTP;
