import React from "react";
import { useDispatch, useSelector } from "react-redux";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import store from "../../../../store";
import { updateBequestAPI } from "../../../../../components/Wills/actions/bequest";
import { bqSelector } from "../../../../../components/Wills/selectors";
import { showBQPage } from "../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../types";

const DisabledBeneficiaries: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { id } = useSelector(bqSelector);
  const { LANDING, MEANS_TESTED_BENEFITS } = GiftsAndBequestPageEnum;

  return (
    <div>
      <YesNoQuestions
        title="Are any of your beneficiaries disabled?"
        description="Potential beneficiaries are any of the relatives you have just added. We ask this to make sure your beneficiaries don’t inadvertently lose their benefit by receiving this inheritance."
        onChange={(value) => {
          dispatch(
            updateBequestAPI({
              data: {
                id,
                memberDisabled: value,
              },
            })
          ).unwrap();
          dispatch(showBQPage(LANDING));
        }}
        onBack={() => dispatch(showBQPage(MEANS_TESTED_BENEFITS))}
      />
    </div>
  );
};

export default DisabledBeneficiaries;
