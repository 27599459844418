import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    primary: "#25347a",
    primaryText: "#191970",
    secondary: "#36B8FC",
    white: "#FFFFFF",
    paleBlue: "rgba(37, 52, 122, 0.07)",
    grey: "#9B9B9B",
    darkGrey: "#4B5861",
    disabled: "#d3d3d3",
    success: "#5EBF99",
    error: "#DC0000",
  },
  heading: {
    fontWeight: "500",
    color: "#FFFFFF",
  },
  borderRadius: "15px",
  boxShadow: "0px 2px 40px rgba(0, 0, 0, 0.05)",
  dividerBorder: "2px solid rgba(0, 0, 0, 0.05)",
};

export { theme };
