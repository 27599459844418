import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import { ColorType, theme } from "../../../styles/theme";
import { ButtonProps } from "../types/buttonTypes";
import Text from "../../Text/Text.component";

export interface OutlinedButtonProps extends ButtonProps {
  height?: string;
  justifyContent?: string;
  bgColor?: ColorType | "none";
  testId?: string;
  type?: string;
  isLoading?: boolean;
  [x: string]: unknown;
}

const StyledButton = styled.button<OutlinedButtonProps>`
  background-color: ${({ bgColor }) =>
    bgColor === "none" ? "none" : bgColor && theme.colors[bgColor]};
  color: ${({ disabled }) =>
    disabled ? theme.colors.SLATE300 : theme.colors.SLATE700};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ selected }) =>
    selected ? theme.colors.SLATE500 : theme.colors.SLATE300};
  border-radius: 4px;
  padding: 0.375em 1em;
  height: ${({ height }) => height};
  width: ${({ width }) => width || "100%"};
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "" : theme.colors.SLATE100};
  }
  &:active {
    border-color: ${theme.colors.BLUE300};
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    flex-direction: ${({ iconPosition }) =>
      iconPosition === "right" ? "row-reverse" : "row"};
  }

  @media (max-width: 768px) {
    padding: 0rem 0.5em;
  }
`;

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  text,
  icon,
  selected,
  disabled,
  iconPosition,
  iconSize = 18,
  iconTheme = "outline",
  iconColor,
  height,
  textSize = "S14",
  onClick,
  href,
  target = "_blank",
  bgColor = "WHITE",
  testId,
  textColor,
  ...props
}) => {
  const btnTextColor = disabled ? "SLATE300" : textColor ?? "SLATE700";
  const Button = (
    <StyledButton
      icon={icon}
      iconPosition={iconPosition}
      selected={selected}
      disabled={disabled}
      onClick={onClick}
      text={text}
      data-testid={testId}
      height={height}
      bgColor={bgColor}
      {...props}
    >
      <div>
        {icon && (
          <span>
            <Icon
              type={icon}
              size={iconSize}
              theme={iconTheme}
              fill={iconColor}
            />
          </span>
        )}
        <Text type={textSize} color={btnTextColor}>
          {text}
        </Text>
      </div>
    </StyledButton>
  );

  return href ? (
    <a href={href} target={target}>
      {Button}
    </a>
  ) : (
    Button
  );
};

export default OutlinedButton;
