import styled from "styled-components";

export const ErrorMessageContainer = styled.div`
  height: 18px;
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 7px 0 0 0;
`;

export default ErrorMessageContainer;
