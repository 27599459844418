import styled from "styled-components";
import { theme } from "../../../styles";

const DisabledText = styled.p`
  color: ${theme.colors.BLUE900};
  font-size: 1rem;
  line-height: 1rem;
`;

export default DisabledText;
