import {
  BequestInterface,
  BequestCharityInterface,
  BequestIndividualInterface,
} from "@ifgengineering/hip-app-domain";
import { createAction } from "@reduxjs/toolkit";
import * as actions from "./constants/actionTypes";
import {
  ExecutionerObject,
  ExecutorInfo,
  FamilyMemberObject,
  FamilyObject,
  GuardianInfo,
  GuardianObject,
  UserInfo,
  WillObject,
} from "./constants/types";

export const updateWills = createAction<WillObject, string>(
  actions.FETCH_WILLS
);

export const onUpdateUserInfo = createAction<UserInfo, string>(
  actions.UPDATE_USER_INFO
);
export const onUpdateDepartingMessage = createAction<string, string>(
  actions.UPDATE_DEPARTING_MESSAGE
);

export const updateFamilyMember = createAction<FamilyMemberObject, string>(
  actions.UPDATE_FAMILY_MEMBER
);

export const createFamilyMember = createAction<FamilyMemberObject, string>(
  actions.CREATE_FAMILY_MEMBER
);

export const updateFamily = createAction<FamilyObject, string>(
  actions.UPDATE_FAMILY
);

export const updateMemberReceivingTestedBenefits = createAction<
  boolean,
  string
>(actions.UPDATE_MEMBER_RECEIVING_BENEFIT);

export const updateBeneficiaryDisabled = createAction<boolean, string>(
  actions.UPDATE_BENEFICIARY_DISABLED
);

export const updateExecutorStore = createAction<ExecutorInfo, string>(
  actions.UPDATE_EXECUTOR_INFO
);

export const updateExecutorsStore = createAction<ExecutionerObject[], string>(
  actions.UPDATE_EXECUTORS
);

export const deleteExecutorStore = createAction<number, string>(
  actions.DELETE_EXECUTOR
);

export const updateGuardiansStore = createAction<GuardianObject[], string>(
  actions.UPDATE_GUARDIANS
);

export const updateGuardianStore = createAction<GuardianInfo, string>(
  actions.UPDATE_GUARDIAN_INFO
);

export const deleteGuardianStore = createAction<number, string>(
  actions.DELETE_GUARDIAN
);

// ==============================
//    Gifts & Bequests Actions
// ==============================

export const bqCreateBequest = createAction<BequestInterface>(
  actions.BQ_CREATE_BEQUEST_API
);

export const bqSelectExistingIndividual =
  createAction<BequestIndividualInterface>(
    actions.BQ_SELECT_EXISTING_INDIVIDUAL
  );

export const bqAddNewIndividual = createAction<BequestIndividualInterface>(
  actions.BQ_ADD_NEW_INDIVIDUAL
);

export const bqUpdateIndividual = createAction<BequestIndividualInterface>(
  actions.BQ_UPDATE_INDIVIDUAL
);

export const bqResetIndividual = createAction(actions.BQ_RESET_INDIVIDUAL);

export const bqAddIndividualToList = createAction<BequestIndividualInterface>(
  actions.BQ_ADD_INDIVIDUAL_TO_LIST
);
export const bqUpdateIndividualInList =
  createAction<BequestIndividualInterface>(
    actions.BQ_UPDATE_INDIVIDUAL_IN_LIST
  );

export const bqDeleteIndividualFromList = createAction<number | undefined>(
  actions.BQ_DELETE_INDIVIDUAL_FROM_LIST
);

export const bqUpdateBequests = createAction<BequestInterface>(
  actions.BQ_UPDATE_BEQUEST
);

export const bqSelectExistingCharity = createAction<BequestCharityInterface>(
  actions.BQ_SELECT_EXISTING_CHARITY
);

export const bqAddNewCharity = createAction<BequestCharityInterface>(
  actions.BQ_ADD_NEW_CHARITY
);

export const bqUpdateCharity = createAction<BequestCharityInterface>(
  actions.BQ_UPDATE_CHARITY
);

export const bqResetCharity = createAction(actions.BQ_RESET_CHARITY);

export const bqAddCharityToList = createAction<BequestCharityInterface>(
  actions.BQ_ADD_CHARITY_TO_LIST
);

export const bqUpdateCharityInList = createAction<BequestCharityInterface>(
  actions.BQ_UPDATE_CHARITY_IN_LIST
);
export const bqDeleteCharityFromList = createAction<number | undefined>(
  actions.BQ_DELETE_CHARITY_FROM_LIST
);
