import {
  ChildTrustFundInterface,
  ChildTrustFundItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";

export type ChildTrustReducerPayload = {
  payload: ChildTrustFundItemInterface;
};

export const updateChildTrustReducer = (
  state: PortfolioDataInterface,
  { payload }: ChildTrustReducerPayload
): void => {
  if (!state.childTrustFunds) {
    state.childTrustFunds = {
      childTrustFundList: [],
    };
  }

  const trustAlreadyListed = state.childTrustFunds.childTrustFundList?.some(
    (trust) => trust.id === payload.id
  );

  if (trustAlreadyListed) {
    const trust =
      state.childTrustFunds.childTrustFundList?.map((trust) => {
        return trust.id === payload.id ? payload : trust;
      }) || [];

    state.childTrustFunds.childTrustFundList = trust;
  } else {
    state.childTrustFunds.childTrustFundList?.push(payload);
  }
};

export const deleteChildTrustReducer = (
  state: PortfolioDataInterface,
  { payload }: ChildTrustReducerPayload
): void => {
  if (!state.childTrustFunds) {
    state.childTrustFunds = {
      childTrustFundList: [],
    };
  }

  const trustList =
    state.childTrustFunds.childTrustFundList?.filter(
      (trust) => trust.id !== payload.id
    ) || [];

  state.childTrustFunds.childTrustFundList = trustList;
};

export type BulkUpdatePayload = {
  payload: ChildTrustFundInterface;
};

export const bulkUpdateDebtReducer = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.childTrustFunds = payload;
};
