import { WillObject, WillsState } from "../constants/types";

export const emptyWill: WillObject = {
  userInfo: {},
  bequests: {
    bequestIndividuals: [],
    bequestCharity: [],
  },
  overseer: {
    executionerList: [],
    guardianList: [],
  },
  family: {
    memberDisabled: null,
    memberReceivingTestedBenefits: null,
    familyMembers: [],
  },
  departingMessage: "",
  adminStatus: "",
  status: "",
};

export const initialState: WillsState = {
  willsList: [],
  loading: false,
  bq_individual: {
    firstName: "",
    lastName: "",
    relationship: "",
    islamicInheritor: false,
    message: "",
    address: {
      city: "",
    },
  },
  bq_charity: {
    charityID: "",
    fullName: "",
    address: {
      city: "",
    },
  },
  will: emptyWill,
};

export default initialState;
