import React from "react";

import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import Text from "../Text";
import { theme } from "../../styles";

type Props = {
  heading?: string;
  errorMessage?: string;
};
export const ErrorContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 7px 0 0 0;
`;

export const Container = styled.div`
  width: 100%;
`;

const InputWrapper: React.FC<Props> = ({ children, heading, errorMessage }) => (
  <Container data-testid={`${heading}-search-select`}>
    {heading && (
      <Text type="S16" color="SLATE800">
        {heading}
      </Text>
    )}
    {children}
    {errorMessage && (
      <ErrorContainer data-testid="input-wrapper-error">
        <>
          <Icon
            type="Caution"
            size={14}
            fill={theme.colors.ERROR800}
            theme="filled"
          />
          <Text type="P14" color="ERROR800">
            {errorMessage}
          </Text>
        </>
      </ErrorContainer>
    )}
  </Container>
);

export default InputWrapper;
