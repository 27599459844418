import { FamilyObject, WillsState } from "../constants/types";

type FamilyReducerPayload = {
  payload: FamilyObject;
};

export const familyReducer = (
  state: WillsState,
  { payload }: FamilyReducerPayload
): void => {
  if (!payload.familyMembers) {
    state.will.family = {
      ...state.will.family,
      memberDisabled: state.will.family?.memberDisabled,
      memberReceivingTestedBenefits:
        state.will.family?.memberReceivingTestedBenefits,
    };
    return;
  }

  state.will.family = payload;
};
