import { ExecutionerObject, WillsState } from "../constants/types";

export const bulkUpdateExecutorReducer = (
  state: WillsState,
  executors: ExecutionerObject[]
) => {
  if (state.will?.overseer) {
    state.will.overseer.executionerList = executors;
  } else {
    state.will.overseer = {
      ...state.will.overseer,
      executionerList: executors,
    };
  }
};
