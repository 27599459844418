import React from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";
import CardContainer, {
  CardContainerStyles,
} from "../../../../legacyComponents/CardContainer/CardContainer.component";
import Button, {
  ButtonStates,
} from "../../../../legacyComponents/Button/Button.component";

const StyledContentWrapper = styled.div`
  display: flex;
  padding: 20px 10px;
`;

const StyledCardText = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

type StyledLogoProps = {
  isSelected: boolean;
};

const StyledLogo = styled.div<StyledLogoProps>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 0.5px solid
    ${({ isSelected }) =>
      isSelected ? theme.colors.Primary02 : theme.colors.Grey01};
  overflow: hidden;
  & > img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`;

const TextLogoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.875em;
  width: 100%;
`;

const StyledButton = styled.div`
  cursor: pointer;

  & > button {
    padding: 10px 20px;
  }
`;

type CharityCardProps = {
  isSelected?: boolean;
  logoURL?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  buttonText: string;
};

const CharityCard = ({
  isSelected = false,
  logoURL,
  onClick,
  text,
  buttonText,
}: CharityCardProps): JSX.Element => {
  return (
    <CardContainer
      cardStyle={
        isSelected ? CardContainerStyles.SELECTED : CardContainerStyles.REGULAR
      }
    >
      <StyledContentWrapper>
        <TextLogoContainer>
          <StyledLogo isSelected={isSelected}>
            <img src={logoURL} alt={text + " logo"} />
          </StyledLogo>
          <StyledCardText>{text}</StyledCardText>
        </TextLogoContainer>
        <StyledButton>
          <Button
            onClick={onClick}
            version={
              isSelected
                ? ButtonStates.PRIMARY
                : ButtonStates.PRIMARY_GHOST_LARGE
            }
            text={buttonText}
            data-testid="edit-button"
          />
        </StyledButton>
      </StyledContentWrapper>
    </CardContainer>
  );
};

export default CharityCard;
