import styled from "styled-components";

const Container = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || 104}px;
  & textarea {
    resize: vertical;
  }
`;

export default Container;
