import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

const DisabledText = styled.p`
  color: ${theme.colors.Primary03};
  font-size: 1rem;
  line-height: 1rem;
  font-family: "Inter", sans-serif;
`;

export default DisabledText;
