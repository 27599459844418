import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { CheckSmall } from "@icon-park/react";
import { PartnerCharityCauseInterface } from "@ifgengineering/hip-app-domain";

interface CauseCardProps extends PartnerCharityCauseInterface {
  selected?: boolean;
  onSelect: (id?: string) => void;
}

const CauseCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: center;
  width: 8.75em;
  @media (max-width: 468px) {
    width: 7.5em;
    & > p {
      font-size: 0.65em;
    }
  }
`;

const ImgContainer = styled.button<{ selected?: boolean }>`
  display: flex;
  position: relative;
  width: 8.75em;
  height: 8.75em;
  border-radius: 0.5em;
  border: ${({ selected }) =>
    selected ? `solid 0.3em ${theme.colors.BrandNavy}` : ""};
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  @media (max-width: 468px) {
    width: 7.5em;
    height: 7.5em;
  }
  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const IconContainer = styled.span`
  background-color: ${theme.colors.Primary02};
  padding: 0.025em;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
`;

const CauseTitle = styled.p`
  font-size: 0.75em;
`;

const CauseCard: React.FC<CauseCardProps> = ({
  id,
  nameOfCause,
  imageKey,
  selected,
  onSelect,
}) => {
  return (
    <CauseCardContainer>
      <ImgContainer onClick={() => onSelect(id)} selected={selected}>
        <img src={imageKey} alt={nameOfCause} />
        {selected && (
          <IconContainer>
            <CheckSmall size={18} fill={theme.colors.BrandNavy} />
          </IconContainer>
        )}
      </ImgContainer>
      <CauseTitle>{nameOfCause}</CauseTitle>
    </CauseCardContainer>
  );
};

export default CauseCard;
