import { GiftsAndBequestPageEnum } from "../../views/forms/giftsAndBequest/types";
import { Selector } from "../utils/types";

export const modalVisibleSelector: Selector<boolean> = (state) =>
  state.ui.modal.visible;

export const currBQPage: Selector<GiftsAndBequestPageEnum> = (state) =>
  state.ui.bequestPage;
export const prevBQPage: Selector<GiftsAndBequestPageEnum> = (state) =>
  state.ui.prevBQPage;
