import React from "react";
import Container from "../styled/Container";
import Text from "../styled/Text";
import TextInput from "../styled/TextInput";
import TextArea from "../styled/TextArea";
import Heading from "../styled/Heading";
import DisabledText from "../styled/DisabledText";
import ErrorContainer from "../styled/ErrorContainer";

export type Props = {
  value: string;
  testId?: string;
  name?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  placeholder?: string;
  description?: string;
  heading?: string;
  type?: string;
  disabled?: boolean;
  isLong?: boolean;
};

const Input: React.FC<Props> = ({
  placeholder,
  value,
  testId,
  name,
  onChange,
  onBlur,
  disabled,
  isLong,
  errorMessage = "",
  type = "text",
  description,
  heading,
  ...props
}) => {
  const InputComponent: React.ElementType = isLong ? TextArea : TextInput;
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      {disabled ? (
        <DisabledText>{value}</DisabledText>
      ) : (
        <InputComponent
          data-testid={testId}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          hasError={errorMessage}
          isLong={isLong}
          name={name}
          {...props}
        />
      )}
      {errorMessage && (
        <ErrorContainer>
          <Text data-testid="input-error-message" hasError={!!errorMessage}>
            {errorMessage}
          </Text>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default Input;
