import { BequestIndividualInterface } from "@ifgengineering/hip-app-domain";
import { WillsState } from "../../constants/types";

const addIndividualToListReducer = (
  state: WillsState,
  action: {
    payload: BequestIndividualInterface;
  }
) => {
  state.will.bequests.bequestIndividuals?.push(action.payload);
};

export default addIndividualToListReducer;
