import { NavItemContainer, NavItemsContainer } from "../styled";
import { ColorType } from "../../../../types";
import Text, { TextTypeAndSize } from "../../Text";
import React from "react";
import { IDesktopNav } from "../types";

const DesktopNav = ({
  navItems,
  selectedItemId,
  navItemsTextColor,
  selectedNavItemsBgColor,
  selectedNavItemsTextColor,
  handleNavItemClick,
}: IDesktopNav) => (
  <NavItemsContainer>
    {navItems?.map((item) => {
      const isItemSelected: boolean = selectedItemId === item.id;
      const textType: TextTypeAndSize = isItemSelected ? "S16" : "P16";
      const textColor: ColorType = isItemSelected
        ? selectedNavItemsTextColor
        : navItemsTextColor;

      return (
        <NavItemContainer
          onClick={() => handleNavItemClick?.(item)}
          key={item.id}
          data-testid={item.id}
          selectedBgColor={selectedNavItemsBgColor}
          isSelected={isItemSelected}
          textColor={textColor}
          selectedTextColor={textColor}
          {...item}
        >
          <Text type={textType} color={textColor}>
            {item.title}
          </Text>
        </NavItemContainer>
      );
    })}
  </NavItemsContainer>
);

export default DesktopNav;
