import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/legacy-theme";

export enum CardContainerStyles {
  REGULAR = "REGULAR",
  SELECTED = "SELECTED",
  HIGHLIGHTED = "HIGHLIGHTED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
}

type StyledCardContainerProps = {
  cardStyle: CardContainerStyles;
  padding: number;
  onClick?: () => void;
};

const StyledCardContainerBorder = {
  [CardContainerStyles.SELECTED]: theme.colors.Primary02,
  [CardContainerStyles.COMPLETED]: theme.colors.CompleteGreen,
  [CardContainerStyles.IN_PROGRESS]: theme.colors.InProgress,
  [CardContainerStyles.HIGHLIGHTED]: theme.colors.Primary01,
  [CardContainerStyles.REGULAR]: theme.colors.Primary02,
};

const StyledCardContainer = styled.div<StyledCardContainerProps>`
background: ${(props) =>
  props.cardStyle === CardContainerStyles.SELECTED
    ? theme.colors.Primary01
    : theme.colors.Primary02};
  border: 2px solid ${(props) => StyledCardContainerBorder[props.cardStyle]};
  border-radius: 8px;
  box-shadow: ${(props) =>
    props.cardStyle === CardContainerStyles.HIGHLIGHTED
      ? ""
      : "0px 3px 7px rgba(39, 55, 122, 0.15);"}
  color: ${(props) =>
    props.cardStyle === CardContainerStyles.SELECTED
      ? theme.colors.Primary02
      : theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  padding: ${({ padding }) => padding}px;
  position: relative;
  ${(props) => (props.onClick !== undefined ? "cursor: pointer;" : "")}
`;

type Props = {
  children: React.ReactNode;
  cardStyle?: CardContainerStyles;
  padding?: number;
  onClick?: () => void;
  testId?: string;
};

const CardContainer = ({
  children,
  cardStyle = CardContainerStyles.REGULAR,
  padding = 15,
  onClick,
  testId,
}: Props): JSX.Element => (
  <StyledCardContainer
    data-testid={testId}
    cardStyle={cardStyle}
    padding={padding}
    onClick={onClick}
  >
    {children}
  </StyledCardContainer>
);

export default CardContainer;
