import { AuthState } from "./types";

export const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  user: {
    id: null,
    email: "",
  },
  error: null,
  neverLoaded: true,
};
