import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/legacy-theme";

export enum ButtonStates {
  PRIMARY = "PRIMARY",
  PRIMARY_GHOST = "PRIMARY_GHOST",
  PRIMARY_GHOST_LARGE = "PRIMARY_GHOST_LARGE",
  INACTIVE = "INACTIVE",
}

type StyledButtonProps = {
  version: ButtonStates;
};

const backgroundColor = (version: ButtonStates) => {
  switch (version) {
    case ButtonStates.PRIMARY:
    case ButtonStates.INACTIVE:
      return theme.colors.Primary01;
    case ButtonStates.PRIMARY_GHOST:
    case ButtonStates.PRIMARY_GHOST_LARGE:
      return theme.colors.Primary02;
    default:
      return theme.colors.Primary01;
  }
};

const textColor = (version: ButtonStates) => {
  switch (version) {
    case ButtonStates.PRIMARY:
    case ButtonStates.INACTIVE:
      return theme.colors.Primary02;
    case ButtonStates.PRIMARY_GHOST:
      return theme.colors.Primary03;
    case ButtonStates.PRIMARY_GHOST_LARGE:
      return theme.colors.Primary01;
    default:
      return theme.colors.Primary02;
  }
};

const border = (version: ButtonStates) => {
  switch (version) {
    case ButtonStates.PRIMARY:
    case ButtonStates.INACTIVE:
      return `2px solid ${theme.colors.Primary02}`;
    case ButtonStates.PRIMARY_GHOST:
      return `2px solid ${theme.colors.BrandBlue}`;
    case ButtonStates.PRIMARY_GHOST_LARGE:
      return `2px solid ${theme.colors.Primary01}`;
    default:
      return "none";
  }
};

const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${(props) => backgroundColor(props.version)};
  border-radius: 4px;
  border: ${(props) => border(props.version)};
  color: ${(props) => textColor(props.version)};
  cursor: ${(props) =>
    props.version !== ButtonStates.INACTIVE ? "pointer" : ""};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 21px;
  padding: 13px;
  text-align: center;
  width: 100%;
  opacity: ${(props) =>
    props.version === ButtonStates.INACTIVE ? "0.5" : "1"};
`;

type ButtonProps = {
  text: string;
  testId?: string;
  type?: "button" | "submit" | "reset";
  version?: ButtonStates;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

export const Button = ({
  text,
  testId,
  type = "button",
  version = ButtonStates.PRIMARY,
  onClick,
  disabled,
}: ButtonProps): JSX.Element => {
  const onClickAction = version !== ButtonStates.INACTIVE ? onClick : undefined;

  return (
    <>
      {text.length ? (
        <StyledButton
          data-testid={testId}
          onClick={onClickAction}
          type={type}
          version={version}
          disabled={disabled}
        >
          {text}
        </StyledButton>
      ) : null}
    </>
  );
};

export default Button;
