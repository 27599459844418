import React, { FC } from "react";
import { DefaultLoader, Spinner } from "./styled";

export interface InlineLoaderProps {
  size?: number;
  backColor?: string;
  frontColor?: string;
  thickness?: number;
}

const InlineLoader: FC<InlineLoaderProps> = ({
  size = 90,
  thickness = 5,
  frontColor = "black",
  backColor = "#e3e3e3",
  children,
}) => (
  <>
    {children || (
      <Spinner>
        <DefaultLoader
          size={size}
          backColor={backColor}
          frontColor={frontColor}
          thickness={thickness}
          position="relative"
        />
      </Spinner>
    )}
  </>
);

export default InlineLoader;
