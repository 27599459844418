import { gql } from "@apollo/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { DiscoverPage, TrackPage } from "./discover.types";
import { store } from "../../state/index";
import { logoutAction } from "../../../components/Login/actions";
import { CompareProductInterface } from "@ifgengineering/hip-app-domain";
import { logException } from "@helpers/logException";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";

export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PAGE = "SET_PAGE";
export const SET_TRACK_PAGE = "SET_TRACK_PAGE";

export const setPage = createAction<DiscoverPage>(SET_PAGE);

export const setTrackPage = createAction<TrackPage>(SET_TRACK_PAGE);

export const updateProducts =
  createAction<CompareProductInterface[]>(UPDATE_PRODUCTS);

export const fetchProductsAPI = createAsyncThunk<
  CompareProductInterface[] | undefined
>(FETCH_PRODUCTS, async () => {
  try {
    const response = await client.query<{
      products: CompareProductInterface[];
    }>({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          products {
            id
            affiliateLink
            assetClass
            availableInCountry
            category
            description
            ifgAnalysis
            investmentReturn
            liquidity
            logo
            minimumInvestment
            name
            regulated
            risk
            tags
            priority
          }
        }
      `,
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.products;
  } catch (e: any) {
    if (
      e.networkError.result.errors.some(
        ({ statusCode }: { statusCode: number }) => statusCode === 401
      )
    ) {
      store.dispatch(logoutAction());
    } else {
      logException({
        tag: "fetchProductsAPI",
        exception: e,
      });

      toast.warn("Something went wrong. Try again or contact support");
      throw Error(e);
    }
  }
});
