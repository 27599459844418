import Wealth from "./src/Wealth/gatsby-browser";
import Wills from "./src/Wills/gatsby-browser";
import Zakat from "./src/Zakat/gatsby-browser";

import { SITE_FOLDER } from "gatsby-env-variables";

const SiteExports = {
  Wealth,
  Wills,
  Zakat,
};

const { wrapRootElement, wrapPageElement } = SiteExports[SITE_FOLDER];

export { wrapRootElement, wrapPageElement };
