import { AxiosStatic } from "axios";
import { ChangePasswordInput } from "../types";

const changePassword =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async ({ password, newPassword }: ChangePasswordInput): Promise<void> => {
    await appAxios.put(
      `${serverURL}/auth${versionURLFragment}/change-password`,
      {
        password,
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
  };

export default changePassword;
