import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

type Props = {
  hasError?: boolean;
  withIcon?: boolean;
};

const TextInput = styled.input<Props>`
  width: 100%;
  font-size: 1rem;
  color: ${theme.colors.Font02};
  box-sizing: border-box;
  height: 3rem;
  border-radius: 12px;
  padding: ${({ withIcon }) => (withIcon ? "13px 40px 13px 13px" : "13px")};
  border: ${({ hasError }) =>
    hasError
      ? `1px ${theme.colors.ErrorRed} solid`
      : `1px ${theme.colors.Grey02} solid`};
  outline: transparent;

  &:focus {
    border: 1px ${theme.colors.Primary01} solid;
  }
`;

export default TextInput;
