import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import { theme } from "../../../styles/theme";
import { ButtonProps, ButtonColor, ButtonStates } from "../types/buttonTypes";
import Text from "../../Text";
import {
  getBackgroundColor,
  getColours,
  getHoverBackgroundColor,
} from "../helpers";
import { InlineLoader } from "../../Loader";

export interface FilledButtonProps extends ButtonProps {
  color: ButtonColor;
  justifyContent?: string;
  testId?: string;
  type?: string;
  isLoading?: boolean;
  customBackgroundColors?: ButtonStates;
  [x: string]: unknown;
}

export const StyledButton = styled.button<FilledButtonProps>`
  background-color: ${(props) => getBackgroundColor(props)};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 0.375em 1em;
  height: ${({ height }) => height};
  width: ${({ width }) => width || "100%"};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  &:active {
    background-color: ${({
      color,
      customBackgroundColors: customBackgroundColors,
    }) => getColours(color, customBackgroundColors).active};
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "center"};
    gap: 0.5em;
    flex-direction: ${({ iconPosition }) =>
      iconPosition === "right" ? "row-reverse" : "row"};
  }
`;

export const FilledButton: React.FC<FilledButtonProps> = ({
  text,
  color = "blue",
  icon,
  iconTheme,
  iconColor = "WHITE",
  iconSize,
  selected,
  disabled,
  iconPosition,
  textSize = "S14",
  textColor,
  onClick,
  href,
  target = "_blank",
  justifyContent,
  height,
  type,
  isLoading,
  testId,
  borderRadius = "4px",
  customBackgroundColors: customBackgroundColors,
  ...props
}) => {
  const defaultTextColor = disabled ? "SLATE400" : "WHITE";
  const chosenTextColor = textColor || defaultTextColor;

  const Button = (
    <StyledButton
      text={text}
      icon={icon}
      iconPosition={iconPosition}
      color={color}
      selected={selected}
      onClick={onClick}
      disabled={disabled}
      justifyContent={justifyContent}
      height={height}
      type={type}
      borderRadius={borderRadius}
      data-testid={testId}
      customBackgroundColors={customBackgroundColors}
      {...props}
    >
      <div>
        {icon && (
          <span>
            <Icon
              type={icon}
              size={iconSize}
              theme={iconTheme}
              fill={theme.colors[iconColor]}
            />
          </span>
        )}
        {isLoading ? (
          <InlineLoader size={20} thickness={1} />
        ) : (
          <Text color={chosenTextColor} type={textSize}>
            {text}
          </Text>
        )}
      </div>
    </StyledButton>
  );

  return href ? (
    <a
      href={href}
      target={target}
      style={{
        ...props,
      }}
    >
      {Button}
    </a>
  ) : (
    Button
  );
};

export default FilledButton;
