import styled from "styled-components";
import {
  CheckboxInputDirection,
  CheckboxInputGroupProps,
} from "../CheckboxInputGroup.component";

const StyledCheckboxInputGroup = styled.div<CheckboxInputGroupProps>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === CheckboxInputDirection.VERTICAL ? "column" : "row"};
  gap: ${({ gap }) => (gap ? gap : undefined)};
`;
export default StyledCheckboxInputGroup;
