import styled from "styled-components";

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 2.5rem;
  top: 0;
  right: 0;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
`;

export default IconWrapper;
