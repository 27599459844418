import { createReducer } from "@reduxjs/toolkit";
import { fetchCryptoProducts } from "./crypto.actions";
import { CryptoState } from "./crypto.types";

export const initialState: CryptoState = {
  loading: false,
  cryptoProducts: [],
};

export const cryptoReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchCryptoProducts.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchCryptoProducts.fulfilled, (state, { payload }) => {
    state.loading = false;
    state.cryptoProducts = payload;
  });
  builder.addCase(fetchCryptoProducts.rejected, (state, { payload }) => {
    state.loading = false;
    state.err = payload as Error;
    state.cryptoProducts = [];
  });
});
