import Icon from "@icon-park/react/es/all";
import React from "react";
import { theme } from "../../styles";
import Text from "../Text";
import ErrorMessageContainer from "./styled/ErrorMessageContainer";
import StyledRadioInputGroup from "./styled/StyledRadioInputGroup";

export enum RadioInputDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export type RadioInputGroupProps = {
  children: React.ReactNode;
  direction?: RadioInputDirection;
  testId?: string;
  errorMessage?: string;
  gap?: number;
};

const RadioInputGroup: React.FC<RadioInputGroupProps> = ({
  direction = RadioInputDirection.VERTICAL,
  gap = 10,
  children,
  testId,
  errorMessage,
}) => (
  <StyledRadioInputGroup data-testid={testId} direction={direction} gap={gap}>
    {children}
    {errorMessage && (
      <ErrorMessageContainer>
        <Icon
          type="Caution"
          size={14}
          fill={theme.colors.ERROR800}
          theme="filled"
        />
        <Text type="P14" color="ERROR800">
          {errorMessage}
        </Text>
      </ErrorMessageContainer>
    )}
  </StyledRadioInputGroup>
);

export default RadioInputGroup;
