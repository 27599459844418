import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiftsAndBequestPageEnum, PageMetaType } from "../../types";
import FamilyCard from "../../../../../components/Cards/FamilyCard/FamilyCard.component";
import styled from "styled-components";
import {
  bqIndividualSelector,
  bqExistingInheritorsSelector,
} from "../../../../../../components/Wills/selectors";
import { bqSelectExistingIndividual } from "../../../../../../components/Wills/actions";
import { showBQPage } from "../../../../../store/ui/actions";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import withResponsiveModal from "../../utils/withResponsiveModal";

export const existingIndividualMeta: PageMetaType = {
  heading: "Select an existing inheritor",
  subheading:
    "These are relatives who are already inheriting a part of your estate as part of their Islamic inheritance. You can decide to leave them something specific or leave them additional inheritance, but this can only be enforced with the consent of other Islamic inheritors.",
};

const PageContainer = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const FamilyCardContainer = styled.div`
  display: grid;
  gap: 1em;
`;
const ButtonContainer = styled.div`
  margin: 3em 0;
`;

export const ExistingIndividual: React.FC = () => {
  const dispatch = useDispatch();
  const selectedInheritor = useSelector(bqIndividualSelector);
  const existingInheritors = useSelector(bqExistingInheritorsSelector);

  const { LANDING, EXISTING_INDIVIDUAL_ADDRESS } = GiftsAndBequestPageEnum;

  return (
    <PageContainer>
      {existingInheritors ? (
        <FamilyCardContainer>
          {existingInheritors?.map((member, i) => (
            <FamilyCard
              key={i}
              name={`${member.firstName}  ${member.lastName}`}
              familyRelationship={member.relationship}
              buttonLabel={`${
                selectedInheritor?.id === member.id ? "Selected" : "Select"
              }`}
              {...member}
              isSelected={selectedInheritor?.id === member.id}
              onClick={() => dispatch(bqSelectExistingIndividual(member))}
            />
          ))}
        </FamilyCardContainer>
      ) : (
        <div>
          <p>No existing family memeber.</p>
        </div>
      )}
      <ButtonContainer>
        <FormButton
          text="Save & Continue"
          onClick={() => {
            selectedInheritor?.id &&
              dispatch(showBQPage(EXISTING_INDIVIDUAL_ADDRESS));
          }}
          leftArrowLabel="Back"
          leftArrowOnClick={() => {
            dispatch(showBQPage(LANDING));
          }}
        />
      </ButtonContainer>
    </PageContainer>
  );
};

export default withResponsiveModal(ExistingIndividual, existingIndividualMeta);
