import { client } from "../../../graphql";
import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { store } from "../../state/index";
import { logoutAction } from "@components/Login/actions";
import { logException } from "@helpers/logException";

export const FETCH_USER_INSIGHTS_API = "FETCH_USER_INSIGHTS_API";
export const ADD_ITEM_TO_SHORTLIST_STORE = "ADD_ITEM_TO_SHORTLIST_STORE";

export const fetchUserInsights = createAsyncThunk<any, { limit?: number }>(
  FETCH_USER_INSIGHTS_API,
  async (payload) => {
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query userItems($limit: Int) {
            getUserInsightItems(limit: $limit) {
              id
              markedAs
              priority
              createdAt
              updatedAt
              insight {
                id
                title
                active
                description
                detailedDescription
                priority
                ragStatusKey
                category {
                  id
                  name
                  description
                }
                section {
                  id
                  name
                  description
                }
                insightCtas {
                  id
                  link
                  linkText
                  imageKey
                  insightId
                }
              }
            }
          }
        `,
        variables: { limit: payload?.limit },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data;
    } catch (e: any) {
      if (
        e.networkError.result.errors.some(
          ({ statusCode }: { statusCode: number }) => statusCode === 401
        )
      ) {
        store.dispatch(logoutAction());
      } else {
        logException({
          tag: "fetchUserInsights",
          exception: e,
        });

        toast.warn("Something went wrong. Try again or contact support");
        throw Error(e);
      }

      return { errors: e.networkError.result.errors };
    }
  }
);
