import { ResetPasswordInput } from "../types";
import { AxiosStatic } from "axios";

const resetPassword =
  (serverURL: string, appAxios: AxiosStatic) =>
  async ({
    email,
    newPassword,
    token,
    otp,
  }: ResetPasswordInput): Promise<void> => {
    await appAxios.put(
      `${serverURL}/auth/reset-password`,
      {
        email,
        newPassword,
        token,
        otp,
      },
      {
        withCredentials: true,
      }
    );
  };

export default resetPassword;
