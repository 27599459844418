import { MEDIA_QUERIES } from "../../styles/media-queries";
import styled from "styled-components";
import { CardProps } from "./Card.component";
import { theme } from "../../styles/theme";

export const cardElevation = {
  0: "none",
  1: "0px 1px 6px rgb(52 111 180 / 15%)",
  2: "0px 1px 6px 4px rgb(52 111 180 / 15%)",
  3: "0px 1px 12px 9px rgb(52 111 180 / 15%)",
};

export const calcBoxShadow = (elevation: number) => {
  switch (elevation) {
    case 0:
      return cardElevation[elevation];
    case 1:
      return cardElevation[elevation];
    case 2:
      return cardElevation[elevation];
    case 3:
      return cardElevation[elevation];
    default:
      return "none";
  }
};

export const CardContainer = styled.div<Partial<CardProps>>`
  background: ${({ background }) => theme.colors[background || "WHITE"]};
  border-radius: ${({ borderRadius }) => borderRadius + "px"};
  padding: ${({ padding }) => padding};
  box-shadow: ${({ elevation }) => calcBoxShadow(elevation || 0)};
  transition: box-shadow 500ms;
  height: 100%;
  width: ${({ width }) => width};
  @media ${MEDIA_QUERIES.PHONE} {
    min-height: 70px;
    height: auto;
    width: 100%;
  }
`;
