/* istanbul ignore file */
import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: whitesmoke;
`;

const Dummy = () => {
  const [number, setNumber] = useState(20);
  return (
    <Wrapper>
      <div data-testid="mock-test" onClick={() => setNumber(number + 1)}>
        Add
      </div>
      <div>Number: {number}</div>
      <div onClick={() => setNumber(number - 1)}>minus</div>
    </Wrapper>
  );
};

export default Dummy;
