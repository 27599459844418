import { AxiosPromise, AxiosStatic } from "axios";
import { AppleSignupInput, AppleSignupOutput } from "../types";

const appleSignup =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (data: AppleSignupInput): AxiosPromise<AppleSignupOutput> => {
    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/apple/signup`,
      data,
      {
        withCredentials: true,
      }
    );

    return response;
  };

export default appleSignup;
