import changePassword from "./changePassword";
import generateOtp from "./generateOtp";
import getTwoFactorAuthDetails from "./getTwoFactorAuthDetails";
import login from "./login";
import logout from "./logout";
import passwordlessStart from "./passwordlessStart";
import passwordlessVerify from "./passwordlessVerify";
import refreshAccessToken from "./refreshAccessToken";
import resendVerifyEmail from "./resendVerifyEmail";
import resetPassword from "./resetPassword";
import forgotPassword from "./forgotPassword";
import signup from "./signup";
import updateTwoFactorAuthSettings from "./updateTwoFactorAuthSettings";
import verifyEmail from "./verifyEmail";
import userInfo from "./userInfo";
import checkOtp from "./checkOtp";
import verifyOTP from "./verifyOTP";
import googleLogin from "./googleLogin";
import appleCallback from "./appleCallback";
import appleSignup from "./appleSignup";
import appleLinkEmail from "./appleLinkEmail";

export default {
  changePassword,
  generateOtp,
  getTwoFactorAuthDetails,
  login,
  logout,
  passwordlessStart,
  passwordlessVerify,
  refreshAccessToken,
  resendVerifyEmail,
  resetPassword,
  forgotPassword,
  signup,
  updateTwoFactorAuthSettings,
  verifyEmail,
  userInfo,
  checkOtp,
  verifyOTP,
  googleLogin,
  appleCallback,
  appleSignup,
  appleLinkEmail,
};
