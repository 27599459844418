import { useEffect, useState } from "react";
import axios from "axios";
import { GoogleAddress, PlacesHookType } from "../types";
import { CORE_API } from "gatsby-env-variables";

const useGooglePlaces: ({ value }: { value: string }) => PlacesHookType = ({
  value,
}) => {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!value) return setPredictions([]);
    !loading && setLoading(true);
    const timeOutId = setTimeout(() => predictionQuery(value), 600);
    return () => clearTimeout(timeOutId);
  }, [value]);

  const predictionQuery = async (value: string) => {
    try {
      const result = await axios.get(
        `${CORE_API}/google/query?place=${value}`,
        {
          withCredentials: true,
        }
      );
      setPredictions(result.data.predictions);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const onSelectPlace = async (
    value: string,
    cb: (arg0: GoogleAddress) => void
  ) => {
    try {
      const result = await axios.get(
        `${CORE_API}/google/place?place_id=${value}`,
        {
          withCredentials: true,
        }
      );
      cb(result.data.result);
    } catch (e) {
      console.log("error", e);
    }
  };

  return {
    predictions,
    onSelectPlace,
    loading,
  };
};

export default useGooglePlaces;
