import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/legacy-theme";

import ArrowPointingLeftIcon from "../../assets/icons/blue_arrow_pointing_left.svg";
import ArrowPointingRightIcon from "../../assets/icons/blue_arrow_pointing_right.svg";

const StyledIndivialArrowWrapper = styled.button`
  color: ${theme.colors.Primary01};
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: ${theme.colors.Primary01};
    min-width: 52px;
    width: 52px;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
  }

  & span {
    cursor: pointer;
  }
`;

export enum NavigationDirections {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
}

type FormNavigationButtonProps = {
  text: string;
  direction: NavigationDirections;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const FormNavigationButton = ({
  text,
  direction = NavigationDirections.BACKWARD,
  onClick,
}: FormNavigationButtonProps): JSX.Element =>
  direction === NavigationDirections.BACKWARD ? (
    <StyledIndivialArrowWrapper
      data-testid="left-arrow-button"
      type="button"
      onClick={onClick}
    >
      <ArrowPointingLeftIcon />
      <span data-testid="left-arrow-label">{text}</span>
    </StyledIndivialArrowWrapper>
  ) : (
    <StyledIndivialArrowWrapper
      data-testid="right-arrow-button"
      type="button"
      onClick={onClick}
    >
      <span data-testid="right-arrow-label">{text}</span>
      <ArrowPointingRightIcon />
    </StyledIndivialArrowWrapper>
  );

export default FormNavigationButton;
