import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { portfolioDataReducer } from "./portfolioData/reducers";
import authReducer from "./auth/reducers";
import { zakatReducer } from "./reducers/reducers";
import resourcesReducer from "./resources";
import { charityReducers } from "./charity/reducers";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["isAuthenticated"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  portfolioData: portfolioDataReducer,
  zakat: zakatReducer,
  resources: resourcesReducer,
  charity: charityReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
