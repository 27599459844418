import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Title = styled.h1`
  color: ${theme.colors.BrandNavy};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.87rem;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  color: ${theme.colors.Font02};
  font-weight: 400;
  margin-bottom: 35px;
`;

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 649px) {
    padding-top: 35px;
    align-items: flex-start;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
`;

export const BackLinkWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  button {
    width: auto;
    margin-top: 60px;
  }
`;
