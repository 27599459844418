import { createReducer } from "@reduxjs/toolkit";
import {
  updateCashStore,
  updateBankAccountsStore,
  deleteBankAccountItemStore,
  updateCommodityStore,
  deleteCommodityStore,
  deleteDebtStore,
  updateFundStore,
  deleteFundStore,
  updateShareItemStore,
  deleteShareItemStore,
  updateAgricultureStore,
  deleteAgricultureStore,
  updateCryptoStore,
  deleteCryptoStore,
  deleteStartupStore,
  updateStartupStore,
  updateAlternativeInvestmentStore,
  deleteAlternativeInvestmentStore,
  updateOwedAssetStore,
  deleteOwedAssetStore,
  updateBusinessStore,
  deleteBusinessStore,
  updatePensionItemStore,
  deletePensionStore,
  updatePropertyStore,
  deletePropertyStore,
  updatePortfolio,
  updateBankAccountItemStore,
  updateCommoditiesStore,
  updateDebtItemStore,
  updateDebtStore,
  updateChildTrustStore,
  updateChildTrustItemStore,
  deleteChildTrustItemStore,
  updateShareStore,
  updateFundsStore,
  updatePensionsStore,
  updateCryptosStore,
  updatePropertiesStore,
  updateStartupsStore,
  updateOwedAssetsStore,
  updateBusinessesStore,
  updateAlternativeInvestmentsStore,
  updateAgriculturesStore,
  updateEstimatedEstateValue,
  updateCurrency,
} from "../../../components/PortfolioData/actions";
import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain";
import {
  deleteCommodityReducer,
  updateCommoditiesStoreReducer,
  updateCommodityReducer,
} from "../../../components/PortfolioData/reducers/commodityReducer";
import {
  bulkUpdateDebt,
  deleteDebtReducer,
  updateDebtReducer,
} from "../../../components/PortfolioData/reducers/debtReducer";
import {
  bulkUpdateDebtReducer,
  deleteChildTrustReducer,
  updateChildTrustReducer,
} from "../../../components/PortfolioData/reducers/childTrustFundListReducer";
import {
  bulkUpdateBankAccount,
  deleteBankAccountReducer,
  updateBankAccountReducer,
} from "../../../components/PortfolioData/reducers/bankAccountReducer";
import {
  bulkUpdateFund,
  deleteFundsReducer,
  updateFundsReducer,
} from "../../../components/PortfolioData/reducers/fundsReducer";
import {
  bulkUpdateShare,
  deleteShareReducer,
  updateShareReducer,
} from "../../../components/PortfolioData/reducers/sharesReducer";
import {
  bulkUpdateAgriculture,
  deleteAgricultureReducer,
  updateAgricultureReducer,
} from "../../../components/PortfolioData/reducers/agriculture";
import {
  bulkUpdateCrypto,
  deleteCryptoReducer,
  updateCryptoReducer,
} from "../../../components/PortfolioData/reducers/crypto";
import {
  bulkUpdateStartup,
  deleteStartupsReducer,
  updateStartupsReducer,
} from "../../../components/PortfolioData/reducers/startups";
import {
  bulkUpdateAlternativeInvestment,
  deleteAlternativeInvestmentsReducer,
  updateAlternativeInvestmentsReducer,
} from "../../../components/PortfolioData/reducers/alternativeInvestments";

import {
  bulkUpdatePension,
  deletePensionReducer,
  updatePensionReducer,
} from "../../../components/PortfolioData/reducers/pension";
import {
  bulkUpdateOwed,
  deleteOwedAssetReducer,
  updateOwedAssetReducer,
} from "../../../components/PortfolioData/reducers/owedAsset";
import {
  bulkUpdateBusiness,
  deleteBusinessReducer,
  updateBusinessReducer,
} from "../../../components/PortfolioData/reducers/businessAssets";
import {
  bulkUpdateProperty,
  deletePropertyReducer,
  updatePropertyReducer,
} from "../../../components/PortfolioData/reducers/propertyReducer";
import { updatePortfolioReducer } from "../../../components/PortfolioData/reducers/portfolio";
import updateEstimatedEstateValueReducer from "../../../components/PortfolioData/reducers/estimatedEstateValueReducer";
import currencyReducer from "@components/PortfolioData/reducers/currency";
import { updateCurrencyApi } from "@components/PortfolioData/actions/currency";

export const initialState: PortfolioDataInterface = {};

export const portfolioDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updatePortfolio, updatePortfolioReducer)
    .addCase(updateCashStore, (state, { payload }) => {
      state.cash = payload;
    })
    .addCase(updateBankAccountsStore, bulkUpdateBankAccount)
    .addCase(updateBankAccountItemStore, updateBankAccountReducer)
    .addCase(deleteBankAccountItemStore, deleteBankAccountReducer)
    .addCase(updateCommoditiesStore, updateCommoditiesStoreReducer)
    .addCase(updateCommodityStore, updateCommodityReducer)
    .addCase(deleteCommodityStore, deleteCommodityReducer)
    .addCase(updateDebtStore, bulkUpdateDebt)
    .addCase(updateDebtItemStore, updateDebtReducer)
    .addCase(updateEstimatedEstateValue, updateEstimatedEstateValueReducer)
    .addCase(updateCurrency, currencyReducer)
    .addCase(deleteDebtStore, deleteDebtReducer)
    .addCase(updateChildTrustStore, bulkUpdateDebtReducer)
    .addCase(updateChildTrustItemStore, updateChildTrustReducer)
    .addCase(deleteChildTrustItemStore, deleteChildTrustReducer)
    .addCase(updateFundsStore, bulkUpdateFund)
    .addCase(updateFundStore, updateFundsReducer)
    .addCase(deleteFundStore, deleteFundsReducer)
    .addCase(updateShareStore, bulkUpdateShare)
    .addCase(updateShareItemStore, updateShareReducer)
    .addCase(deleteShareItemStore, deleteShareReducer)
    .addCase(updateAgriculturesStore, bulkUpdateAgriculture)
    .addCase(updateAgricultureStore, updateAgricultureReducer)
    .addCase(deleteAgricultureStore, deleteAgricultureReducer)
    .addCase(updateCryptosStore, bulkUpdateCrypto)
    .addCase(updateCryptoStore, updateCryptoReducer)
    .addCase(deleteCryptoStore, deleteCryptoReducer)
    .addCase(updateStartupsStore, bulkUpdateStartup)
    .addCase(updateStartupStore, updateStartupsReducer)
    .addCase(deleteStartupStore, deleteStartupsReducer)
    .addCase(updateAlternativeInvestmentsStore, bulkUpdateAlternativeInvestment)
    .addCase(
      updateAlternativeInvestmentStore,
      updateAlternativeInvestmentsReducer
    )
    .addCase(
      deleteAlternativeInvestmentStore,
      deleteAlternativeInvestmentsReducer
    )
    .addCase(updateOwedAssetsStore, bulkUpdateOwed)
    .addCase(updateOwedAssetStore, updateOwedAssetReducer)
    .addCase(deleteOwedAssetStore, deleteOwedAssetReducer)
    .addCase(updateBusinessesStore, bulkUpdateBusiness)
    .addCase(updateBusinessStore, updateBusinessReducer)
    .addCase(deleteBusinessStore, deleteBusinessReducer)
    .addCase(updatePensionsStore, bulkUpdatePension)
    .addCase(updatePensionItemStore, updatePensionReducer)
    .addCase(deletePensionStore, deletePensionReducer)
    .addCase(updatePropertiesStore, bulkUpdateProperty)
    .addCase(updatePropertyStore, updatePropertyReducer)
    .addCase(deletePropertyStore, deletePropertyReducer);
});
