export const SIGN_UP = "AUTH:SIGN_UP";

export const LOGIN = "AUTH:LOGIN";

export const LOGOUT = "AUTH:LOGOUT";

export const FETCH_USER_INFO = "AUTH:FETCH_USER_INFO";
export const UPDATE_USER_INFO = "AUTH:UPDATE_USER_INFO";

export const IS_LOGGED_IN = "AUTH:IS_LOGGED_IN";

export const AUTH_ERROR = "AUTH:ERROR";
export const CLEAR_STORE = "AUTH:CLEAR_STORE";
