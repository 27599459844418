import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const SignUpWrapper = styled.div`
  width: 327px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.h4<{ align?: string }>`
  width: 100%;
  font-weight: bold;
  color: ${theme.colors.BrandNavy};
  text-align: ${({ align }) => align || "left"};
  margin: 3rem 0 2rem 0;
`;

export const Description = styled.p`
  margin: 0 0 2rem 0;
  color: ${theme.colors.Font02};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
`;

export const Spacer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export const List = styled.ul`
  list-style-type: circle;
  width: 80%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: 2rem;
`;

export const SignInText = styled.div`
  flex-direction: row;
  display: flex;
  font-weight: bold;
  justify-content: center;
  color: ${theme.colors.Primary01};
  margin-top: -2rem;
  margin-bottom: 1rem;
`;

export const SignInClick = styled.div`
  font-weight: bold;
  color: ${theme.colors.Primary01};
  cursor: pointer;
  margin-left: 0.3rem;
  &:hover {
    opacity: 0.8;
  }
`;

export const Item = styled.li`
  color: ${theme.colors.Primary01};
  font-weight: bold;
`;

export const TermsAndConditions = styled.a`
  text-decoration: underline;
`;

export const CharityLogoContainer = styled.div`
  width: 300px;
  height: 150px;
  margin-bottom: 2.5em;
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const CharityLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const PoweredByIFGContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7em;
  p {
    font-size: small;
    color: ${theme.colors.BrandBlue};
  }
`;

export const IFGLogoContainer = styled.div`
  height: 20px;
  & > svg {
    width: auto;
    height: 100%;
  }
`;
