export const FETCH_WILLS_API = "WILL:FETCH_WILLS_API";
export const UPDATE_WILLS_API = "WILL:UPDATE_WILLS_API";
export const FETCH_WILLS = "RESOURCES:FETCH_WILLS";

export const UPDATE_EXECUTOR_INFO = "WILL:UPDATE_EXECUTOR_INFO";
export const DELETE_EXECUTOR = "WILL:DELETE_EXECUTOR";
export const UPDATE_EXECUTORS = "WILL:UPDATE_EXECUTORS";

export const CREATE_EXECUTORS_API = "WILL:CREATE_EXECUTORS_API";
export const UPDATE_EXECUTORS_API = "WILL:UPDATE_EXECUTORS_API";
export const DELETE_EXECUTORS_API = "WILL:DELETE_EXECUTORS_API";

export const UPDATE_GUARDIAN_INFO = "WILL:UPDATE_GUARDIAN_INFO";
export const DELETE_GUARDIAN = "WILL:DELETE_GUARDIAN";
export const UPDATE_GUARDIANS = "WILL:UPDATE_GUARDIANS";

export const CREATE_GUARDIAN_API = "WILL:CREATE_GUARDIAN_API";
export const UPDATE_GUARDIAN_API = "WILL:UPDATE_GUARDIAN_API";
export const DELETE_GUARDIAN_API = "WILL:DELETE_GUARDIAN_API";

export const SUBMIT_USER_INFO = "WILL:SUBMIT_USER_INFO";

export const UPDATE_USER_INFO = "WILL:UPDATE_USER_INFO";

export const UPDATE_DEPARTING_MESSAGE = "WILL:UPDATE_DEPARTING_MESSAGE";

export const FETCH_FAMILY_API = "will:FETCH_FAMILY_API";
export const CREATE_FAMILY_API = "will:CREATE_FAMILY_API";
export const UPDATE_FAMILY_API = "will:UPDATE_FAMILY_API";
export const POPULATE_INHERITANCE_DATA = "will:POPULATE_INHERITANCE_DATA";
export const CREATE_FAMILY_MEMBER_API = "will:CREATE_FAMILY_MEMBER_API";
export const CREATE_FAMILY_MEMBER = "WILL:CREATE_FAMILY_MEMBER";
export const UPDATE_FAMILY_MEMBER = "WILL:UPDATE_FAMILY_MEMBER";
export const UPDATE_FAMILY = "will:UPDATE_FAMILY";

export const UPDATE_MEMBER_RECEIVING_BENEFIT =
  "UPDATE_MEMBER_RECEIVING_BENEFIT";

export const UPDATE_BENEFICIARY_DISABLED = "UPDATE_BENEFICIARY_DISABLED";

// =====================
//   GIFTS & BEQUESTS
// =====================
export const BQ_SELECT_EXISTING_INDIVIDUAL = "BQ:SELECT_EXISTING_INDIVIDUAL";
export const BQ_ADD_NEW_INDIVIDUAL = "BQ:ADD_NEW_INDIVIDUAL";
export const BQ_UPDATE_INDIVIDUAL = "BQ:UPDATE_INDIVIDUAL";
export const BQ_RESET_INDIVIDUAL = "BQ:RESET_INDIVIDUAL";
export const BQ_ADD_INDIVIDUAL_TO_LIST = "BQ:ADD_INDIVIDUAL_TO_LIST";
export const BQ_UPDATE_INDIVIDUAL_IN_LIST = "BQ:UPDATE_INDIVIDUAL_IN_LIST";
export const BQ_DELETE_INDIVIDUAL_FROM_LIST = "BQ:DELETE_INDIVIDUAL_FROM_LIST";
export const BQ_UPDATE_BEQUEST = "BQ:UPDATE_BEQUEST";

export const BQ_SELECT_EXISTING_CHARITY = "BQ:SELECT_EXISTING_CHARITY";
export const BQ_ADD_NEW_CHARITY = "BQ:ADD_NEW_CHARITY";
export const BQ_UPDATE_CHARITY = "BQ:UPDATE_CHARITY";
export const BQ_RESET_CHARITY = "BQ:RESET_CHARITY";
export const BQ_ADD_CHARITY_TO_LIST = "BQ:ADD_CHARITY_TO_LIST";
export const BQ_UPDATE_CHARITY_IN_LIST = "BQ:UPDATE_CHARITY_IN_LIST";
export const BQ_DELETE_CHARITY_FROM_LIST = "BQ:DELETE_CHARITY_FROM_LIST";

// Gifts & Bequests API actions
export const BQ_CREATE_BEQUEST_API = "WILL:CREATE_BEQUEST_API";
export const BQ_UPDATE_BEQUEST_API = "WILL:UPDATE_BEQUEST_API";
export const BQ_FETCH_BEQUEST_API = "WILL:FETCH_BEQUEST_API";
export const BQ_CREATE_INDIVIDUAL_BEQUEST_API =
  "WILL:CREATE_INDIVIDUAL_BEQUEST_API";
export const BQ_UPDATE_INDIVIDUAL_BEQUEST_API =
  "WILL:UPDATE_INDIVIDUAL_BEQUEST_API";
export const BQ_DELETE_INDIVIDUAL_BEQUEST_API =
  "WILL:DELETE_INDIVIDUAL_BEQUEST_API";
export const BQ_UPDATE_CHARITY_BEQUEST_API = "WILL:UPDATE_CHARITY_BEQUEST_API";
export const BQ_DELETE_CHARITY_BEQUEST_API = "WILL:DELETE_CHARITY_BEQUEST_API";
