import { AxiosPromise, AxiosStatic } from "axios";
import { AppleCallbackInput, AppleCallbackOutput } from "../types";

const appleCallback =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (data: AppleCallbackInput): AxiosPromise<AppleCallbackOutput> => {
    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/apple/callback`,
      data,
      {
        withCredentials: true,
      }
    );

    return response;
  };

export default appleCallback;
