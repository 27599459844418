import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { showBQPage } from "../../../../store/ui/actions";
import { useMediaQuery } from "react-responsive";
import Modal from "../../../../../legacyComponents/Modal/Modal.component";
import { PageTexts } from "../styles";
import { GiftsAndBequestPageEnum, PageMetaType } from "../types";
import { MEDIA_QUERIES } from "../../../../../components/mediaQueries";

const MobileLayout = styled.div`
  display: grid;
  gap: 2em;
`;

/**
 * This is a HOC that renders responsive modal
 * @param Elem
 * The component or page you want the modal to render
 * @param {PageMetaType} modalMeta - The page metadata
 * @param modalMeta.heading - The modal heading
 * @param modalMeta.subheading - The modal description
 * @param modalMeta.backPage - The page to go when **Back Button** is clicked
 */
export function withResponsiveModal<T>(
  Elem: React.ComponentType<T>,
  modalMeta: PageMetaType
): {
  (hocProps: T): JSX.Element;
  displayName: string;
} {
  const Component = (hocProps: T) => {
    const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
    const dispatch = useDispatch();

    const { LANDING } = GiftsAndBequestPageEnum;

    const { heading, subheading, backPage = LANDING } = modalMeta;

    return (
      <>
        {isMobile ? (
          <MobileLayout>
            <PageTexts heading={heading} subheading={subheading} />
            <div>
              <Elem {...hocProps} />
            </div>
          </MobileLayout>
        ) : (
          <Modal
            heading={heading}
            description={subheading}
            open={!isMobile}
            onClose={() =>
              dispatch(showBQPage(backPage as GiftsAndBequestPageEnum))
            }
          >
            <Elem {...hocProps} />
          </Modal>
        )}
      </>
    );
  };

  Component.displayName = "WithResponsiveModal";

  return Component;
}

export default withResponsiveModal;
