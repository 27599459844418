import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";
import { onError } from "apollo-link-error";
import { fromPromise } from "apollo-link";
import { CORE_API } from "gatsby-env-variables";
import getRefreshToken from "@components/Login/helpers/getRefreshToken";
import authSdk from "@components/Login/authSDK";

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

// For expedience, this is copied from Wealth
// which also seems to be taken from https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if ((err as any).statusCode) {
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver
          let forward$;

          switch ((err as any).statusCode) {
            case 401:
              if (!isRefreshing) {
                isRefreshing = true;
                forward$ = fromPromise(
                  getRefreshToken()
                    .then((res) => {
                      // Store the new tokens for your auth link
                      if (res?.status === 201) {
                        resolvePendingRequests();
                      } else {
                        pendingRequests = [];
                      }
                      return true;
                    })
                    .catch((error) => {
                      console.log(error);
                      pendingRequests = [];
                      authSdk.logout();

                      return forward(operation).map(() => {
                        return { errors: [error], data: null };
                      });
                    })
                    .finally(() => {
                      isRefreshing = false;
                    })
                ).filter((value) => Boolean(value));
              } else {
                // Will only emit once the Promise is resolved
                forward$ = fromPromise(
                  new Promise<void>((resolve) => {
                    pendingRequests.push(() => resolve());
                  })
                );
              }

              return forward$.flatMap(() => forward(operation));
          }
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(networkError.stack);
    }
  }
);

const httpLink = createHttpLink({
  uri: `${CORE_API}/graphql`,
  credentials: "include",
  fetch,
  headers: {
    "X-Application-Id": "web-app",
  },
});

const link = ApolloLink.from([errorLink as unknown as ApolloLink, httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
});
