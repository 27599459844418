import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

const Heading = styled.p`
  color: ${theme.colors.Font02};
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
`;

export default Heading;
