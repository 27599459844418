import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.Primary02};
  flex-direction: row;
  height: 2.25rem;
  margin: 0 20px 0 0;
`;

export default HeaderContainer;
