import React from "react";
import styled from "styled-components";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import { theme } from "../../../../styles/legacy-theme";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";

const Title = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

const Description = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  flex-direction: row;
  padding-right: 10px;
`;

type BequestAllocationMoneyProps = {
  title?: string;
  description?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
};

const BequestAllocationMoney = ({
  title,
  description,
  onChange,
  value,
  errorMessage,
}: BequestAllocationMoneyProps): JSX.Element => (
  <CardContainer>
    <ContentWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      <MoneyInput
        onChange={(value) => onChange(value)}
        value={value}
        errorMessage={errorMessage}
      />
    </ContentWrapper>
  </CardContainer>
);

export default BequestAllocationMoney;
