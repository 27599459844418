import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import styled from "styled-components";

import { Link } from "../../views/forms/aboutYou/pages/contact";
import { Spacer } from "../../views/signup/styled";
import InputWithComponent from "../../../legacyComponents/Form/input/InputWithComponent/InputWithComponent.component";
import Input from "../../../legacyComponents/Form/input/Input/Input.component";
import useGooglePlaces from "./hooks/useGooglePlaces";
import DropDownBox from "../../../legacyComponents/DropDown/styled/DropDownBox";
import DropDownItem from "../../../legacyComponents/DropDown/styled/DropDownItem";
import { theme } from "../../styles/theme";
import Cross from "../../../assets/icons/cross.svg";
import { GooglePrediction, GoogleAddress, FieldType } from "./types";
import { InlineLoader } from "@ifgengineering/component-library";

type AddressFieldsProps = {
  isOpen?: boolean;
  isOptional?: boolean;
  searchDescription?: string;
};

const AddressFields: React.FC<AddressFieldsProps> = ({
  isOpen = false,
  isOptional = false,
  searchDescription,
}) => {
  const { setFieldValue } = useFormikContext();
  const [manualInput, setManualInput] = useState<boolean>(isOpen);
  const [value, setValue] = useState("");
  const { loading, predictions, onSelectPlace } = useGooglePlaces({ value });
  const optionalLabel = isOptional ? "(Optional)" : "";

  useEffect(() => {
    setManualInput(isOpen);
  }, [isOpen]);

  const selectedAddress = (result: GoogleAddress) => {
    result.address_components.forEach((item) => {
      switch (true) {
        case item.types.includes("street_number"):
          return setFieldValue("addressLine1", item.long_name);
        case item.types.includes("route"):
          return setFieldValue("addressLine2", item.long_name);
        case item.types.includes("postal_town"):
          return setFieldValue("city", item.long_name);
        case item.types.includes("postal_code"):
          return setFieldValue("postcode", item.long_name);
      }
    });
    setValue("");
    setManualInput(true);
  };

  const renderPredictions = () =>
    predictions.map((pred: GooglePrediction, key) => (
      <DropDownItem
        key={key}
        onClick={() => onSelectPlace(pred.place_id, selectedAddress)}
      >
        {pred.description}
      </DropDownItem>
    ));

  const renderInputComponent = () => {
    if (loading) return <InlineLoader size={20} thickness={1} />;

    if (value)
      return (
        <Cross
          onClick={() => {
            setValue("");
          }}
        />
      );
  };

  return (
    <>
      <Link onClick={() => setManualInput((prev) => !prev)}>
        {manualInput ? "Cancel" : "Manual address"}
      </Link>
      {!manualInput && (
        <InputWithComponent
          component={renderInputComponent()}
          heading={`Address ${optionalLabel}`}
          description={
            searchDescription ||
            "Start typing your address and select it from the list below. If you can't find it try inputting it manually"
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      {predictions && predictions.length > 0 && (
        <StyledDropDownBox>{renderPredictions()}</StyledDropDownBox>
      )}

      {manualInput && (
        <Field name="addressLine1">
          {({ field, form: { errors, touched } }: FieldType) => (
            <Spacer>
              <Input
                heading="Address line 1"
                errorMessage={
                  touched.addressLine1 ? errors.addressLine1 : undefined
                }
                placeholder="Address line 1"
                {...field}
              />
            </Spacer>
          )}
        </Field>
      )}
      {manualInput && (
        <Field name="addressLine2">
          {({ field }: FieldType) => (
            <Spacer>
              <Input
                heading="Address line 2"
                placeholder="Address line 2"
                {...field}
              />
            </Spacer>
          )}
        </Field>
      )}
      {manualInput && (
        <Field name="city">
          {({ field, form: { errors, touched } }: FieldType) => (
            <Spacer>
              <Input
                heading="City"
                errorMessage={touched.city ? errors.city : undefined}
                placeholder="City"
                {...field}
              />
            </Spacer>
          )}
        </Field>
      )}
      {manualInput && (
        <Field name="postcode">
          {({ field, form: { errors, touched } }: FieldType) => (
            <Spacer>
              <Input
                heading="Postcode"
                errorMessage={touched.postcode ? errors.postcode : undefined}
                placeholder="Postcode"
                {...field}
              />
            </Spacer>
          )}
        </Field>
      )}
    </>
  );
};

export default AddressFields;

const StyledDropDownBox = styled(DropDownBox)`
  margin-top: -1rem;
`;
