import React from "react";
import { useMediaQuery } from "react-responsive";

export const MEDIA_QUERIES = {
  BIG_DESKTOP: "(min-width: 1800px)",
  TAB_LAND: "(max-width: 1200px)",
  TAB_PORT: "(max-width: 900px)",
  PHONE: "(max-width: 600px)",
  SMALL_PHONE: "(max-width: 300px)",
};

export const Desktop: React.FC = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return isDesktop ? <>{children}</> : null;
};

export const TabletLandscape: React.FC = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 900, maxWidth: 1200 });
  return isTablet ? <>{children}</> : null;
};

export const TabletPortrait: React.FC = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 900 });
  return isTablet ? <>{children}</> : null;
};

export const Mobile: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return isMobile ? <>{children}</> : null;
};

export const BigDesktop: React.FC = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 1800 });
  return isNotMobile ? <>{children}</> : null;
};
