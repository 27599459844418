const theme = {
  colors: {
    BrandBlue: "#305CFF",
    BrandNavy: "#26445A",
    CompleteGreen: "#1B9907",
    ErrorRed: "#FF0000",
    Font01: "#B7C1C8",
    Font02: "#435D70",
    Grey01: "#E2E2E2",
    Grey02: "#B7C1C8",
    InProgress: "#F0BC5E",
    LightBlue: "#dbeffd",
    Primary01: "#26445A",
    Primary02: "#FFFFFF",
    Primary03: "#1B2474",
  },
};

export { theme };
