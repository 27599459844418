import { AxiosStatic } from "axios";

const resendVerifyEmail =
  (serverURL: string, appAxios: AxiosStatic) => async (): Promise<void> => {
    await appAxios.post(
      `${serverURL}/auth/generate-verify-email-link`,
      {},
      {
        withCredentials: true,
      }
    );
  };

export default resendVerifyEmail;
