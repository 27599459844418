import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import storage from "redux-persist/lib/storage";
import { discoverReducer } from "./discover/discover.reducer";
import { shortlistReducer } from "./shortlist/shortlist.reducer";
import { insightsReducer } from "./insights/insights.reducer";
import { persistReducer } from "redux-persist";
import { portfolioDataReducer } from "@Wealth/state/portfolio/reducers";
import { cryptoReducer } from "./crypto/crypto.reducer";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["isAuthenticated"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  discover: discoverReducer,
  shortlist: shortlistReducer,
  portfolioData: portfolioDataReducer,
  insights: insightsReducer,
  crypto: cryptoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
