import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/integration/react";
import {
  VERSION,
  RECAPTCHA_CLIENT_KEY,
  SENTRY_DSN,
} from "gatsby-env-variables";
import { CurrencyContextProvider } from "../components/CurrencyContext/index";
import GlobalStyle from "./styles/global-styles";
import { theme } from "../styles/legacy-theme";
import store, { persistor } from "./store";
import { client } from "../graphql";
import { AuthComponentsProvider } from "@ifgengineering/client-auth-components";
import {
  isCur8InWealthEnabled,
  isLayoutBEnabled,
  isMagicCodeEnabled,
} from "../helpers/useFeatureFlag";
import { logException } from "../helpers/logException";
import useAnalytics from "../helpers/useAnalytics";
import {
  loginAction,
  resetPassword,
  sendResetPasswordLink,
  signupAction,
  verifyEmail,
} from "../components/Login/actions";
import authSdk from "../components/Login/authSDK";
import { initSentry } from "../helpers/logException";
import axios from "axios";

const Startup = ({ element }) => {
  console.log("VERSION:", VERSION);
  initSentry(SENTRY_DSN);

  useEffect(() => {
    axios.defaults.headers.post["x-application-id"] = "web-app";
    axios.defaults.headers.common["x-application-id"] = "web-app";
  }, []);

  const { passwordlessVerify, passwordlessStart } = authSdk;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <title>Zakat Calculator</title>
          <meta
            name="description"
            content="Islamic Finance Guru Halal Investment Platform"
          />
          <html lang="en-GB" />
          <script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            async
          />
        </Helmet>
        <ApolloProvider client={client}>
          <AuthComponentsProvider
            isCur8InWealthEnabled={isCur8InWealthEnabled}
            isLayoutBEnabled={isLayoutBEnabled}
            isMagicCodeEnabled={isMagicCodeEnabled}
            logException={logException}
            recaptchaClientKey={RECAPTCHA_CLIENT_KEY}
            useAnalytics={useAnalytics}
            verifyEmail={verifyEmail}
            loginAction={loginAction}
            signupAction={signupAction}
            sendResetPasswordLink={sendResetPasswordLink}
            resetPassword={resetPassword}
            passwordlessVerify={passwordlessVerify}
            passwordlessStart={passwordlessStart}
          >
            <ThemeProvider theme={theme}>
              <CurrencyContextProvider>{element}</CurrencyContextProvider>
            </ThemeProvider>
            <ToastContainer />
          </AuthComponentsProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <Startup element={element} />;
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  );
};

export default { wrapRootElement, wrapPageElement };
