import { AxiosPromise, AxiosStatic } from "axios";
import { LoginInput } from "../types";
import { Investor } from "@ifgengineering/client-invest-sdk";

const login =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async ({
    email,
    password,
    otp,
    token,
    captchaToken,
  }: LoginInput): AxiosPromise<Investor> => {
    const endpoint = token ? "forgot-password/login" : "login";

    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/${endpoint}`,
      {
        email,
        password,
        otp,
        token,
        captchaToken,
      },
      {
        withCredentials: true,
      }
    );

    return response;
  };

export default login;
