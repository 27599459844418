import styled from "styled-components";
import { theme } from "../../../styles/theme";

type StyledRadioInputProps = {
  checked: boolean;
  value: string;
  name?: string;
  onClick: () => void;
};

export const StyledRadioInput = styled.div<StyledRadioInputProps>`
  cursor: pointer;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin: 10px 10px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ checked }) =>
    checked ? theme.colors.BLUE600 : theme.colors.WHITE};
  border: 1px solid
    ${({ checked }) => (checked ? theme.colors.BLUE600 : theme.colors.SLATE400)};

  &::after {
    content: "";
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: ${theme.colors.WHITE};
  }
`;

export default StyledRadioInput;
