import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const StyledWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button:first-of-type {
    margin-right: 1rem;
  }
`;

type StyledButtonProps = {
  highlighted: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) =>
    props.highlighted ? theme.colors.Primary01 : theme.colors.Primary02};
  border-radius: 8px;
  border: 1px solid ${theme.colors.Grey01};
  color: ${(props) =>
    props.highlighted ? theme.colors.Primary02 : theme.colors.Font02};
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  height: 136px;
  max-height: 136px;
  max-width: 156px;
  width: 100%;
`;

type Props = {
  onChange: (value: boolean) => void;
};

const BoxRadioButton = ({ onChange }: Props): JSX.Element => {
  const [decision, setDecision] = useState<boolean | null>(null);

  useEffect(() => {
    if (decision !== null) {
      onChange(decision);
    }
  }, [decision]);

  return (
    <StyledWrapper>
      <StyledButton
        data-testid="no-button"
        onClick={() => setDecision(false)}
        highlighted={decision === false}
      >
        No
      </StyledButton>
      <StyledButton
        data-testid="yes-button"
        onClick={() => setDecision(true)}
        highlighted={decision === true}
      >
        Yes
      </StyledButton>
    </StyledWrapper>
  );
};

export default BoxRadioButton;
