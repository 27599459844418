import { AxiosPromise, AxiosStatic } from "axios";
import { AppleLinkEmailInput, AppleLinkEmailOutput } from "../types";

const appleLinkEmail =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (data: AppleLinkEmailInput): AxiosPromise<AppleLinkEmailOutput> => {
    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/apple/link`,
      data,
      {
        withCredentials: true,
      }
    );

    return response;
  };

export default appleLinkEmail;
