import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";
import Button, {
  ButtonStates,
} from "../../../legacyComponents/Button/Button.component";

const StyledHeader = styled.h3`
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${theme.colors.Font02};
`;

export enum RadioButtonDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

type StyledRadioButtonWrapperProps = {
  direction: RadioButtonDirection;
};

const StyledRadioButtonWrapper = styled.div<StyledRadioButtonWrapperProps>`
  display: flex;
  ${({ direction }) => {
    if (direction === RadioButtonDirection.VERTICAL) {
      return `
        flex-direction: column;
          & > button {
            margin-bottom: 1rem;
          }
      `;
    }

    return `
      flex-direction: row;
        & > button {
          margin-right: 1rem;        
        }    
    `;
  }}
`;

export type RadioButtonDefinition = {
  key: string;
  text: string;
  value: string | number;
};

type RadioButtonProps = {
  value?: string | number;
  buttons: RadioButtonDefinition[];
  direction?: RadioButtonDirection;
  heading?: string;
  onChange?: (value: string | number | null) => void;
};

const RadioButton = ({
  value,
  buttons,
  onChange,
  heading,
  direction = RadioButtonDirection.HORIZONTAL,
}: RadioButtonProps): JSX.Element => {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | number | null>(
    value || null
  );

  useEffect(() => {
    if (value) {
      const key = buttons.find((button) => button.value === value)?.key || null;

      setSelectedKey(key);
      setSelectedValue(value);
    } else {
      const [firstbutton] = buttons;

      if (firstbutton) {
        setSelectedKey(firstbutton.key);
        setSelectedValue(firstbutton.value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div>
      <StyledHeader>{heading}</StyledHeader>
      <StyledRadioButtonWrapper direction={direction}>
        {buttons.map(({ key, text, value }) => (
          <Button
            text={text}
            key={key}
            version={
              selectedKey === key
                ? ButtonStates.PRIMARY
                : ButtonStates.PRIMARY_GHOST_LARGE
            }
            onClick={() => {
              setSelectedValue(value);
              setSelectedKey(key);
            }}
          />
        ))}
      </StyledRadioButtonWrapper>
    </div>
  );
};

export default RadioButton;
