import React, { useState, useRef } from "react";
import Container from "../styled/Container";
import Text from "../styled/Text";
import Heading from "../styled/Heading";
import DateInputWrapper from "../styled/DateInputWrapper";
import SectionInput from "../styled/SectionInput";
import ErrorContainer from "../styled/ErrorContainer";
import { validateNumerical } from "./utils/validation";

type Props = {
  day: string;
  month: string;
  year: string;
  setDay: (value: string) => void;
  setMonth: (value: string) => void;
  setYear: (value: string) => void;
  validation?: () => void;
  heading?: string;
  errorMessage?: string;
  description?: string;
};

const DateInput: React.FC<Props> = ({
  day,
  month,
  year,
  setDay,
  setMonth,
  setYear,
  heading,
  errorMessage,
  description,
  validation,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const dayInput = useRef<HTMLInputElement>(null);
  const monthInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);

  const onChangeDay = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if ((parseInt(value) < 32 || value === "") && value.length < 3)
      setDay(value);
    if (value.length === 2) monthInput.current && monthInput.current.focus();
  };

  const onChangeMonth = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if ((parseInt(value) < 13 || value === "") && value.length < 3)
      setMonth(value);
    if (value.length === 2) yearInput.current && yearInput.current.focus();
  };

  const onChangeYear = (e: React.FormEvent) => {
    const { value } = e.target as HTMLInputElement;
    if (value.length < 5) setYear(value);
  };

  const keyDownDay = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    e.key === "ArrowRight" &&
      !value &&
      monthInput.current &&
      monthInput.current.focus();
  };

  const keyDownMonth = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    if (e.key === "ArrowRight" && !value)
      yearInput.current && yearInput.current.focus();
    if ((e.key === "Backspace" || e.key === "ArrowLeft") && !value)
      dayInput.current && dayInput.current.focus();
  };

  const keyDownYear = (
    e: React.KeyboardEvent<HTMLInputElement>,
    value: string
  ) => {
    validateNumerical(e);
    (e.key === "Backspace" || e.key === "ArrowLeft") &&
      !value &&
      monthInput.current &&
      monthInput.current.focus();
  };

  const onBlurAction = () => {
    setIsFocused(false);
    validation && validation();
  };

  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      <DateInputWrapper focused={isFocused} hasError={!!errorMessage}>
        <SectionInput
          data-testid="day-input"
          ref={dayInput}
          onFocus={() => setIsFocused(true)}
          onBlur={onBlurAction}
          type="number"
          placeholder="DD"
          onChange={onChangeDay}
          onKeyDown={(e) => keyDownDay(e, day)}
          value={day}
        />
        /
        <SectionInput
          data-testid="month-input"
          ref={monthInput}
          onFocus={() => setIsFocused(true)}
          onBlur={onBlurAction}
          type="number"
          placeholder="MM"
          onChange={onChangeMonth}
          onKeyDown={(e) => keyDownMonth(e, month)}
          value={month}
        />
        /
        <SectionInput
          data-testid="year-input"
          ref={yearInput}
          onFocus={() => setIsFocused(true)}
          onBlur={onBlurAction}
          type="number"
          placeholder="YYYY"
          onChange={onChangeYear}
          onKeyDown={(e) => keyDownYear(e, year)}
          value={year}
        />
      </DateInputWrapper>
      <ErrorContainer>
        {errorMessage && <Text hasError={!!errorMessage}>{errorMessage}</Text>}
      </ErrorContainer>
    </Container>
  );
};

export default DateInput;
