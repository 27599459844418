import { WillsState } from "../../constants/types";

const deleteCharityFromListReducer = (
  state: WillsState,
  action: {
    payload?: number;
  }
) => {
  const updatedBqCharities = state.will.bequests.bequestCharity?.filter(
    (charity) => charity.id !== action.payload
  );
  state.will.bequests.bequestCharity = updatedBqCharities;
};

export default deleteCharityFromListReducer;
