import {
  PortfolioDataInterface,
  ShareInterface,
  ShareTickerInterface,
} from "@ifgengineering/hip-app-domain";

export type ShareTickerPayload = {
  payload: ShareTickerInterface;
};

const sumAll = (list: ShareTickerInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.totalValue, 0);
};

export const deleteShareReducer = (
  state: PortfolioDataInterface,
  { payload }: ShareTickerPayload
): void => {
  if (!state.stocks) {
    state.stocks = {
      id: 0,
      totalValue: 0,
      shareList: [],
    };
  }

  state.stocks.shareList = state.stocks?.shareList?.filter(
    (share) => share.id !== payload.id
  );

  state.stocks.totalValue = sumAll(state.stocks.shareList);
};

export const updateShareReducer = (
  state: PortfolioDataInterface,
  { payload }: ShareTickerPayload
): void => {
  if (!state.stocks) {
    state.stocks = {
      id: 0,
      totalValue: 0,
      shareList: [],
    };
  }

  const fundExist = state.stocks?.shareList?.some(
    (share) => share.id === payload.id
  );

  if (fundExist) {
    state.stocks.shareList = state.stocks?.shareList?.map((share) => {
      if (share.id === payload.id) {
        return payload;
      }

      return share;
    });
  } else {
    state.stocks.shareList?.push(payload);
  }

  state.stocks.totalValue = sumAll(state.stocks.shareList);
};

export type BulkUpdatePayload = {
  payload: ShareInterface;
};

export const bulkUpdateShare = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.stocks = payload;
};
