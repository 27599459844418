import styled from "styled-components";
import Text from "../../Text/Text.component";

type RadioLabelProps = {
  checked: boolean;
  name?: string;
  onClick?: () => void;
};

const RadioLabel = styled(Text)<RadioLabelProps>`
  cursor: pointer;
  margin: 7px 0;
`;
export default RadioLabel;
