import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

type WrapperProps = {
  hasError?: boolean;
  focused?: boolean;
};

const DateInputWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  color: ${theme.colors.Font02};
  padding: 0 1rem;
  height: 3rem;
  outline: transparent;
  border-radius: 12px;
  border: ${({ hasError, focused }) => {
    switch (true) {
      case hasError:
        return `1px ${theme.colors.ErrorRed} solid`;
      case focused:
        return `1px ${theme.colors.Primary01} solid`;
      default:
        return `1px ${theme.colors.Grey02} solid`;
    }
  }};
`;

export default DateInputWrapper;
