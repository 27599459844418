export const FETCH_ZAKAT = "ZAKAT:FETCH_ZAKAT";
export const CREATE_ZAKAT = "ZAKAT:CREATE_ZAKAT";
export const UPDATE_ZAKAT = "ZAKAT:UPDATE_ZAKAT";
export const FETCH_OR_CREATE_ZAKAT = "ZAKAT:FETCH_OR_CREATE_ZAKAT";

export const FETCH_GIFT_AID = "ZAKAT:FETCH_GIFT_AID";
export const CREATE_GIFT_AID = "ZAKAT:CREATE_GIFT_AID";
export const UPDATE_GIFT_AID = "ZAKAT:UPDATE_GIFT_AID";

export const FETCH_CHARITY_ALLOCATION_API =
  "ZAKAT:FETCH_CHARITY_ALLOCATION_API";
export const CREATE_UPDATE_CHARITY_ALLOCATION_API =
  "ZAKAT:CREATE_UPDATE_CHARITY_ALLOCATION_API";
export const CREATE_UPDATE_CHARITY_ALLOCATION =
  "ZAKAT:CREATE_UPDATE_CHARITY_ALLOCATION";
export const FETCH_ZAKAT_CHAIRTY_API = "ZAKAT:FETCH_ZAKAT_CHAIRTY_API";

export const FETCH_SILVER_PRICE_API = "ZAKAT:FETCH_SILVER_PRICE_API";

export const FETCH_REFERRAL_CODE_API = "ZAKAT:FETCH_REFERRAL_CODE_API";
