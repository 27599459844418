import {
  TwoFactorAuthDetailsInput,
  TwoFactorAuthDetailsOutput,
} from "../types";
import { AxiosStatic } from "axios";

const getTwoFactorAuthDetails =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async ({
    email,
    password,
    captchaToken,
  }: TwoFactorAuthDetailsInput): Promise<TwoFactorAuthDetailsOutput> => {
    try {
      const res = await appAxios.post(
        `${serverURL}/auth${versionURLFragment}/2fa/details`,
        {
          email,
          password,
          captchaToken,
        },
        {
          withCredentials: true,
        }
      );

      return res.data;
    } catch (error: any) {
      return { error };
    }
  };

export default getTwoFactorAuthDetails;
