import { UserInfoOutput } from "../types";
import { AxiosStatic } from "axios";

const userInfo =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (): Promise<UserInfoOutput> => {
    const response = await appAxios.get(
      `${serverURL}/auth${versionURLFragment}/user-info`,
      {
        withCredentials: true,
      }
    );

    if (response.data.message) {
      return response.data as UserInfoOutput;
    }

    return {
      state: "AUTHORIZED",
      user: response.data,
    };
  };

export default userInfo;
