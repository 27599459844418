import { WillsState } from "../../constants/types";

const deleteIndividualFromListReducer = (
  state: WillsState,
  action: {
    payload?: number;
  }
) => {
  const updatedBqIndividuals = state.will.bequests.bequestIndividuals?.filter(
    (individual) => individual.id !== action.payload
  );
  state.will.bequests.bequestIndividuals = updatedBqIndividuals;
};

export default deleteIndividualFromListReducer;
