import styled from "styled-components";

export const DateInputWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  font-size: 1rem;
  gap: 8px;
  margin-top: 8px;
  outline: transparent;
  border-radius: 12px;
`;
