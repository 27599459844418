import { gql } from "@apollo/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../graphql";
import {
  UPDATE_CHARITY,
  FETCH_DONATION,
  CREATE_DONATION,
  UPDATE_DONATION,
} from "./consts";
import { GraphQLError } from "graphql";
import {
  PartnerCharityDonationInterface,
  CharityInterface,
} from "@ifgengineering/hip-app-domain";
import { logException } from "@helpers/logException";

export const updateCharityStore = createAction<CharityInterface, string>(
  UPDATE_CHARITY
);

const charityDoesNotExist = (errors: readonly GraphQLError[]) =>
  errors.length === 1 &&
  errors.some(({ message }) =>
    message.match(/Partner charity does not exist/gi)
  );

export const fetchCharityByKeyAPI = createAsyncThunk<CharityInterface, string>(
  UPDATE_CHARITY,
  async (key) => {
    try {
      const response = await client.query({
        query: gql`
          query ($key: String!) {
            partnerCharity(key: $key) {
              id
              key
              registeredName
              displayName
              logoKey
              causesList {
                id
                nameOfCause
                description
                imageKey
              }
              productList {
                id
                productType
                amount
                active
              }
            }
          }
        `,
        variables: {
          key,
        },
        fetchPolicy: "no-cache",
      });

      if (response.errors && !charityDoesNotExist(response.errors)) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.partnerCharity;
    } catch (e: any) {
      if (!charityDoesNotExist(e.networkError.result.errors)) {
        toast.warn("Something went wrong. Try again or contact support");

        logException({
          tag: "fetchCharityByKeyAPI",
          exception: e,
        });

        throw Error(e);
      }
    }
  }
);

export const fetchCharityByIdAPI = createAsyncThunk<CharityInterface, string>(
  UPDATE_CHARITY,
  async (id) => {
    try {
      const response = await client.query({
        query: gql`
          query ($id: String!) {
            partnerCharityById(id: $id) {
              id
              key
              registeredName
              displayName
              logoKey
              causesList {
                id
                nameOfCause
                description
                imageKey
              }
              productList {
                id
                productType
                amount
                active
              }
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
      });

      if (response.errors && !charityDoesNotExist(response.errors)) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.partnerCharityById;
    } catch (e: any) {
      if (!charityDoesNotExist(e.networkError.result.errors)) {
        toast.warn("Something went wrong. Try again or contact support");

        logException({
          tag: "fetchCharityByIdAPI",
          exception: e,
        });

        throw Error(e);
      }
    }
  }
);

export const DONATION_FRAGMENT = `
    id
    amount
    giftAid
    title
`;

export const fetchPartnerCharityDonation = createAsyncThunk<
  PartnerCharityDonationInterface,
  string
>(FETCH_DONATION, async (email) => {
  try {
    const response = await client.query({
      query: gql`
        query($email: String!) {
          partnerCharityDonation(email: $email) {
            ${DONATION_FRAGMENT}
          }
        }
      `,
      variables: {
        email,
      },
      fetchPolicy: "no-cache",
    });

    return response.data?.partnerCharityDonation;
  } catch (e) {
    toast.error("Something went wrong. Try again or contact support");
    throw e;
  }
});

export const createPartnerCharityDonation = createAsyncThunk<
  PartnerCharityDonationInterface,
  {
    email: string;
    data: {
      amount: number;
      giftAid: boolean;
      title?: string;
    };
  }
>(CREATE_DONATION, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation($data: PartnerCharityDonationInput!, $email: String!) {
          createPartnerCharityDonation(data: $data, email: $email) {
            ${DONATION_FRAGMENT}
          }
        }
      `,
      variables: {
        email,
        data,
      },
      fetchPolicy: "no-cache",
    });

    if (!response.data?.createPartnerCharityDonation) {
      toast.error("There was an error adding donation");
      return;
    }

    return response.data?.createPartnerCharityDonation;
  } catch (e) {
    toast.error("Something went wrong. Try again or contact support");
    throw e;
  }
});

export const updateCharityDonation = createAsyncThunk<
  PartnerCharityDonationInterface,
  {
    email: string;
    data: {
      amount: number;
      giftAid: boolean;
      title?: string;
    };
  }
>(UPDATE_DONATION, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation($data: PartnerCharityDonationInput!, $email: String!) {
          updatePartnerCharityDonation(data: $data, email: $email) {
            ${DONATION_FRAGMENT}
          }
        }
      `,
      variables: {
        email,
        data,
      },
      fetchPolicy: "no-cache",
    });

    if (!response.data?.updatePartnerCharityDonation) {
      toast.error("There was an error updating donation");
      return;
    }

    return response.data?.updatePartnerCharityDonation;
  } catch (e) {
    toast.error("Something went wrong. Try again or contact support");
    throw e;
  }
});
