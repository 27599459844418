import { DEFAULT_SILVER_PRICE } from "@Zakat/lib/zakat/consts";
import React, { FC, useState, createContext } from "react";
import { ContextCurrencies } from "./constants";
import { Currency } from "@ifgengineering/hip-app-domain";

type ContextType = {
  currencySymbol: string;
  formatCurrency: (val: number) => string;
  setCurrency: React.Dispatch<React.SetStateAction<Currency>>;
  currency: Currency;
  silverPrice: number;
  setSilverPrice: React.Dispatch<React.SetStateAction<number>>;
};

// We can't have empty functions. We need to set a default here.
// So I'm create this informative one that does nothing
const emptyDefault = () => {
  console.log("You need to define a callback for it");
};

export const CurrencyContext = createContext<ContextType>({
  currencySymbol: "gbp",
  formatCurrency: () => "gbp",
  currency: "gbp",
  setCurrency: emptyDefault,
  silverPrice: DEFAULT_SILVER_PRICE,
  setSilverPrice: emptyDefault,
});

export const moneyfy =
  (locale: string, config?: { noDecimals?: boolean }) =>
  (val: number): string => {
    const value = val || 0;
    const decimals = config?.noDecimals ? 0 : undefined;
    return value.toLocaleString("en", {
      style: "currency",
      currency: locale,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  };

export const CurrencyContextProvider: FC = ({ children }) => {
  const [currency, setCurrency] =
    useState<keyof typeof ContextCurrencies>("gbp");

  const [silverPrice, setSilverPrice] = useState<number>(DEFAULT_SILVER_PRICE);

  const currencySymbol = ContextCurrencies[currency];

  return (
    <CurrencyContext.Provider
      value={{
        currencySymbol,
        formatCurrency: moneyfy(currency),
        currency,
        setCurrency,
        silverPrice,
        setSilverPrice,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
