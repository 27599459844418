import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const StyledText = styled.p<{ clickable?: boolean }>`
  color: ${theme.colors.Font02};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default StyledText;
