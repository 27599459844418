import styled from "styled-components";
import { theme } from "../../styles";

const GenericInput = styled.input<{
  focused?: boolean;
  hasError?: boolean;
  width?: string;
  withIcon?: boolean;
}>`
  text-align: left;
  padding: ${({ withIcon }) => (withIcon ? "16px 45px 16px 16px" : "16px")};
  color: ${theme.colors.SLATE800};
  background: ${theme.colors.WHITE};
  font-size: 1rem;
  outline: none;
  border-radius: 4px;
  width: ${({ width }) => width || "100%"};
  height: 56px;
  border: ${({ hasError, focused }) => {
    switch (true) {
      case hasError:
        return `1px ${theme.colors.ERROR800} solid`;
      case focused:
        return `1px ${theme.colors.BLUE600} solid`;
      default:
        return `1px ${theme.colors.SLATE300} solid`;
    }
  }};
  &:focus {
    border: 1px ${theme.colors.BLUE600} solid;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-ms-reveal {
    display: none;
  }
  -moz-appearance: textfield;
`;

export default GenericInput;
