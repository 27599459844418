import {
  CommodityItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";
import { CommodityObject } from "../constants/types";

export type CommodityReducerPayload = {
  payload: CommodityItemInterface;
};

const sumAll = (commodities: CommodityItemInterface[]) =>
  commodities.reduce((acc, commodities: CommodityItemInterface) => {
    return acc + (Number(commodities?.monetaryValue) || 0);
  }, 0);

export const updateCommodityReducer = (
  state: PortfolioDataInterface,
  { payload }: CommodityReducerPayload
): void => {
  if (!state.commodities) {
    state.commodities = {
      totalValue: 0,
      commoditiesList: [],
    };
  }

  const commodityAlreadyListed = state.commodities.commoditiesList?.some(
    (commodities) => commodities.id === payload.id
  );

  if (commodityAlreadyListed) {
    const commodities =
      state.commodities?.commoditiesList?.map((commodities) => {
        return commodities.id === payload.id ? payload : commodities;
      }) || [];

    state.commodities.commoditiesList = commodities;
  } else {
    state.commodities.commoditiesList?.push(payload);
  }

  state.commodities = {
    ...state.commodities,
    totalValue: sumAll(state.commodities.commoditiesList || []),
  };
};

export const deleteCommodityReducer = (
  state: PortfolioDataInterface,
  { payload }: CommodityReducerPayload
): void => {
  const commoditiesList =
    state.commodities?.commoditiesList?.filter(
      (commodities) => commodities.id !== payload.id
    ) || [];

  state.commodities = {
    commoditiesList,
    totalValue: sumAll(commoditiesList),
  };
};

export type CommoditiesReducerPayload = {
  payload: CommodityObject;
};

export const updateCommoditiesStoreReducer = (
  state: PortfolioDataInterface,
  { payload }: CommoditiesReducerPayload
): void => {
  state.commodities = payload;
};
