import { ColorProps } from "../types";
import { theme } from "../../../styles";

export const defaultColors: ColorProps = {
  background: theme.colors.WHITE,
  backgroundChecked: theme.colors.BLUE600,
  button: theme.colors.SLATE600,
  buttonChecked: theme.colors.WHITE,
  hover: theme.colors.BLUE100,
  hoverChecked: theme.colors.BLUE700,
  focus: theme.colors.BLUE300,
  border: theme.colors.SLATE200,
} as ColorProps;
