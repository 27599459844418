import styled from "styled-components";
import Text from "../../Text/Text.component";

type CheckboxLabelProps = {
  checked: boolean;
  name?: string;
  onClick?: () => void;
};

const CheckboxLabel = styled(Text)<CheckboxLabelProps>`
  cursor: ${({ checked }) => (checked ? "default" : "pointer")};
`;
export default CheckboxLabel;
