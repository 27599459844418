import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bqIndividualSelector } from "../../../../../../components/Wills/selectors";
import {
  bqAddIndividualToList,
  bqUpdateIndividual,
  bqUpdateIndividualInList,
} from "../../../../../../components/Wills/actions";
import { showBQPage } from "../../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import {
  createIndividualBequestAPI,
  updateIndividualBequestAPI,
} from "../../../../../../components/Wills/actions/bequest";
import WhatWouldYouLikeToLeave, {
  WhatWouldYouLikeToLeaveBequest,
} from "../../forms/WhatWouldYouLikeToLeave";
import { prevBQPage } from "../../../../../store/ui/selectors";
import store from "../../../../../store";
import { getUserEmail } from "../../../../../../components/Login/selectors";

const BequestEstateAllocation: React.FC = () => {
  const bqIndividual = useSelector(bqIndividualSelector);
  const userEmail = useSelector(getUserEmail);
  const previousPage = useSelector(prevBQPage);
  const dispatch = useDispatch<typeof store.dispatch>();

  const {
    LANDING,
    PART_OR_ABOVE_SHARE,
    NEW_INDIVIDUAL,
    EXISTING_INDIVIDUAL_ADDRESS,
    EDIT_INDIVIDUAL,
  } = GiftsAndBequestPageEnum;

  const handleSubmit = async (values: WhatWouldYouLikeToLeaveBequest) => {
    const { id, ...individual } = bqIndividual;
    const individualToSubmit = {
      ...individual,
      ...values,
    };

    let returnedBqIndividual: any = {};
    switch (previousPage) {
      case NEW_INDIVIDUAL:
      case EXISTING_INDIVIDUAL_ADDRESS:
        returnedBqIndividual = await dispatch(
          createIndividualBequestAPI({
            data: individualToSubmit,
            email: userEmail,
          })
        ).unwrap();
        dispatch(bqUpdateIndividual(returnedBqIndividual));
        dispatch(bqAddIndividualToList(returnedBqIndividual));

        break;
      case LANDING:
      case EDIT_INDIVIDUAL:
        returnedBqIndividual = await dispatch(
          updateIndividualBequestAPI({
            data: {
              ...bqIndividual,
              ...values,
            },
          })
        ).unwrap();
        dispatch(bqUpdateIndividual(returnedBqIndividual));
        dispatch(bqUpdateIndividualInList(returnedBqIndividual));
        break;
      default:
        break;
    }
    if (bqIndividual.islamicInheritor) {
      dispatch(showBQPage(PART_OR_ABOVE_SHARE));
    } else {
      dispatch(showBQPage(LANDING));
    }
  };

  return (
    <WhatWouldYouLikeToLeave
      bequest={bqIndividual}
      charityName={bqIndividual.firstName}
      onSubmit={(values) => handleSubmit(values)}
      showItems={true}
      leftArrowOnClick={() => dispatch(showBQPage(previousPage))}
    />
  );
};

export default BequestEstateAllocation;
