import styled, { keyframes } from "styled-components";

export const Animation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

export const DefaultLoader = styled.div<{
  size: number;
  frontColor: string;
  backColor: string;
  thickness: number;
  position: "absolute" | "relative" | "fixed";
}>`
  position: ${({ position }) => position};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ thickness }) => thickness}px solid
    ${({ backColor }) => backColor};
  border-bottom-color: ${({ frontColor }) => frontColor};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${Animation} 1s linear infinite;
`;

export const Spinner = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
