import DisabledBeneficiaries from "./pages/disabled-beneficiaries";
import BequestEstateAllocation from "./pages/people/estate-allocation";
import ExistingIndividual from "./pages/people/existing-individual";
import ExistingIndividualAddress from "./pages/people/existing-individual-address";
import GiftsAndBequestLanding from "./pages/landing";
import MeansTestedBenefits from "./pages/means-tested-benefits";
import NewIndividual from "./pages/people/new-individual";
import PartOrAboveShare from "./pages/people/part-or-above-share";
import NewCharity from "./pages/charity/new-charity";
import EditCharity from "./pages/charity/edit-charity";
import BequestCharityEstate from "./pages/charity/charity-estate";
import EditIndividual from "./pages/people/edit-individual";
import SponsoredCharity from "./pages/charity/SponsoredCharity";
import SponsoredCharityDonation from "./pages/charity/SponsoredCharityDonation";
import SponsoredCharityThanks from "./pages/charity/SponsoredCharityThanks ";

export enum GiftsAndBequestPageEnum {
  LANDING = "LANDING",
  NEW_INDIVIDUAL = "NEW_INDIVIDUAL",
  EDIT_INDIVIDUAL = "EDIT_INDIVIDUAL",
  EXISTING_INDIVIDUAL = "EXISTING_INDIVIDUAL",
  EXISTING_INDIVIDUAL_ADDRESS = "EXISTING_INDIVIDUAL_ADDRESS",
  ESTATE_ALLOCATION = "ESTATE_ALLOCATION",
  PART_OR_ABOVE_SHARE = "PART_OR_ABOVE_SHARE",
  MEANS_TESTED_BENEFITS = "MEANS_TESTED_BENEFITS",
  DISABLED_BENEFICIARIES = "DISABLED_BENEFICIARIES",
  NEW_CHARITY = "NEW_CHARITY",
  EDIT_CHARITY = "EDIT_CHARITY",
  CHARITY_ESTATE = "CHARITY_ESTATE",
  SPONSORED_CHARITY = "SPONSORED_CHARITY",
  SPONSORED_CHARITY_DONATION = "SPONSORED_CHARITY_DONATION",
  SPONSORED_CHARITY_THANKS = "SPONSORED_CHARITY_THANKS",
}

export const GiftsAndBequestPages: Record<
  GiftsAndBequestPageEnum,
  React.ComponentType
> = {
  LANDING: GiftsAndBequestLanding,
  NEW_INDIVIDUAL: NewIndividual,
  EDIT_INDIVIDUAL: EditIndividual,
  EXISTING_INDIVIDUAL: ExistingIndividual,
  EXISTING_INDIVIDUAL_ADDRESS: ExistingIndividualAddress,
  ESTATE_ALLOCATION: BequestEstateAllocation,
  PART_OR_ABOVE_SHARE: PartOrAboveShare,
  MEANS_TESTED_BENEFITS: MeansTestedBenefits,
  DISABLED_BENEFICIARIES: DisabledBeneficiaries,
  NEW_CHARITY: NewCharity,
  EDIT_CHARITY: EditCharity,
  CHARITY_ESTATE: BequestCharityEstate,
  SPONSORED_CHARITY: SponsoredCharity,
  SPONSORED_CHARITY_DONATION: SponsoredCharityDonation,
  SPONSORED_CHARITY_THANKS: SponsoredCharityThanks,
};

export type PageMetaType = {
  heading?: string;
  subheading?: string;
  backPage?: string;
};
