import { createReducer } from "@reduxjs/toolkit";
import { fetchUserInsights } from "./insights.actions";
import { InsightState } from "./insights.types";

export const initialState: InsightState = {
  loading: false,
  items: [],
};

export const insightsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserInsights.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    })
    .addCase(fetchUserInsights.fulfilled, (state, { payload }) => {
      return {
        loading: false,
        items: payload.getUserInsightItems,
      };
    })
    .addCase(fetchUserInsights.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
});
