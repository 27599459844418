/**
 * Used to convert string separated by comma into an array
 * @param text - comma separated text
 */
const stringToArray = (text: string): string[] =>
  text
    .split(",")
    .filter((item) => item.trim().length > 0)
    .map((itm) => itm.trim());

export default stringToArray;
