import { AxiosPromise, AxiosStatic } from "axios";

const refreshAccessToken =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (): Promise<AxiosPromise> => {
    return await appAxios.get(
      `${serverURL}/auth${versionURLFragment}/refresh-access-token`,
      {
        withCredentials: true,
      }
    );
  };

export default refreshAccessToken;
