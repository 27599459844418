import styled from "styled-components";
import { ColorType, theme } from "../../styles/theme";

interface StyledTagProps {
  backgroundColor: ColorType;
  color: ColorType;
}

export const StyledTag = styled.div<StyledTagProps>`
  width: fit-content;
  max-height: 32px;
  border-radius: 24px;
  padding: 4px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  color: ${({ color }) => theme.colors[color]};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 1px;
`;

interface StyledIconContainerProps {
  addMargin: boolean;
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  margin-right: ${({ addMargin }) => (addMargin ? "8px" : "0")};
`;
