import { UserInfoObject, WillsState } from "../constants/types";

const updateUserInfoReducer = (state: WillsState, payload: UserInfoObject) => {
  const { firstName, lastName, gender, mobileNumber, address } = payload;

  if (!state.will?.userInfo) {
    state.will.userInfo = {};
  }

  if (address) {
    state.will.userInfo.address = payload.address;
    state.will.userInfo.mobileNumber = mobileNumber;
  } else {
    state.will.userInfo = {
      ...state.will.userInfo,
      firstName,
      lastName,
      gender,
      address: {
        ...state.will.userInfo?.address,
      },
    };
  }
};

export default updateUserInfoReducer;
