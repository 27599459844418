import React from "react";
import BoxRadioButton from "../../BoxRadioButton/BoxRadioButton.component";
import FormNavigationButton, {
  NavigationDirections,
} from "../../../../legacyComponents/FormNavigationButton/FormNavigationButton.component";
import {
  BackLinkWrapper,
  ContentWrapper,
  Description,
  PageWrapper,
  Title,
} from "./YesNoQuestions.styled";

type Props = {
  title: string;
  description?: string;
  onChange: (value: boolean) => void;
  onBack?: () => void;
};

const YesNoQuestions = ({
  title,
  description,
  onChange,
  onBack,
}: Props): JSX.Element => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <BoxRadioButton onChange={(value) => onChange(value)} />
        <BackLinkWrapper>
          <FormNavigationButton
            onClick={onBack}
            text="Back"
            direction={NavigationDirections.BACKWARD}
          />
        </BackLinkWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default YesNoQuestions;
