import styled, { keyframes } from "styled-components";
import React from "react";
import { theme } from "../../styles/legacy-theme";
import Button from "../Button/Button.component";
import Cross from "../../assets/icons/cross.svg";

type Props = {
  buttonText?: string;
  heading?: string;
  description?: string;
  onClickHelp?: () => void;
  onConfirm?: () => void;
  onClose: () => void;
  open: boolean;
  closeOnOutsideClick?: boolean;
  showConfirmButton?: boolean;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  animation: ${fadeIn} 0.2s;
`;

const StyledModal = styled.div`
  background: ${theme.colors.Primary02};
  max-height: 80%;
  overflow: scroll;
  position: relative;
  width: 360px;
  padding: 20px;
  @media (max-width: 480px) {
    width: 80%;
  }
`;

const ModalHeader = styled.div`
  font-size: 1.625rem;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  color: ${theme.colors.BrandNavy};
`;

const ModalDescription = styled.div`
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  margin: 0.5rem 0;
  color: ${theme.colors.Font02};
`;

const HelpText = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0.5rem 0;
  font-family: "Inter", sans-serif;
  color: ${theme.colors.Primary01};
`;

const StyledCross = styled(Cross)`
  position: absolute;
  cursor: pointer;
  top: 0.75rem;
  right: 0.75rem;
`;

const ModalContent = styled.div<{ hasHeading?: boolean }>`
  margin: ${({ hasHeading: modalHasHeader }) =>
    modalHasHeader ? "1.5rem 0" : ""};
`;

const Modal: React.FC<Props> = ({
  children,
  buttonText,
  heading,
  description,
  open,
  closeOnOutsideClick,
  onConfirm,
  onClickHelp,
  onClose,
  showConfirmButton = true,
}) => {
  if (!open) return null;
  return (
    <Overlay onClick={() => closeOnOutsideClick && onClose()}>
      <StyledModal
        data-testid="modal-component"
        onClick={(e) => e.stopPropagation()}
      >
        <StyledCross data-testid="close-icon" onClick={onClose} />
        {heading && <ModalHeader>{heading}</ModalHeader>}
        {description && <ModalDescription>{description}</ModalDescription>}
        {onClickHelp && (
          <HelpText data-testid="help-text" onClick={onClickHelp}>
            Important Information
          </HelpText>
        )}
        <ModalContent hasHeading={!!heading}>{children}</ModalContent>
        {showConfirmButton && onConfirm && buttonText && (
          <Button
            testId="modal-confirm-button"
            text={buttonText}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        )}
      </StyledModal>
    </Overlay>
  );
};

export default Modal;
