import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { bqIndividualSelector } from "../../../../../../components/Wills/selectors";
import { PageTexts } from "../../styles";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import { theme } from "../../../../../styles/theme";
import Checkbox from "../../../../../../assets/icons/check.svg";
import { showBQPage } from "../../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import { updateIndividualBequestAPI } from "../../../../../../components/Wills/actions/bequest";
import { prevBQPage } from "../../../../../store/ui/selectors";

const LongButton = styled.button<{ isSelected: boolean }>`
  border: solid 1.7px grey;
  border-radius: 7.999px;
  padding: 10px 0;
  color: ${({ isSelected }) => (isSelected ? "white" : theme.colors.Font02)};
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.Primary01 : theme.colors.BackgroundWhite};
`;

const LongRadioButton: React.FC<{
  text: string;
  isSelected: boolean;
  onClick: () => void;
}> = ({ text, isSelected = false, onClick }) => {
  return (
    <LongButton isSelected={isSelected} onClick={onClick}>
      {text}
    </LongButton>
  );
};

const Container = styled.div`
  display: grid;
  gap: 3em;
`;

const QuestionsContainer = styled.div`
  display: grid;
  gap: 1em;
`;

const UnderstandingContainer = styled.div<{ active: boolean }>`
  display: flex;
  gap: 0.7em;
  & > p {
    color: ${({ active }) =>
      active ? theme.colors.Primary01 : theme.colors.Grey02};
  }
  & > div > svg {
    stroke: ${({ active }) =>
      active ? theme.colors.Primary01 : theme.colors.Grey02};
  }
`;

const PartOrAboveShare: React.FC = () => {
  const dispatch = useDispatch();
  const bqIndividual = useSelector(bqIndividualSelector);
  const prevPage = useSelector(prevBQPage);
  const [decision, setDecision] = useState<"part" | "above" | "">("");
  const [understand, setUnderstand] = useState<boolean>(false);

  const { LANDING } = GiftsAndBequestPageEnum;

  const { firstName, lastName } = bqIndividual;

  return (
    <Container>
      <PageTexts
        heading={`${firstName} ${lastName} is already inheriting a share of your estate.
        Would you like this item to form a part of their share, or above the share they are already receiving?`}
        subheading="If you decide to bequest a part of your estate to somebody who is already an Islamic inheritor, they will need the consent of all other Islamic inheritors."
        help
      />
      <QuestionsContainer>
        <LongRadioButton
          text="Include as part of their share"
          isSelected={decision === "part"}
          onClick={() => {
            setDecision("part");
            setUnderstand(false);
            dispatch(
              updateIndividualBequestAPI({
                data: { ...bqIndividual, partOfInheritance: true },
              })
            );
          }}
        />
        <LongRadioButton
          text="Include above their share"
          isSelected={decision === "above"}
          onClick={() => {
            setDecision("above");
          }}
        />
        {decision === "above" && (
          <div>
            <p style={{ color: theme.colors.Grey02 }}>
              As {firstName} {lastName} is already an Islamic inheritor, they
              can only receive this item if all other islamic inheritors consent
            </p>
            <br />
            <UnderstandingContainer active={understand}>
              <div
                onClick={() => {
                  setUnderstand(!understand);
                  dispatch(
                    updateIndividualBequestAPI({
                      data: { ...bqIndividual, partOfInheritance: false },
                    })
                  );
                }}
              >
                <Checkbox />
              </div>
              <p>
                I understand, this will go into my will as a non-binding
                guidance to my executors.
              </p>
            </UnderstandingContainer>
          </div>
        )}
      </QuestionsContainer>
      <FormButton
        text="Save & Continue"
        leftArrowLabel="Back"
        active={decision === "part" || (decision === "above" && understand)}
        onClick={() => {
          dispatch(showBQPage(LANDING));
        }}
        leftArrowOnClick={() => {
          dispatch(showBQPage(prevPage));
        }}
      />
    </Container>
  );
};

export default PartOrAboveShare;
