import {
  LoginInput,
  SignupInput,
  UpdateTwoFactorAuthSettingsInput,
  ChangePasswordInput,
  TwoFactorAuthDetailsInput,
  ResetPasswordInput,
  VerifyEmailInput,
  TwoFactorAuthDetailsOutput,
  UserInfoOutput,
  AuthUser,
  TwoFactorAuthMethod,
  GoogleLoginInput,
  GoogleLoginOutput,
  AppleCallbackInput,
  AppleCallbackOutput,
  AppleLinkEmailInput,
  AppleLinkEmailOutput,
  AppleSignupInput,
  AppleSignupOutput,
} from "./types";
import requests from "./requests";
import axios, { AxiosPromise, AxiosStatic } from "axios";
import { Investor } from "@ifgengineering/client-invest-sdk";

const {
  changePassword,
  generateOtp,
  getTwoFactorAuthDetails,
  login,
  googleLogin,
  appleCallback,
  appleSignup,
  appleLinkEmail,
  logout,
  resendVerifyEmail,
  resetPassword,
  forgotPassword,
  signup,
  updateTwoFactorAuthSettings,
  verifyEmail,
  passwordlessStart,
  passwordlessVerify,
  refreshAccessToken,
  userInfo,
  checkOtp,
  verifyOTP,
} = requests;

type APIParams = {
  serverURL: string;
  version?: string;
  appAxios?: AxiosStatic;
};

type InitializeApiSignature = {
  logout: () => Promise<void>;
  login: (payload: LoginInput) => AxiosPromise<Investor>;
  googleLogin: (payload: GoogleLoginInput) => AxiosPromise<GoogleLoginOutput>;
  appleCallback: (
    payload: AppleCallbackInput
  ) => AxiosPromise<AppleCallbackOutput>;
  appleSignup: (payload: AppleSignupInput) => AxiosPromise<AppleSignupOutput>;
  appleLinkEmail: (
    payload: AppleLinkEmailInput
  ) => AxiosPromise<AppleLinkEmailOutput>;
  signup: (payload: SignupInput) => AxiosPromise<void>;
  updateTwoFactorAuthSettings: (
    payload: UpdateTwoFactorAuthSettingsInput
  ) => Promise<void>;
  changePassword: (payload: ChangePasswordInput) => Promise<void>;
  generateOtp: (
    email: string,
    mfaMethod?: TwoFactorAuthMethod
  ) => Promise<void>;
  getTwoFactorAuthDetails: (
    payload: TwoFactorAuthDetailsInput
  ) => Promise<TwoFactorAuthDetailsOutput>;
  resendVerifyEmail: () => Promise<void>;
  resetPassword: (payload: ResetPasswordInput) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  verifyEmail: (payload: VerifyEmailInput) => Promise<void>;
  checkOtp: (otp: string) => Promise<void>;
  passwordlessStart: (
    email: string,
    isSignup?: boolean | undefined
  ) => Promise<void>;
  passwordlessVerify: (
    email: string,
    otp: string,
    isSignup?: boolean | undefined
  ) => Promise<void>;
  refreshAccessToken: () => Promise<AxiosPromise>;
  userInfo: () => Promise<UserInfoOutput>;
  verifyOTP: (otp: string) => Promise<void>;
};

const InitializeApi: (payload: APIParams) => InitializeApiSignature = ({
  serverURL,
  version,
  appAxios = axios,
}) => {
  const versionURLFragment = version !== undefined ? `/${version}` : "";

  return {
    login: login(serverURL, versionURLFragment, appAxios),
    googleLogin: googleLogin(serverURL, versionURLFragment, appAxios),
    appleCallback: appleCallback(serverURL, versionURLFragment, appAxios),
    appleSignup: appleSignup(serverURL, versionURLFragment, appAxios),
    appleLinkEmail: appleLinkEmail(serverURL, versionURLFragment, appAxios),
    logout: logout(serverURL, versionURLFragment, appAxios),
    signup: signup(serverURL, versionURLFragment, appAxios),
    updateTwoFactorAuthSettings: updateTwoFactorAuthSettings(
      serverURL,
      appAxios
    ),
    changePassword: changePassword(serverURL, versionURLFragment, appAxios),
    generateOtp: generateOtp(serverURL, versionURLFragment, appAxios),
    passwordlessStart: passwordlessStart(
      serverURL,
      versionURLFragment,
      appAxios
    ),
    passwordlessVerify: passwordlessVerify(
      serverURL,
      versionURLFragment,
      appAxios
    ),
    getTwoFactorAuthDetails: getTwoFactorAuthDetails(
      serverURL,
      versionURLFragment,
      appAxios
    ),
    resendVerifyEmail: resendVerifyEmail(serverURL, appAxios),
    resetPassword: resetPassword(serverURL, appAxios),
    forgotPassword: forgotPassword(serverURL, appAxios),
    verifyEmail: verifyEmail(serverURL, appAxios),
    refreshAccessToken: refreshAccessToken(
      serverURL,
      versionURLFragment,
      appAxios
    ),
    userInfo: userInfo(serverURL, versionURLFragment, appAxios),
    checkOtp: checkOtp(serverURL, versionURLFragment, appAxios),
    verifyOTP: verifyOTP(serverURL, versionURLFragment, appAxios),
  };
};

export { InitializeApi };
export type {
  LoginInput,
  GoogleLoginInput,
  SignupInput,
  Investor,
  AuthUser,
  UserInfoOutput,
  VerifyEmailInput,
  TwoFactorAuthMethod,
  ResetPasswordInput,
  AppleLinkEmailInput,
  AppleLinkEmailOutput,
};
