import { UpdateTwoFactorAuthSettingsInput } from "../types";
import { AxiosStatic } from "axios";

const updateTwoFactorAuthSettings =
  (serverURL: string, appAxios: AxiosStatic) =>
  async ({
    isTwoFactorAuthEnabled,
    primaryTwoFactorAuthMethod,
  }: UpdateTwoFactorAuthSettingsInput): Promise<void> => {
    await appAxios.put(
      `${serverURL}/auth/2fa/update`,
      {
        isTwoFactorAuthEnabled,
        primaryTwoFactorAuthMethod,
      },
      {
        withCredentials: true,
      }
    );
  };

export default updateTwoFactorAuthSettings;
