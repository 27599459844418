import { ColorType, theme } from "../../styles/theme";
import styled from "styled-components";
import { TabTheme } from "./TabContext.component";

interface StyledTabProps {
  backgroundColor: ColorType;
  color: ColorType;
  selected: boolean;
}

export const StyledTab = styled.div<StyledTabProps>`
  user-select: none;
  white-space: nowrap;
  display: flex;
  position: relative;
  cursor: ${({ selected }) => (selected ? "" : "pointer")};
  border-bottom: 4px solid
    ${({ color, selected }) => (selected ? theme.colors[color] : "transparent")};
  z-index: 1;
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  color: ${({ color }) => theme.colors[color]};
`;

interface StyledTabTextWrapperProps {
  mobile: boolean;
}

export const StyledTabTextWrapper = styled.div<StyledTabTextWrapperProps>`
  padding: ${({ mobile }) => (mobile ? "8px" : "18px")} 0 8px 0;
`;

export const StyledTabsContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

interface StyledTabsContainerProps {
  tabTheme: TabTheme;
  mobile: boolean;
}

export const StyledTabsScroll = styled.div<StyledTabsContainerProps>`
  display: flex;
  width: 100%;
  overflow-x: auto;
  position: relative;
  gap: ${({ mobile }) => (mobile ? "40px" : "56px")};
  border-top: ${({ mobile, tabTheme }) =>
    mobile && `1px solid ${theme.colors[tabTheme.backgroundColor]}`};
  background-color: ${({ tabTheme }) => theme.colors[tabTheme.backgroundColor]};
  & > div:nth-child(1) {
    margin-left: ${({ mobile }) => (mobile ? "16px" : "56px")};
  }
  /* There's a bug on mobile Safari that won't consider the last tab's right margin when calculating the scroll size.  */
  /* This style will create an invisible element forcing the browser to leave some space after it. */
  & > div:nth-last-child(1)::after {
    content: "";
    width: 1px;
    height: 1px;
    position: absolute;
    right: -50px;
  }
`;
export const StyledTabsBottomBorder = styled.hr<{ borderColor?: ColorType }>`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  border: 2px solid
    ${({ borderColor }) => theme.colors[borderColor ?? "SLATE100"]};
`;

interface StyledMobileTabsContainerOverlayProps {
  color: ColorType;
}

export const StyledMobileTabsContainerOverlay = styled.div<StyledMobileTabsContainerOverlayProps>`
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    ${({ color }) => theme.colors[color]} 100%
  );
  position: absolute;
  right: 0;
  height: 100%;
  width: 20px;
  z-index: 1;
`;
