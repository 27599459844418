import {
  BusinessItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";
import { BusinessObject } from "../constants/types";

export type BusinessPayload = {
  payload: BusinessItemInterface;
};

const sumAll = (list: BusinessItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.totalValue, 0);
};

export const deleteBusinessReducer = (
  state: PortfolioDataInterface,
  { payload }: BusinessPayload
): void => {
  if (!state.businessAssets) {
    state.businessAssets = {
      totalValue: 0,
      businessList: [],
    };
  }

  state.businessAssets.businessList = state.businessAssets.businessList?.filter(
    (business) => business.id !== payload.id
  );

  state.businessAssets.totalValue = sumAll(state.businessAssets.businessList);
};

export const updateBusinessReducer = (
  state: PortfolioDataInterface,
  { payload }: BusinessPayload
): void => {
  if (!state.businessAssets) {
    state.businessAssets = {
      totalValue: 0,
      businessList: [],
    };
  }

  const businessExist = state.businessAssets.businessList?.some(
    (business) => business.id === payload.id
  );

  if (businessExist) {
    state.businessAssets.businessList = state.businessAssets.businessList?.map(
      (business) => {
        if (business.id === payload.id) {
          return payload;
        }

        return business;
      }
    );
  } else {
    state.businessAssets.businessList?.push(payload);
  }

  state.businessAssets.totalValue = sumAll(state.businessAssets.businessList);
};

export type BulkUpdatePayload = {
  payload: BusinessObject;
};

export const bulkUpdateBusiness = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.businessAssets = payload;
};
