import React from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { bqIndividualSelector } from "../../../../../../components/Wills/selectors";
import AddressFields from "../../../../../components/AddressFields";
import { existingIndividualMeta } from "./existing-individual";
import withResponsiveModal from "../../utils/withResponsiveModal";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import { bqUpdateIndividual } from "../../../../../../components/Wills/actions";
import { showBQPage, storePrevBQPage } from "../../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import Input from "../../../../../../legacyComponents/Form/input/Input/Input.component";
import styled from "styled-components";
import { existingInheritor } from "../../formValidations";
import { theme } from "../../../../../../styles/legacy-theme";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

const NameCotainer = styled.div`
  display: grid;
  gap: 1.8em;
`;

const NameHeadingText = styled.h3`
  font-size: 1.2em;
  color: ${theme.colors.Font02};
`;
const NameText = styled.p`
  font-size: 1.2em;
  color: ${theme.colors.BrandBlue};
`;

const ExistingIndividualAddress: React.FC = () => {
  const bqInheritor = useSelector(bqIndividualSelector);
  const dispatch = useDispatch();

  const { EXISTING_INDIVIDUAL, ESTATE_ALLOCATION } = GiftsAndBequestPageEnum;

  return (
    <div>
      <NameCotainer>
        <div>
          <NameHeadingText>First name</NameHeadingText>
          <NameText>{bqInheritor?.firstName}</NameText>
        </div>
        <div>
          <NameHeadingText>Last name</NameHeadingText>
          <NameText>{bqInheritor?.lastName}</NameText>
        </div>
      </NameCotainer>
      <Formik
        initialValues={{
          emailAddress: bqInheritor.emailAddress,
          addressLine1: bqInheritor.address?.addressLine1,
          addressLine2: bqInheritor.address?.addressLine2,
          city: bqInheritor.address?.city,
          postcode: bqInheritor.address?.postcode,
        }}
        validationSchema={existingInheritor}
        onSubmit={(values) => {
          dispatch(
            bqUpdateIndividual({
              ...bqInheritor,
              emailAddress: values.emailAddress,
              address: {
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                postcode: values.postcode,
              },
            })
          );
          dispatch(storePrevBQPage());
          dispatch(showBQPage(ESTATE_ALLOCATION));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <AddressFields
              isOpen={Boolean(
                touched.addressLine1 || touched.city || touched.postcode
              )}
            />
            <Field name="emailAddress">
              {({ field }: FieldType) => (
                <Input
                  {...field}
                  heading="Email"
                  errorMessage={
                    touched.emailAddress && errors.emailAddress
                      ? errors.emailAddress
                      : ""
                  }
                />
              )}
            </Field>

            <FormButton
              text="Save & Continue"
              type="submit"
              leftArrowLabel="Back"
              leftArrowOnClick={() => dispatch(showBQPage(EXISTING_INDIVIDUAL))}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withResponsiveModal(ExistingIndividualAddress, {
  ...existingIndividualMeta,
  backPage: "EXISTING_INDIVIDUAL",
});
