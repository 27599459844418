import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Icon from "@icon-park/react/es/all";
import { MEDIA_QUERIES } from "../../styles";
import Text from "../Text";
import { INavigationBar, INavigationItem } from "./types";
import {
  ChildrenContainer,
  HamburgerButtonContainer,
  LogoContainer,
  NavigationBarContainer,
  PageNameContainer,
} from "./styled";
import MobileNav from "./components/MobileNav.component";
import DesktopNav from "./components/DesktopNav.component";

const EMPTY_SELECTED_ITEM = { id: "", title: "" };

export const NavigationBar = ({
  bgColor = "BLACK",
  primaryLogo,
  secondaryLogo,
  children,
  navItems,
  selectedItemId,
  navItemsTextColor = "SLATE300",
  selectedNavItemsBgColor = "BLUE600",
  selectedNavItemsTextColor = "WHITE",
  onSelectNavItem,
  pageName,
}: INavigationBar): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title?: string;
  }>(EMPTY_SELECTED_ITEM);

  useEffect(() => {
    if (selectedItemId) {
      const preSelectedNavItemTitle = navItems?.find(
        (item) => item.id === selectedItemId
      )?.title;
      setSelectedItem({
        id: selectedItemId,
        title: preSelectedNavItemTitle,
      });
    } else if (selectedItemId === undefined) {
      setSelectedItem(EMPTY_SELECTED_ITEM);
    }
  }, [selectedItemId]);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const isTablet = useMediaQuery({ query: MEDIA_QUERIES.TAB_LAND });

  const handleNavItemClick = (item: INavigationItem) => {
    setSelectedItem({ id: item.id, title: item.title });
    setIsDrawerOpen(false);
    onSelectNavItem && onSelectNavItem(item);
  };

  const renderDesktopPageName = () =>
    !isMobile &&
    !isTablet &&
    pageName && (
      <PageNameContainer data-testid="page-name-container">
        <Text type="T18" color={navItemsTextColor}>
          {pageName}
        </Text>
      </PageNameContainer>
    );

  const renderDesktopLogo = () =>
    !isMobile &&
    !isTablet &&
    primaryLogo && (
      <LogoContainer>
        <img data-testid="logo-image" src={primaryLogo} alt="logo" />
      </LogoContainer>
    );

  const renderHamburgerMenu = () =>
    (isMobile || isTablet) && (
      <HamburgerButtonContainer data-testid="hamburger-menu">
        {navItems?.length && (
          <button
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            data-testid="hamburger-menu-button"
          >
            <Icon type="HamburgerButton" size={24} fill="white" />
          </button>
        )}
        {(selectedItem.title || pageName) && (
          <Text type="H20" color="BLUE200">
            {selectedItem.title || pageName}
          </Text>
        )}
      </HamburgerButtonContainer>
    );

  return (
    <NavigationBarContainer bgColor={bgColor}>
      {renderDesktopPageName()}
      {renderDesktopLogo()}
      {!isMobile && !isTablet && (
        <DesktopNav
          navItems={navItems}
          selectedItemId={selectedItem.id}
          navItemsTextColor={navItemsTextColor}
          selectedNavItemsBgColor={selectedNavItemsBgColor}
          selectedNavItemsTextColor={selectedNavItemsTextColor}
          handleNavItemClick={handleNavItemClick}
        />
      )}
      {renderHamburgerMenu()}
      <ChildrenContainer>{children}</ChildrenContainer>
      {isDrawerOpen && (isMobile || isTablet) && (
        <MobileNav
          bgColor={bgColor}
          primaryLogo={primaryLogo}
          secondaryLogo={secondaryLogo}
          navItems={navItems}
          selectedItemId={selectedItem.id}
          navItemsTextColor={navItemsTextColor}
          selectedNavItemsBgColor={selectedNavItemsBgColor}
          onSelectNavItem={handleNavItemClick}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </NavigationBarContainer>
  );
};

export default NavigationBar;
