import { BequestCharityInterface } from "@ifgengineering/hip-app-domain";
import { WillsState } from "../../constants/types";

const addCharityToListReducer = (
  state: WillsState,
  action: {
    payload: BequestCharityInterface;
  }
) => {
  state.will.bequests.bequestCharity?.push({
    ...state.bq_charity,
    ...action.payload,
  });
};

export default addCharityToListReducer;
