import { Selector } from "@willsStore/utils/types";
import { PartnerCharityInterfaceExtra } from "@Wills/store/charity/reducers";

export const getCharity: Selector<PartnerCharityInterfaceExtra> = (state) =>
  state.charity;

export const getCharityProductList: Selector<
  PartnerCharityInterfaceExtra["productList"]
> = (state) => state.charity.productList;

export const getDonation: Selector<PartnerCharityInterfaceExtra["donation"]> = (
  state
) => state.charity.donation;
