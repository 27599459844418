import { ButtonsContainer, Spacer } from "../../../signup/styled";
import { Field, Formik } from "formik";
import { AboutYouSchema } from "../constants/validation";
import Input from "../../../../../legacyComponents/Form/input/Input/Input.component";
import RadioButton from "../../../../components/RadioButton/RadioButton.component";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { AboutYouChild, AboutYouPages } from "../index";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { onUpdateUserInfo } from "../../../../../components/Wills/actions";
import { submitUserInfo } from "../../../../../components/Wills/actions/submitUserInfo";
import DateInput from "../../../../../legacyComponents/Form/input/DateInput/DateInput.component";
import { FormikForm } from "../../../../../legacyComponents/Form/input/styled/Form";
import { ErrorContainer } from "@ifgengineering/component-library";
import Text from "../../../../../legacyComponents/Form/input/styled/Text";

export const Separator = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.Grey01};
  margin: 2rem 0;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: {
      firstName: string;
      lastName: string;
      gender: string;
    };
    touched: {
      firstName: boolean;
      lastName: boolean;
      gender: boolean;
    };
  };
};

const General: React.FC<AboutYouChild> = ({ setPage, initialState }) => {
  const dispatch = useDispatch();
  const { firstName, lastName, gender, dob } = initialState || {};
  const date = dob ? dob : "";
  const [year, month, day] = date.split("-");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitHandler = async (values: {
    firstName: string;
    lastName: string;
    gender: string;
    dob: string | undefined;
    day: string;
    month: string;
    year: string;
  }) => {
    const { day, month, year, ...rest } = values;

    rest.dob = `${parseInt(year)}-${parseInt(month)}-${parseInt(day)}`;

    const response = await dispatch(submitUserInfo(rest));
    const errors = response?.payload?.networkError?.result?.errors;
    await dispatch(onUpdateUserInfo(rest));

    if (errors?.length) {
      const allErrors = errors.map((e) => e.message);

      const isDateInvalid = allErrors.find((e) => e.match(/date\/time/gi));
      const errorMessage = isDateInvalid
        ? "Date of birth is invalid"
        : allErrors.join(", ");
      setErrorMessage(errorMessage);
      return;
    }

    setPage(AboutYouPages.contact);
  };

  return (
    <>
      <Text align="left">Firstly, a little about you.</Text>
      <Formik
        enableReinitialize
        validationSchema={AboutYouSchema}
        initialValues={{
          firstName: firstName || "",
          lastName: lastName || "",
          gender: gender || "Male",
          dob: dob,
          day: day,
          month: month,
          year: year,
        }}
        onSubmit={onSubmitHandler}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <FormikForm>
            <Field name="firstName">
              {({ field, form: { errors, touched } }: FieldType) => (
                <Spacer>
                  <Input
                    heading="First name"
                    errorMessage={
                      touched.firstName ? errors.firstName : undefined
                    }
                    placeholder="First name"
                    {...field}
                  />
                </Spacer>
              )}
            </Field>
            <Field name="lastName">
              {({ field, form: { errors, touched } }: FieldType) => (
                <Spacer>
                  <Input
                    heading="Last name"
                    errorMessage={
                      touched.lastName ? errors.lastName : undefined
                    }
                    placeholder="Last name"
                    {...field}
                  />
                </Spacer>
              )}
            </Field>
            <Separator />
            <Field name="gender">
              {() => (
                <Spacer>
                  <RadioButton
                    onChange={(value) => setFieldValue("gender", value)}
                    heading="Gender"
                    value={values.gender}
                    buttons={genderOptions}
                  />
                </Spacer>
              )}
            </Field>
            <Field name="dob">
              {() => {
                const isThereAnyError =
                  (errors.day && touched.day) ||
                  (errors.month && touched.month) ||
                  (errors.year && touched.year);

                const dateInputErrorMessage = isThereAnyError
                  ? "Please enter a valid date"
                  : "";

                return (
                  <Spacer>
                    <DateInput
                      setDay={(value) => setFieldValue("day", value)}
                      setMonth={(value) => setFieldValue("month", value)}
                      setYear={(value) => setFieldValue("year", value)}
                      day={values.day}
                      month={values.month}
                      year={values.year}
                      heading="Date of birth"
                      errorMessage={dateInputErrorMessage}
                    />
                  </Spacer>
                );
              }}
            </Field>
            <ButtonsContainer>
              {errorMessage && (
                <ErrorContainer>
                  <Text hasError={!!errorMessage}>{errorMessage}</Text>
                </ErrorContainer>
              )}

              <FormButton
                type="submit"
                text="Save & Continue"
                leftArrowOnClick={() => navigate(-1)}
                leftArrowLabel="Back"
              />
            </ButtonsContainer>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default General;

const genderOptions = [
  {
    key: "Male",
    text: "Male",
    value: "Male",
  },
  {
    key: "Female",
    text: "Female",
    value: "Female",
  },
];
