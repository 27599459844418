import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_UPDATE_CHARITY_ALLOCATION,
  CREATE_UPDATE_CHARITY_ALLOCATION_API,
  FETCH_CHARITY_ALLOCATION_API,
} from "../constants/actionTypes";
import { PartialZakatAllocationInput, ZakatAllocationObject } from "../types";

export const fetchCharityAllocationAPI = createAsyncThunk<
  ZakatAllocationObject[],
  string
>(FETCH_CHARITY_ALLOCATION_API, async (email) => {
  try {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query ($email: String!) {
          findAllAllocationsByEmail(userEmail: $email) {
            id
            percentage
            amount
            zakatCharity {
              id
            }
          }
        }
      `,
      variables: {
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.findAllAllocationsByEmail;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "fetchCharityAllocationAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const createUpdateCharityAllocation = createAction<
  PartialZakatAllocationInput,
  string
>(CREATE_UPDATE_CHARITY_ALLOCATION);

export const createUpdateCharityAllocationAPI = createAsyncThunk<
  ZakatAllocationObject,
  {
    updateZakatAllocationInput: PartialZakatAllocationInput[];
    userEmail: string;
    zakatId: number;
  }
>(
  CREATE_UPDATE_CHARITY_ALLOCATION_API,
  async ({ updateZakatAllocationInput, userEmail, zakatId }) => {
    try {
      const response = await client.mutate({
        fetchPolicy: "no-cache",
        mutation: gql`
          mutation (
            $updateZakatAllocationInput: [PartialZakatAllocationInput!]!
            $zakatId: Float!
            $userEmail: String!
          ) {
            updateZakatAllocation(
              updateZakatAllocationInput: $updateZakatAllocationInput
              zakatId: $zakatId
              userEmail: $userEmail
            ) {
              allocations {
                amount
                zakatCharityId
                percentage
                zakatId
              }
              totalAmountAllocated
              totalPercentageAllocated
            }
          }
        `,
        variables: {
          updateZakatAllocationInput,
          zakatId,
          userEmail,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.updateZakatAllocation;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "createUpdateCharityAllocationAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
