import {
  PortfolioDataInterface,
  PropertyInterface,
  PropertyItemInterface,
} from "@ifgengineering/hip-app-domain";

export type PropertyPayload = {
  payload: PropertyItemInterface;
};

const sumAll = (list: PropertyItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deletePropertyReducer = (
  state: PortfolioDataInterface,
  { payload }: PropertyPayload
): void => {
  if (!state.properties) {
    state.properties = {
      totalValue: 0,
      propertyList: [],
    };
  }

  state.properties.propertyList = state.properties?.propertyList?.filter(
    (property) => property.id !== payload.id
  );

  state.properties.totalValue = sumAll(state.properties?.propertyList);
};

export const updatePropertyReducer = (
  state: PortfolioDataInterface,
  { payload }: PropertyPayload
): void => {
  if (!state.properties) {
    state.properties = {
      totalValue: 0,
      propertyList: [],
    };
  }

  const propertiesExist = state.properties?.propertyList?.some(
    (property) => property.id === payload.id
  );

  if (propertiesExist) {
    state.properties.propertyList = state.properties?.propertyList?.map(
      (property) => {
        if (property.id === payload.id) {
          return payload;
        }

        return property;
      }
    );
  } else {
    state.properties?.propertyList?.push(payload);
  }

  state.properties.totalValue = sumAll(state.properties?.propertyList);
};

export type BulkUpdatePayload = {
  payload: PropertyInterface;
};

export const bulkUpdateProperty = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.properties = payload;
};
