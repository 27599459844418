import { ExecutorInfo, WillsState } from "../constants/types";

const updateExecutorsReducer = (state: WillsState, payload: ExecutorInfo) => {
  const { executionerList } = state.will.overseer;
  const executorExists = executionerList.find((item) => item.id === payload.id);
  if (executorExists) {
    state.will.overseer.executionerList = executionerList.map((exec) =>
      exec.id === payload.id ? payload : exec
    );
  } else {
    state.will.overseer.executionerList = [...executionerList, payload];
  }
};
export default updateExecutorsReducer;
