import styled from "styled-components";
import GenericInput from "../../GenericInput";
import { theme } from "../../../styles";

export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  & input[type="text"] {
    opacity: 0;
    color: transparent;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 13px;
    outline: transparent;
  }
`;

export const StyledGenericInput = styled(GenericInput)`
  padding-left: 30px;
  font-weight: 500;
  font-size: 18px;
  color: ${theme.colors.SLATE700};
`;

export const CurrencySymbol = styled.span`
  position: absolute;
  font-weight: 500;
  font-size: 18px;
  left: 16px;
  color: ${theme.colors.SLATE700};
`;
