import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as actions from "../constants/actionTypes";
import { toast } from "react-toastify";
import { User } from "@ifgengineering/hip-app-domain";
import { navigate } from "gatsby";
import axios from "axios";
import { CORE_API } from "gatsby-env-variables";
import authSdk from "../authSDK";
import { logException } from "@helpers/logException";

export const clearStore = createAction(actions.CLEAR_STORE);

export const UPDATE_APP_RECORD = "UPDATE_APP_RECORD";

export const updateUserInfo = createAction<User, string>(
  actions.UPDATE_USER_INFO
);

export const updateAppRecord = createAction<{
  app: string;
}>(UPDATE_APP_RECORD);

export const authError = createAsyncThunk<
  void,
  {
    err: Error;
  }
>(actions.AUTH_ERROR, () => {
  toast.info("Please log in");
});

export const loginAction = createAsyncThunk<
  User,
  {
    email: string;
    password: string;
  }
>(actions.LOGIN, async ({ email, password }) => {
  try {
    const result = await authSdk.login({ email, password });

    return result.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response?.status === 422) {
        toast.info("Please set a new password below");
        throw e;
      }

      if (e.response?.status === 401) {
        toast.error("Incorrect Email or Password!");
        throw e;
      }
    }

    toast.error("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "login",
      exception: e,
    });

    throw e;
  }
});

export const fetchUserInfoAPI = createAsyncThunk(
  actions.FETCH_USER_INFO,
  async () => {
    try {
      const response = await authSdk.userInfo();

      return { data: response.user };
    } catch (e: any) {
      if (e.response.status !== 401) {
        toast.warn("Something went wrong. Try again or contact support");

        logException({
          tag: "userInfo",
          exception: e,
        });

        throw Error(e);
      }

      return { errors: e.networkError.result.errors };
    }
  }
);

export const signupAction = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    signupRedirect: string;
    captchaToken: string;
  }
>(
  actions.SIGN_UP,
  async ({ email, password, signupRedirect, captchaToken }) => {
    const origin = window.location.origin;

    try {
      await authSdk.signup({ email, password, origin, captchaToken });

      toast.success(
        "Your account has been successfully created! Please sign in with your new login details"
      );

      navigate(signupRedirect);

      return;
    } catch (e: any) {
      if (e.response.status !== 409) {
        toast.error("There was a problem creating your user, please try again");

        logException({
          email,
          tag: "signup",
          exception: e,
        });
      }

      return e;
    }
  }
);

export const logoutAction = createAsyncThunk<void>(actions.LOGOUT, async () => {
  try {
    await authSdk.logout();

    navigate("/auth/login/");
  } catch (e) {
    logException({
      tag: "logout",
      exception: e,
    });

    toast.error("There was an unexpected error logging out");
    throw e;
  }
});

export const isLoggedInAction = createAsyncThunk<User>(
  actions.IS_LOGGED_IN,
  async () => {
    try {
      const res = await axios.get(`${CORE_API}/auth/is-logged-in`);
      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Login failed, please try again");
      navigate("/auth/login");
      throw e;
    }
  }
);

export const sendResetPasswordLink = async (email: string) => {
  const origin = window.location.origin;

  return await axios.post(
    `${CORE_API}/auth/forgot-password`,
    {
      email,
      origin,
    },
    { withCredentials: true }
  );
};

export const resetPassword = async ({
  email,
  newPassword,
  token,
}: {
  email: string;
  newPassword: string;
  token: string;
}): Promise<void> => {
  return await authSdk.resetPassword({ email, newPassword, token });
};

export const verifyEmail = async ({
  email,
  token,
}: {
  email: string;
  token: string;
}): Promise<void> => {
  return authSdk.verifyEmail({ email, token });
};

export const changePassword = async ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}): Promise<void> => {
  await authSdk.changePassword({ password, newPassword });
};
