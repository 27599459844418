import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import CurrencyInput from "react-currency-input-field";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import { theme } from "../../../../styles/legacy-theme";
import Text from "../../../../legacyComponents/Form/input/styled/Text";

const Title = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

const Description = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  flex-direction: row;
  padding-right: 10px;
`;

const InputWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  margin: 15px 0 0 0;

  & input[type="text"] {
    opacity: 0;
    color: transparent;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    height: 3rem;
    border-radius: 12px;
    padding: 13px;
    outline: transparent;
  }
`;

const PerCentValue = styled.div`
  font-size: 1.18rem;
  font-weight: 600;
  color: ${theme.colors.Font01};
`;

const PerCentSymbol = styled.div`
  font-size: 1.18rem;
  color: ${theme.colors.Font02};
`;

const blink = keyframes`
 0% { color: transparent; }
 50% { color:${theme.colors.Font01}; }
`;

const BlinkyCaret = styled.span`
  font-weight: 100;
  font-size: 1.18rem;
  margin: 0 -2px 0 -2px;
  animation: 1s ${blink} step-end infinite;
`;

type FakeInputProp = {
  error: boolean;
};

const FakeInput = styled.div<FakeInputProp>`
  border-radius: 12px;
  border: 1px solid
    ${({ error }) => (error ? theme.colors.ErrorRed : theme.colors.Grey02)};
  box-sizing: border-box;
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  width: calc(100% - 30px);
`;

const ErrorContainer = styled.div`
  margin-top: 18px;
`;

type BequestAllocationPerCentProps = {
  title?: string;
  description?: string;
  percentageValue?: number;
  errorMessage?: string;
  onChange: (value: string) => void;
};

const BequestAllocationPerCent = ({
  title,
  description,
  onChange,
  percentageValue,
  errorMessage,
}: BequestAllocationPerCentProps): JSX.Element => {
  const defaultValue = "0.00";
  const [value, setValue] = useState<string>(defaultValue);
  const [showBlink, setShowBlink] = useState<boolean>(false);

  const onChangeHandler = (imputedValue: string) => {
    const previousValue = value || "0";
    const valueToConsider =
      parseFloat(imputedValue) > 100 ? previousValue : imputedValue;

    onChange(valueToConsider);
    setValue(valueToConsider);
  };

  useEffect(() => {
    setValue(percentageValue?.toString() || defaultValue);
  }, [percentageValue]);

  return (
    <CardContainer>
      <ContentWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextWrapper>
        <InputWrapper>
          <FakeInput error={Boolean(errorMessage)}>
            <PerCentValue>{value}</PerCentValue>
            {showBlink && <BlinkyCaret>|</BlinkyCaret>}
            <PerCentSymbol>%</PerCentSymbol>
          </FakeInput>
          <CurrencyInput
            defaultValue={0}
            decimalsLimit={2}
            value={value}
            onClick={() => {
              setShowBlink(true);
              setValue("");
            }}
            onFocus={() => {
              setShowBlink(true);
              setValue("");
            }}
            onBlur={() => {
              setShowBlink(false);
              setValue(value || defaultValue);
              onChangeHandler(value || "");
            }}
            onValueChange={(value) => onChangeHandler(value || "")}
          />
        </InputWrapper>
        <ErrorContainer>
          {errorMessage && (
            <Text hasError={!!errorMessage}>{errorMessage}</Text>
          )}
        </ErrorContainer>
      </ContentWrapper>
    </CardContainer>
  );
};

export default BequestAllocationPerCent;
