import {
  DebtInterface,
  DebtItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";

export type DebtPayload = {
  payload: DebtItemInterface;
};

const sumAll = (list: DebtItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deleteDebtReducer = (
  state: PortfolioDataInterface,
  { payload }: DebtPayload
): void => {
  if (!state.debts) {
    state.debts = {
      totalValue: 0,
      debt: [],
    };
  }

  state.debts.debt = state.debts.debt.filter((debt) => debt.id !== payload.id);

  state.debts.totalValue = sumAll(state.debts?.debt);
};

export const updateDebtReducer = (
  state: PortfolioDataInterface,
  { payload }: DebtPayload
): void => {
  if (!state.debts) {
    state.debts = {
      totalValue: 0,
      debt: [],
    };
  }

  const debtExist = state.debts.debt.some((debt) => debt.id === payload.id);

  if (debtExist) {
    state.debts.debt = state.debts.debt.map((debt) =>
      debt.id === payload.id ? payload : debt
    );
  } else {
    state.debts.debt.push(payload);
  }

  state.debts.totalValue = sumAll(state.debts.debt);
};

export type BulkUpdatePayload = {
  payload: DebtInterface;
};

export const bulkUpdateDebt = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.debts = payload;
};
