import { navigate } from "gatsby";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { HeadingText, SubHeadingText } from "../../styles";
import withResponsiveModal from "../../utils/withResponsiveModal";
import { showBQPage } from "@willsStore/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import styled from "styled-components";
import { getCharity } from "@components/Charity/selectors";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  display: flex;
  align-items: center;

  & > img {
    height: auto;
    width: auto;
    object-fit: cover;
  }
`;

const SponsoredCharityThanks: React.FC = () => {
  const dispatch = useDispatch();
  const sponsoredCharity = useSelector(getCharity);

  return (
    <div>
      <LogoContainer>
        <img
          src={sponsoredCharity.logoKey}
          alt={sponsoredCharity.displayName + " logo"}
        />
      </LogoContainer>
      <br />
      <HeadingText fontSize="1rem">
        Thank you so much for supporting {sponsoredCharity.displayName}!
      </HeadingText>
      <br />
      <SubHeadingText>
        Your donation makes the difference to help continue running our
        operations.
      </SubHeadingText>
      <br />
      <br />
      <FormButton
        text="Next Section"
        onClick={() => {
          navigate("/app/wills");
          dispatch(showBQPage(GiftsAndBequestPageEnum.LANDING));
        }}
      />
    </div>
  );
};

export default withResponsiveModal(SponsoredCharityThanks, {});
