import {
  DetailedAssetInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";
import { AlternativeInvestmentObject } from "../constants/types";

export type AlternativeInvestmentsPayload = {
  payload: DetailedAssetInterface;
};

const sumAll = (list: DetailedAssetInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deleteAlternativeInvestmentsReducer = (
  state: PortfolioDataInterface,
  { payload }: AlternativeInvestmentsPayload
): void => {
  if (!state.alternativeInvestments) {
    state.alternativeInvestments = {
      totalValue: 0,
      alternativeAssetList: [],
    };
  }

  state.alternativeInvestments.alternativeAssetList =
    state.alternativeInvestments.alternativeAssetList?.filter(
      (investment) => investment.id !== payload.id
    );

  state.alternativeInvestments.totalValue = sumAll(
    state.alternativeInvestments.alternativeAssetList
  );
};

export const updateAlternativeInvestmentsReducer = (
  state: PortfolioDataInterface,
  { payload }: AlternativeInvestmentsPayload
): void => {
  if (!state.alternativeInvestments) {
    state.alternativeInvestments = {
      totalValue: 0,
      alternativeAssetList: [],
    };
  }

  const investmentExist =
    state.alternativeInvestments.alternativeAssetList?.some(
      (investment) => investment.id === payload.id
    );

  if (investmentExist) {
    state.alternativeInvestments.alternativeAssetList =
      state.alternativeInvestments.alternativeAssetList?.map((investment) => {
        if (investment.id === payload.id) {
          return payload;
        }

        return investment;
      });
  } else {
    state.alternativeInvestments.alternativeAssetList?.push(payload);
  }

  state.alternativeInvestments.totalValue = sumAll(
    state.alternativeInvestments.alternativeAssetList
  );
};

export type BulkUpdatePayload = {
  payload: AlternativeInvestmentObject;
};

export const bulkUpdateAlternativeInvestment = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.alternativeInvestments = payload;
};
