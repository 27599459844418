import {
  FundInterface,
  FundItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";

export type FundItemPayload = {
  payload: FundItemInterface;
};

const sumAll = (list: FundItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.totalValue, 0);
};

export const deleteFundsReducer = (
  state: PortfolioDataInterface,
  { payload }: FundItemPayload
): void => {
  if (!state.funds) {
    state.funds = {
      id: 0,
      totalValue: 0,
      fundsList: [],
    };
  }

  state.funds.fundsList = state.funds?.fundsList?.filter(
    (fund) => fund.id !== payload.id
  );

  state.funds.totalValue = sumAll(state.funds.fundsList);
};

export const updateFundsReducer = (
  state: PortfolioDataInterface,
  { payload }: FundItemPayload
): void => {
  if (!state.funds) {
    state.funds = {
      id: 0,
      totalValue: 0,
      fundsList: [],
    };
  }

  const fundExist = state.funds?.fundsList?.some(
    (fund) => fund.id === payload.id
  );

  if (fundExist) {
    state.funds.fundsList = state.funds?.fundsList?.map((fund) => {
      if (fund.id === payload.id) {
        return payload;
      }

      return fund;
    });
  } else {
    state.funds.fundsList?.push(payload);
  }

  state.funds.totalValue = sumAll(state.funds.fundsList);
};

export type BulkUpdatePayload = {
  payload: FundInterface;
};

export const bulkUpdateFund = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.funds = payload;
};
