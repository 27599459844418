import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 1px 0 0 0;
`;

export default ErrorContainer;
