import { AxiosStatic } from "axios";
import { TwoFactorAuthMethod } from "../types";

const generateOtp =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (email: string, mfaMethod?: TwoFactorAuthMethod): Promise<void> => {
    await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/otp/generate`,
      {
        email,
        mfaMethod,
      },
      {
        withCredentials: true,
      }
    );
  };

export default generateOtp;
