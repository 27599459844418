import { IconType, Theme } from "@icon-park/react/es/all";
import { ColorType, theme } from "../../../styles/theme";
import TextTypes from "../../Text/types/textTypes";

export type ButtonColor =
  | "blue"
  | "green"
  | "red"
  | "lightBlue"
  | "darkBlue"
  | "navy"
  | "white";
export type IconPosition = "left" | "right";

export interface ButtonProps {
  text: string;
  selected?: boolean;
  icon?: IconType;
  iconTheme?: Theme;
  iconSize?: number;
  iconColor?: ColorType;
  iconPosition?: IconPosition;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  textSize?: keyof typeof TextTypes;
  textColor?: ColorType;
  height?: string;
  width?: string;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  testId?: string;
  borderRadius?: string;
}
export interface ButtonStates {
  default?: string;
  hover?: string;
  active?: string;
  selected?: string;
  disabled?: string;
}

export type ButtonStatesColorType = {
  [color in ButtonColor]: ButtonStates;
};

export const buttonStatesColors: ButtonStatesColorType = {
  blue: {
    default: theme.colors.BLUE600,
    hover: theme.colors.BLUE700,
    active: theme.colors.BLUE800,
    selected: theme.colors.BLUE900,
    disabled: theme.colors.SLATE200,
  },
  green: {
    default: theme.colors.GREEN400,
    hover: theme.colors.GREEN400,
    active: theme.colors.GREEN400,
    selected: theme.colors.GREEN400,
    disabled: theme.colors.SLATE200,
  },
  red: {
    default: theme.colors.R200,
    hover: theme.colors.R200,
    active: theme.colors.R300,
    selected: theme.colors.R400,
    disabled: theme.colors.SLATE200,
  },
  lightBlue: {
    default: theme.colors.BLUE100,
    hover: theme.colors.BLUE200,
    active: theme.colors.BLUE300,
    selected: theme.colors.BLUE600,
    disabled: theme.colors.SLATE200,
  },
  darkBlue: {
    default: theme.colors.BLUE900,
    hover: theme.colors.BLUE800,
    active: theme.colors.BLUE700,
    selected: theme.colors.BLUE600,
    disabled: theme.colors.SLATE200,
  },
  navy: {
    default: theme.colors.SLATE900,
    hover: theme.colors.SLATE500,
    active: theme.colors.SLATE900,
    selected: theme.colors.SLATE900,
    disabled: theme.colors.SLATE200,
  },
  white: {
    default: theme.colors.WHITE,
    hover: theme.colors.WHITE,
    active: theme.colors.WHITE,
    selected: theme.colors.WHITE,
    disabled: theme.colors.SLATE200,
  },
};
