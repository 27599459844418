import {
  StartupItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";
import { StartupObject } from "../constants/types";

export type StartupItemPayload = {
  payload: StartupItemInterface;
};

const sumAll = (list: StartupItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deleteStartupsReducer = (
  state: PortfolioDataInterface,
  { payload }: StartupItemPayload
): void => {
  if (!state.startups) {
    state.startups = {
      totalValue: 0,
      startupInvestments: [],
    };
  }

  state.startups.startupInvestments =
    state.startups?.startupInvestments?.filter(
      (startup) => startup.id !== payload.id
    );

  state.startups.totalValue = sumAll(state.startups?.startupInvestments);
};

export const updateStartupsReducer = (
  state: PortfolioDataInterface,
  { payload }: StartupItemPayload
): void => {
  if (!state.startups) {
    state.startups = {
      totalValue: 0,
      startupInvestments: [],
    };
  }

  const startupExist = state.startups?.startupInvestments?.some(
    (startup) => startup.id === payload.id
  );

  if (startupExist) {
    state.startups.startupInvestments = state.startups?.startupInvestments?.map(
      (startup) => {
        if (startup.id === payload.id) {
          return payload;
        }

        return startup;
      }
    );
  } else {
    state.startups?.startupInvestments?.push(payload);
  }

  state.startups.totalValue = sumAll(state.startups?.startupInvestments);
};

export type BulkUpdatePayload = {
  payload: StartupObject;
};

export const bulkUpdateStartup = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.startups = payload;
};
