import React from "react";
import Container from "../styled/Container";
import Text from "../styled/Text";
import Heading from "../styled/Heading";
import TextInput from "../styled/TextInput";
import InputIconContainer from "../styled/InputIconContainer";
import IconWrapper from "../styled/IconWrapper";
import ErrorContainer from "../styled/ErrorContainer";

type Props = {
  value: string;
  testId?: string;
  name?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  description?: string;
  placeholder?: string;
  heading?: string;
  disabled?: boolean;
  component?: JSX.Element | boolean;
};

const InputWithComponent: React.FC<Props> = ({
  value,
  testId,
  onChange,
  disabled,
  placeholder,
  heading,
  errorMessage,
  description,
  name,
  component,
}) => {
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      <InputIconContainer>
        <TextInput
          data-testid={testId}
          withIcon={true}
          type="text"
          placeholder={placeholder}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => onChange(e)}
          value={value}
          disabled={disabled}
          hasError={!!errorMessage}
          name={name}
        />
        <IconWrapper>{component}</IconWrapper>
      </InputIconContainer>
      <ErrorContainer>
        {errorMessage && <Text hasError={!!errorMessage}>{errorMessage}</Text>}
      </ErrorContainer>
    </Container>
  );
};

export default InputWithComponent;
