import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import { ButtonProps } from "../types/buttonTypes";
import { theme } from "../../../styles/theme";
import Text from "../../Text";

export interface LinkProps extends Omit<ButtonProps, "onClick"> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  [x: string]: unknown;
}

const StyledLink = styled.button<LinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 0.5em;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === "right" ? "row-reverse" : "row"};
`;

export const LinkButton: React.FC<LinkProps> = ({
  text,
  textColor = "BLUE600",
  textSize = "S16",
  iconTheme = "outline",
  iconSize = 25,
  iconColor = textColor,
  testId,
  icon,
  ...rest
}) => (
  <StyledLink text={text} icon={icon} {...rest} data-testid={testId}>
    {icon && (
      <span>
        <Icon
          type={icon}
          size={iconSize}
          theme={iconTheme}
          fill={theme.colors[iconColor]}
        />
      </span>
    )}
    <Text type={textSize} color={textColor}>
      {text}
    </Text>
  </StyledLink>
);

export default LinkButton;
