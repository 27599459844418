import React from "react";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BequestCharityInterface } from "@ifgengineering/hip-app-domain";
import Input from "../../../../../../legacyComponents/Form/input/Input/Input.component";
import { bqAddNewCharity } from "../../../../../../components/Wills/actions";
import { bqCharitySelector } from "../../../../../../components/Wills/selectors";
import {
  onHideModal,
  showBQPage,
  storePrevBQPage,
} from "../../../../../store/ui/actions";
import withResponsiveModal from "../../utils/withResponsiveModal";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import { GiftsAndBequestPageEnum } from "../../types";
import { createCharityBequestAPI } from "../../../../../../components/Wills/actions/bequest";
import { newCharitySchema } from "../../formValidations";
import store from "../../../../../store";
import { getUserEmail } from "../../../../../../components/Login/selectors";

const FormContainer = styled(Form)`
  display: grid;
  // gap: 0.5em;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: BequestCharityInterface;
    touched: BequestCharityInterface;
  };
};

const NewCharity: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const bqCharity = useSelector(bqCharitySelector);
  const initVals = {
    ...bqCharity,
  };

  const { LANDING, CHARITY_ESTATE } = GiftsAndBequestPageEnum;

  const userEmail = useSelector(getUserEmail);

  return (
    <Formik
      initialValues={initVals}
      validationSchema={newCharitySchema}
      onSubmit={async (values) => {
        const returnedCharityData = await dispatch(
          createCharityBequestAPI({
            data: { ...values, percentageEstate: 0 },
            email: userEmail,
          })
        ).unwrap();
        dispatch(bqAddNewCharity(returnedCharityData));
        dispatch(storePrevBQPage());
        dispatch(showBQPage(CHARITY_ESTATE));
      }}
    >
      {({ errors, touched }) => (
        <FormContainer>
          <Field name="fullName">
            {({ field }: FieldType) => (
              <Input
                heading="Charity name"
                {...field}
                errorMessage={
                  touched.fullName && errors.fullName ? errors.fullName : ""
                }
              />
            )}
          </Field>
          <Field name="charityID">
            {({ field }: FieldType) => (
              <Input heading="Charity number (optional)" {...field} />
            )}
          </Field>

          <Field name="description">
            {({ field }: FieldType) => (
              <Input
                isLong
                heading="Charity Description"
                description="Add as many details about the charity to help correctly identify it. You can also add causes you would like to support e.g. I would like to support their madrasa programme at my local masjid located in Bradford."
                {...field}
                errorMessage={errors.description ? errors.description : ""}
              />
            )}
          </Field>
          <FormButton
            text="Save & Continue"
            leftArrowLabel="Back"
            type="submit"
            leftArrowOnClick={() => {
              dispatch(showBQPage(LANDING));
              dispatch(onHideModal());
            }}
          />
        </FormContainer>
      )}
    </Formik>
  );
};

export default withResponsiveModal(NewCharity, {
  backPage: "LANDING",
  heading: "New charity",
  subheading:
    "Please tell us a little bit about your chosen charity so we can make sure the right people get your donation",
});
