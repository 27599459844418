import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const DropDownItem = styled.p<{ isSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.Font02};
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0 0.75rem;
  height: 2.5rem;
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.LightBlue : theme.colors.Primary02};

  &:hover  {
    background-color: ${theme.colors.LightBlue};};
}
`;

export default DropDownItem;
