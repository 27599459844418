import { AxiosPromise, AxiosStatic } from "axios";
import { GoogleLoginInput, GoogleLoginOutput } from "../types";

const googleLogin =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (data: GoogleLoginInput): AxiosPromise<GoogleLoginOutput> => {
    const response = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/google/callback`,
      data,
      {
        withCredentials: true,
      }
    );

    return response;
  };

export default googleLogin;
