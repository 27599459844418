import * as actions from "./constants/actionTypes";
import { createAction } from "@reduxjs/toolkit";
import {
  AgricultureItemInterface,
  BankAccountInterface,
  BankAccountItemInterface,
  BusinessItemInterface,
  CashInterface,
  ChildTrustFundInterface,
  ChildTrustFundItemInterface,
  CommodityItemInterface,
  CryptoInterface,
  CryptoItemInterface,
  DebtInterface,
  DebtItemInterface,
  DetailedAssetInterface,
  FundInterface,
  FundItemInterface,
  OwedAssetItemInterface,
  PensionInterface,
  PensionItemInterface,
  PortfolioDataInterface,
  PropertyInterface,
  PropertyItemInterface,
  ShareInterface,
  ShareTickerInterface,
  StartupItemInterface,
} from "@ifgengineering/hip-app-domain";
import {
  AgricultureObject,
  AlternativeInvestmentObject,
  BusinessObject,
  OwedAssetObject,
  StartupObject,
} from "./constants/types";

export const updatePortfolio = createAction<PortfolioDataInterface, string>(
  actions.UPDATE_PORTFOLIO
);

export const updateCashStore = createAction<CashInterface, string>(
  actions.UPDATE_CASH
);

export const deleteBankAccountItemStore = createAction<
  BankAccountItemInterface,
  string
>(actions.DELETE_BANK_ACCOUNT_ITEM);

export const updateBankAccountsStore = createAction<
  BankAccountInterface,
  string
>(actions.UPDATE_BANK_ACCOUNTS);

export const updateBankAccountItemStore = createAction<
  BankAccountItemInterface,
  string
>(actions.UPDATE_BANK_ACCOUNT_ITEM);

export const updateCommoditiesStore = createAction<
  CommodityItemInterface[],
  string
>(actions.UPDATE_COMMODITIES);

export const updateCommodityStore = createAction<
  CommodityItemInterface,
  string
>(actions.UPDATE_COMMODITY);

export const deleteCommodityStore = createAction<
  CommodityItemInterface,
  string
>(actions.DELETE_COMMODITY);

export const updateDebtStore = createAction<DebtInterface, string>(
  actions.UPDATE_DEBTS
);

export const updateDebtItemStore = createAction<DebtItemInterface, string>(
  actions.UPDATE_DEBT
);

export const deleteDebtStore = createAction<DebtItemInterface, string>(
  actions.DELETE_DEBT
);

export const updateChildTrustStore = createAction<
  ChildTrustFundInterface,
  string
>(actions.UPDATE_CHILD_TRUSTS);

export const updateChildTrustItemStore = createAction<
  ChildTrustFundItemInterface,
  string
>(actions.UPDATE_CHILD_TRUST);

export const deleteChildTrustItemStore = createAction<
  ChildTrustFundItemInterface,
  string
>(actions.DELETE_CHILD_TRUST);

export const updateFundsStore = createAction<FundInterface, string>(
  actions.UPDATE_FUNDS
);

export const updateFundStore = createAction<FundItemInterface, string>(
  actions.UPDATE_FUND
);

export const deleteFundStore = createAction<FundItemInterface, string>(
  actions.DELETE_FUND
);

export const updateShareStore = createAction<ShareInterface, string>(
  actions.UPDATE_SHARES
);

export const updateShareItemStore = createAction<ShareTickerInterface, string>(
  actions.UPDATE_SHARE
);

export const deleteShareItemStore = createAction<ShareTickerInterface, string>(
  actions.DELETE_SHARE
);

export const updateAgriculturesStore = createAction<AgricultureObject, string>(
  actions.UPDATE_AGRICULTURES
);

export const updateAgricultureStore = createAction<
  AgricultureItemInterface,
  string
>(actions.UPDATE_AGRICULTURE);

export const deleteAgricultureStore = createAction<
  AgricultureItemInterface,
  string
>(actions.DELETE_AGRICULTURE);

export const updateCryptosStore = createAction<CryptoInterface, string>(
  actions.UPDATE_CRYPTOS
);

export const updateCryptoStore = createAction<CryptoItemInterface, string>(
  actions.UPDATE_CRYPTO
);

export const deleteCryptoStore = createAction<CryptoItemInterface, string>(
  actions.DELETE_CRYPTO
);

export const updateStartupsStore = createAction<StartupObject, string>(
  actions.UPDATE_STARTUPS
);

export const updateStartupStore = createAction<StartupItemInterface, string>(
  actions.UPDATE_STARTUP
);

export const deleteStartupStore = createAction<StartupItemInterface, string>(
  actions.DELETE_STARTUP
);

export const updateAlternativeInvestmentsStore = createAction<
  AlternativeInvestmentObject,
  string
>(actions.UPDATE_ALTERNATIVE_INVESTMENTS);

export const updateAlternativeInvestmentStore = createAction<
  DetailedAssetInterface,
  string
>(actions.UPDATE_ALTERNATIVE_INVESTMENT);

export const deleteAlternativeInvestmentStore = createAction<
  DetailedAssetInterface,
  string
>(actions.DELETE_ALTERNATIVE_INVESTMENT);

export const updateOwedAssetsStore = createAction<OwedAssetObject, string>(
  actions.UPDATE_OWED_ASSETS
);

export const updateOwedAssetStore = createAction<
  OwedAssetItemInterface,
  string
>(actions.UPDATE_OWED_ASSET);

export const deleteOwedAssetStore = createAction<
  OwedAssetItemInterface,
  string
>(actions.DELETE_OWED_ASSET);

export const updateBusinessesStore = createAction<BusinessObject, string>(
  actions.UPDATE_BUSINESSES
);

export const updateBusinessStore = createAction<BusinessItemInterface, string>(
  actions.UPDATE_BUSINESS
);

export const deleteBusinessStore = createAction<BusinessItemInterface, string>(
  actions.DELETE_BUSINESS
);

export const updatePensionsStore = createAction<PensionInterface, string>(
  actions.UPDATE_PENSIONS
);

export const updatePensionItemStore = createAction<
  PensionItemInterface,
  string
>(actions.DELETE_PENSION);

export const deletePensionStore = createAction<PensionItemInterface, string>(
  actions.UPDATE_PENSION
);

export const updatePropertiesStore = createAction<PropertyInterface, string>(
  actions.UPDATE_PROPERTIES
);

export const updatePropertyStore = createAction<PropertyItemInterface, string>(
  actions.DELETE_PROPERTY
);

export const deletePropertyStore = createAction<PropertyItemInterface, string>(
  actions.UPDATE_PROPERTY
);

export const updateEstimatedEstateValue = createAction<{
  estimatedEstateValue?: number;
}>(actions.UPDATE_ESTIMATED_ESTATE_VALUE);

export const updateCurrency = createAction<{
  currency?: string;
}>(actions.UPDATE_CURRENCY);
