import styled from "styled-components";
import { theme } from "../../../styles";

type Props = {
  hasError?: boolean;
};

const TextArea = styled.textarea<Props>`
  width: 100%;
  box-sizing: border-box;
  height: 8rem;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: ${theme.colors.SLATE700};
  padding: 1rem;
  resize: none;
  border-radius: 12px;
  outline: transparent;
  border: ${({ hasError }) =>
    hasError
      ? `1px ${theme.colors.ERROR800} solid`
      : `1px ${theme.colors.SLATE300} solid`};

  &:focus {
    border: 1px ${theme.colors.BLUE600} solid;
  }
`;

export default TextArea;
