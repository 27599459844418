import { createReducer } from "@reduxjs/toolkit";
import initialState from "@components/Wills/reducers/initialState";
import {
  bqAddCharityToList,
  bqAddIndividualToList,
  bqAddNewCharity,
  bqAddNewIndividual,
  bqCreateBequest,
  bqDeleteCharityFromList,
  bqDeleteIndividualFromList,
  bqResetCharity,
  bqResetIndividual,
  bqSelectExistingCharity,
  bqSelectExistingIndividual,
  bqUpdateBequests,
  bqUpdateCharity,
  bqUpdateCharityInList,
  bqUpdateIndividual,
  bqUpdateIndividualInList,
  createFamilyMember,
  deleteExecutorStore,
  deleteGuardianStore,
  onUpdateDepartingMessage,
  onUpdateUserInfo,
  updateBeneficiaryDisabled,
  updateExecutorsStore,
  updateExecutorStore,
  updateFamily,
  updateFamilyMember,
  updateGuardiansStore,
  updateGuardianStore,
  updateMemberReceivingTestedBenefits,
  updateWills,
} from "@components/Wills/actions";
import { UserInfoObject, WillsState } from "@components/Wills/constants/types";
import updateUserInfoReducer from "@components/Wills/reducers/updateUserInfoReducer";
import { familyReducer } from "@components/Wills/reducers/family";
import updateExecutorsReducer from "@components/Wills/reducers/updateExecutorReducer";
import { bulkUpdateExecutorReducer } from "@components/Wills/reducers/bulkUpdateExecutorReducer";
import { bulkUpdateGuardianReducer } from "@components/Wills/reducers/bulkUpdateGuardianReducer";
import updateGuardiansReducer from "@components/Wills/reducers/updateGuardiansReducer";
import addIndividualToListReducer from "@components/Wills/reducers/bequests/addIndividualToListReducer";
import deleteIndividualFromListReducer from "@components/Wills/reducers/bequests/deleteIndividualFromListReducer";
import updateBequestsReducer from "@components/Wills/reducers/bequests/updateBequestsReducer";
import addCharityToListReducer from "@components/Wills/reducers/bequests/addCharityToListReducer";
import updateCharityInListReducer from "@components/Wills/reducers/bequests/updateCharityInListReducer";
import deleteCharityFromListReducer from "@components/Wills/reducers/bequests/deleteCharityFromListReducer";
import updateIndividualInListReducer from "@components/Wills/reducers/bequests/updateIndividualInListReducer";

export const willsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      onUpdateUserInfo,
      (state: WillsState, { payload }: { payload: UserInfoObject }) => {
        updateUserInfoReducer(state, payload);
      }
    )
    .addCase(updateFamilyMember, (state: WillsState, { payload }) => {
      state.will.family.familyMembers = state.will.family.familyMembers.map(
        (familyMember) =>
          familyMember.id === payload.id ? payload : familyMember
      );
    })
    .addCase(createFamilyMember, (state: WillsState, { payload }) => {
      state.will.family.familyMembers.push(payload);
    })
    .addCase(updateFamily, familyReducer)
    .addCase(
      updateMemberReceivingTestedBenefits,
      (state: WillsState, { payload }) => {
        state.will.family.memberReceivingTestedBenefits = payload;
      }
    )
    .addCase(updateBeneficiaryDisabled, (state: WillsState, { payload }) => {
      state.will.family.memberDisabled = payload;
    })
    .addCase(updateExecutorStore, (state: WillsState, { payload }) => {
      updateExecutorsReducer(state, payload);
    })
    .addCase(updateExecutorsStore, (state: WillsState, { payload }) => {
      bulkUpdateExecutorReducer(state, payload);
    })
    .addCase(deleteExecutorStore, (state: WillsState, { payload }) => {
      state.will.overseer.executionerList =
        state.will.overseer.executionerList.filter(
          (exec) => exec.id !== payload
        );
    })
    .addCase(updateGuardiansStore, (state: WillsState, { payload }) => {
      bulkUpdateGuardianReducer(state, payload);
    })
    .addCase(updateGuardianStore, (state: WillsState, { payload }) => {
      updateGuardiansReducer(state, payload);
    })
    .addCase(deleteGuardianStore, (state: WillsState, { payload }) => {
      state.will.overseer.guardianList =
        state.will.overseer.guardianList.filter(
          (guard) => guard.id !== payload
        );
    })
    .addCase(onUpdateDepartingMessage, (state, { payload }) => {
      state.will.departingMessage = payload;
    })
    .addCase(updateWills, (state: WillsState, { payload }) => {
      state.will = payload;
    })
    .addCase(bqCreateBequest, (state, { payload }) => {
      state.will.bequests = {
        ...payload,
      };
    })
    .addCase(bqAddNewIndividual, (state, { payload }) => {
      if (payload.emailAddress === "") {
        delete payload.emailAddress;
        delete state.bq_individual.emailAddress;
      }

      state.bq_individual = {
        ...payload,
        islamicInheritor: false,
      };
    })
    .addCase(bqSelectExistingIndividual, (state, { payload }) => {
      state.bq_individual = payload;
    })
    .addCase(bqUpdateIndividual, (state, { payload }) => {
      state.bq_individual = {
        ...state.bq_individual,
        ...payload,
      };
    })
    .addCase(bqResetIndividual, (state) => {
      state.bq_individual = { ...initialState.bq_individual };
    })
    .addCase(bqAddIndividualToList, addIndividualToListReducer)
    .addCase(bqDeleteIndividualFromList, deleteIndividualFromListReducer)
    .addCase(bqUpdateBequests, updateBequestsReducer)
    .addCase(bqAddNewCharity, (state, { payload }) => {
      state.bq_charity = {
        ...payload,
      };
    })
    .addCase(bqSelectExistingCharity, (state, { payload }) => {
      state.bq_charity = {
        ...payload,
      };
    })
    .addCase(bqUpdateCharity, (state, { payload }) => {
      state.bq_charity = { ...state.bq_charity, ...payload };
    })
    .addCase(bqResetCharity, (state) => {
      state.bq_charity = { ...initialState.bq_charity };
    })
    .addCase(bqAddCharityToList, addCharityToListReducer)
    .addCase(bqUpdateCharityInList, updateCharityInListReducer)
    .addCase(bqDeleteCharityFromList, deleteCharityFromListReducer)
    .addCase(bqUpdateIndividualInList, updateIndividualInListReducer);
});
