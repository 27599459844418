import { theme } from "@ifgengineering/component-library";
import { createGlobalStyle } from "styled-components";
import { tailwindReset } from "../../styles/tailwind-css-reset";
/**
This file should consist of Wills-specific global syling.
A good example is our font family.
- `font-family: "Inter", sans-serif`
 */

const GlobalStyle = createGlobalStyle`
  ${tailwindReset}
  body {
    font-family: "Inter", sans-serif;
    background-color: ${theme.colors.SLATE200}
  }

  span[data-testid="security-layer"] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
  }  
`;

export default GlobalStyle;
