import React, { FC } from "react";
import styled from "styled-components";

type STACK_ORIENTATION = "vertical" | "horizontal";

type JUSTIFY_CONTENT =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

type ALIGN_ITEMS =
  | "center"
  | "flex-start"
  | "flex-end"
  | "stretch"
  | "baseline";

type FLEX_WRAP = "wrap" | "nowrap";

type ALIGN_CONTENT =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between"
  | "stretch";

type StackProps = {
  orientation?: STACK_ORIENTATION;
  justifyContent?: JUSTIFY_CONTENT;
  alignItems?: ALIGN_ITEMS;
  alignContent?: ALIGN_CONTENT;
  flexWrap?: FLEX_WRAP;
  width?: number;
  minWidth?: number;
  gap?: number;
};

export const Stack: FC<StackProps> = ({ children, ...props }) => (
  <FlexBuilder {...props}>{children}</FlexBuilder>
);

const FlexBuilder = styled.div<StackProps>`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "column" : "row"};
  ${({ gap }) => (gap !== undefined ? `gap: ${gap}px` : "")};
  ${({ width }) => (width !== undefined ? `width: ${width}px` : "")};
  ${({ minWidth }) =>
    minWidth !== undefined ? `min-width: ${minWidth}px` : ""};
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent}` : ""};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
  ${({ alignContent }) =>
    alignContent ? `align-content: ${alignContent}` : ""};
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap}` : "")};
`;

export default Stack;
