import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

const SectionInput = styled.input`
  text-align: center;
  color: ${theme.colors.Font02};
  font-size: 1rem;
  width: 20%;
  border: none;
  outline: none;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export default SectionInput;
