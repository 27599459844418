import { GuardianObject, WillsState } from "../constants/types";

export const bulkUpdateGuardianReducer = (
  state: WillsState,
  guardians: GuardianObject[]
) => {
  if (state.will?.overseer) {
    state.will.overseer.guardianList = guardians;
  } else {
    state.will.overseer = {
      ...state.will.overseer,
      guardianList: guardians,
    };
  }
};
