import { createReducer } from "@reduxjs/toolkit";
import { UiState } from "./constants/types";
import {
  onShowModal,
  onHideModal,
  showBQPage,
  storePrevBQPage,
} from "./actions";
import { GiftsAndBequestPageEnum } from "../../views/forms/giftsAndBequest/types";

export const initialState: UiState = {
  modal: {
    visible: false,
  },
  bequestPage: GiftsAndBequestPageEnum.LANDING,
  prevBQPage: GiftsAndBequestPageEnum.NEW_INDIVIDUAL,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onShowModal, (state) => {
      state.modal.visible = true;
    })
    .addCase(onHideModal, (state) => {
      state.modal.visible = false;
    })
    .addCase(showBQPage, (state, action) => {
      state.bequestPage = action.payload;
    })
    .addCase(storePrevBQPage, (state) => {
      state.prevBQPage = state?.bequestPage;
    });
});
