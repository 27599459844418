import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { navigate as navigateToAnotherApp } from "@reach/router";
import styled from "styled-components";
import { AddItemCardContainer, GroupingHeaders, PageTexts } from "../styles";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import { GiftsAndBequestPageEnum } from "../types";
import { showBQPage, storePrevBQPage } from "@willsStore/ui/actions";
import BequestIndividualCard from "@willsComponents/Cards/BequestIndividualCard/BequestIndividualCard.component";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  bqCharitiesSelector,
  bqIndividualsSelector,
  willSelector,
} from "@components/Wills/selectors";
import {
  bqCreateBequest,
  bqDeleteCharityFromList,
  bqDeleteIndividualFromList,
  bqResetCharity,
  bqResetIndividual,
  bqSelectExistingIndividual,
  bqUpdateCharity,
} from "@components/Wills/actions";
import {
  createBequestAPI,
  deleteCharityBequestAPI,
  deleteIndividualBequestAPI,
} from "@components/Wills/actions/bequest";
import FormNavigationButton, {
  NavigationDirections,
} from "@legacyComponents/FormNavigationButton/FormNavigationButton.component";
import store from "@willsStore/index";
import CharityCard from "@willsComponents/Cards/CharityCard/CharityCard.component";
import { getCharity } from "@components/Charity/selectors";
import { getUserEmail } from "@components/Login/selectors";
import { WILLS_SITE_URL } from "gatsby-env-variables";

const Container = styled.div`
  display: grid;
  gap: 2em;
`;

const AddedInheritorsContainer = styled.div`
  display: grid;
  gap: 0.7em;
`;

const GiftsAndBequestLanding: React.FC = () => {
  const {
    NEW_INDIVIDUAL,
    EXISTING_INDIVIDUAL,
    EDIT_INDIVIDUAL,
    ESTATE_ALLOCATION,
    NEW_CHARITY,
    EDIT_CHARITY,
    CHARITY_ESTATE,
    SPONSORED_CHARITY,
    SPONSORED_CHARITY_DONATION,
  } = GiftsAndBequestPageEnum;

  const sponsoredCharity = useSelector(getCharity);

  const dispatch = useDispatch<typeof store.dispatch>();
  const bqIndividualsList = useSelector(bqIndividualsSelector);
  const bqCharitiesList = useSelector(bqCharitiesSelector);
  const will = useSelector(willSelector);
  const userEmail = useSelector(getUserEmail);

  const charitiesAvailable =
    bqCharitiesList !== undefined && bqCharitiesList.length > 0;
  const individualVailable =
    bqIndividualsList !== undefined && bqIndividualsList.length > 0;

  const createBequests = async () => {
    const newlyCreatedBequest = await dispatch(
      createBequestAPI(userEmail)
    ).unwrap();

    dispatch(bqCreateBequest({ ...newlyCreatedBequest }));
  };

  const sponsoredCharityBequest =
    bqCharitiesList &&
    bqCharitiesList.find(
      (bq) =>
        bq.partnerCharity?.id === sponsoredCharity.id &&
        sponsoredCharity.id !== undefined
    );

  useEffect(() => {
    /**
     * If a user with a regular wills reach the Gifts & Bequests
     * before we set the Wills status to in-progress, when we would normally redirect them,
     * send them to the regular Wills website.
     */
    if (
      sponsoredCharity.id !== undefined &&
      sponsoredCharity.key !== undefined &&
      will.partnerCharityId === null
    ) {
      navigateToAnotherApp(WILLS_SITE_URL);
    }
  }, [sponsoredCharity, will.partnerCharityId]);

  useEffect(() => {
    /**
    There is an issue with the domain types where
    it doesn't have the id, but we actually receive 
    it is from the API  
    @ts-ignore */
    if (will.id && will.bequests === null) {
      createBequests();
    }
    /**
    There is an issue with the domain types where
    it doesn't have the id, but we actually receive 
    it is from the API  
    @ts-ignore */
  }, [will.id]);

  const canProceed =
    charitiesAvailable || individualVailable || sponsoredCharity.id;

  const sponsoredCharityDestination = sponsoredCharity.id
    ? () => dispatch(showBQPage(SPONSORED_CHARITY))
    : () => navigate("/app/wills");

  const formConfirm = sponsoredCharityBequest
    ? () => dispatch(showBQPage(SPONSORED_CHARITY_DONATION))
    : () => sponsoredCharityDestination();

  return (
    <>
      <Container>
        <div>
          <PageTexts
            heading="Would you like to leave a gift to a person and/or charity?"
            help
          />
        </div>
        <AddItemCardContainer>
          <GroupingHeaders>Person</GroupingHeaders>
          <AddItemCard
            text="Add a new person"
            onClick={() => {
              dispatch(bqResetIndividual());
              dispatch(showBQPage(NEW_INDIVIDUAL));
            }}
          />
          <AddItemCard
            text="Select an existing inheritor"
            onClick={() => {
              dispatch(showBQPage(EXISTING_INDIVIDUAL));
            }}
          />
          <PageTexts
            subheading="If you decide to bequest a part of your estate to somebody who is already an islamic inheritor, they will need consent of all other Islamic inheritors.  "
            help
          />
        </AddItemCardContainer>
        <AddedInheritorsContainer>
          {bqIndividualsList?.map((individual, i) => (
            <BequestIndividualCard
              key={i}
              name={`${individual.firstName} ${individual.lastName}`}
              familyRelationship={individual.relationship}
              money={individual.money}
              percentageOfEstate={individual.percentageEstate}
              items={individual.itemsToLeave}
              editBequests={() => {
                dispatch(bqResetIndividual());
                dispatch(bqSelectExistingIndividual(individual));
                dispatch(showBQPage(EDIT_INDIVIDUAL));
              }}
              addBequests={() => {
                dispatch(bqSelectExistingIndividual(individual));
                dispatch(storePrevBQPage());
                dispatch(showBQPage(ESTATE_ALLOCATION));
              }}
              deleteBequest={() => {
                dispatch(deleteIndividualBequestAPI(individual.id));
                dispatch(bqDeleteIndividualFromList(individual.id));
              }}
            />
          ))}
        </AddedInheritorsContainer>
        <div>
          <GroupingHeaders>Charity</GroupingHeaders>
          <PageTexts
            subheading="50% of our customers leave a portion of their estate to charity, may Allah accept from them."
            help
          />
        </div>
        {sponsoredCharity.id && (
          <CharityCard
            text={sponsoredCharity.displayName}
            logoURL={sponsoredCharity.logoKey}
            buttonText={sponsoredCharityBequest ? "Edit" : "Add"}
            onClick={() => dispatch(showBQPage(SPONSORED_CHARITY))}
          />
        )}
        <AddItemCard
          text="Add a new charity"
          onClick={() => {
            dispatch(bqResetCharity());
            dispatch(showBQPage(NEW_CHARITY));
          }}
        />
        <AddedInheritorsContainer>
          {bqCharitiesList
            ?.filter((bq) => !bq.partnerCharity)
            .map((charity, i) => (
              <BequestIndividualCard
                key={i}
                name={charity.fullName}
                familyRelationship=""
                money={charity.money}
                percentageOfEstate={charity.percentageEstate}
                editBequests={() => {
                  dispatch(bqUpdateCharity(charity));
                  dispatch(showBQPage(EDIT_CHARITY));
                }}
                addBequests={() => {
                  dispatch(bqUpdateCharity(charity));
                  dispatch(showBQPage(CHARITY_ESTATE));
                }}
                deleteBequest={() => {
                  dispatch(deleteCharityBequestAPI(charity.id));
                  dispatch(bqDeleteCharityFromList(charity.id));
                }}
              />
            ))}
        </AddedInheritorsContainer>
        <div>
          {canProceed && (
            <FormButton text="Save & Continue" onClick={formConfirm} />
          )}
          <FormNavigationButton
            onClick={() => {
              navigate("/app/wills");
            }}
            text="Back"
            direction={NavigationDirections.BACKWARD}
          />
        </div>
      </Container>
    </>
  );
};

export default GiftsAndBequestLanding;
