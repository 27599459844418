import * as Sentry from "@sentry/browser";

interface TrackedRequestInput {
  email?: string;
  tag?: string;
  exception: any;
}

export const logException = ({
  email,
  tag,
  exception,
}: TrackedRequestInput): void => {
  if (email) {
    Sentry.setUser({ email });
  }

  if (tag) {
    Sentry.setTag("request", tag);
  }

  Sentry.captureException(exception);
};
