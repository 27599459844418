import { TextType } from "../types/textTypes";

export const tags: Record<TextType, keyof HTMLElementTagNameMap> = {
  [TextType.T48]: "h1",
  [TextType.T40]: "h1",
  [TextType.T32]: "h1",
  [TextType.T24]: "h2",
  [TextType.T18]: "h3",
  [TextType.H32]: "h1",
  [TextType.H24]: "h2",
  [TextType.H20]: "h3",
  [TextType.H18]: "h4",
  [TextType.H16]: "h5",
  [TextType.H14]: "h6",
  [TextType.H12M]: "h6",
  [TextType.H12]: "h6",
  [TextType.H10]: "h6",
  [TextType.S20]: "span",
  [TextType.S18]: "span",
  [TextType.S16]: "span",
  [TextType.S14]: "span",
  [TextType.P18]: "p",
  [TextType.P16]: "p",
  [TextType.P14]: "p",
  [TextType.P12]: "p",
  [TextType.CM14]: "p",
  [TextType.CD12]: "p",
  [TextType.U12]: "p",
  [TextType.U14]: "p",
  [TextType.U14R]: "p",
  [TextType.U16]: "p",
  [TextType.U16R]: "p",
};

export default tags;
