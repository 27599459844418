import React from "react";
import styled from "styled-components";
import Button, {
  ButtonStates,
} from "../../../../legacyComponents/Button/Button.component";
import CardContainer, {
  CardContainerStyles,
} from "../../../../legacyComponents/CardContainer/CardContainer.component";
import RubbishCanIcon from "../../../../assets/icons/rubbish_can_icon.svg";

const StyledContentWrapper = styled.div`
  display: flex;
  padding: 10px 0 10px 10px;
`;

const StyledCardText = styled.div`
  flex-grow: 1;
  font-family: "Inter", sans-serif;
  text-align: left;
`;

const StyledFamilyRelationship = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
`;

const StyledName = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;

  & > button {
    padding: 10px 20px;
  }
`;

const RubbishCanWrapper = styled.div`
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

type FamilyCardProps = {
  familyRelationship?: string;
  isSelected?: boolean;
  name: string;
  enableDelete?: boolean;
  buttonLabel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
};

const FamilyCard = ({
  familyRelationship,
  isSelected = false,
  enableDelete = false,
  buttonLabel = "Edit",
  name,
  onClick,
  onRemove,
}: FamilyCardProps): JSX.Element => (
  <CardContainer
    cardStyle={
      isSelected ? CardContainerStyles.SELECTED : CardContainerStyles.REGULAR
    }
  >
    <StyledContentWrapper>
      <StyledCardText>
        <StyledFamilyRelationship>
          {familyRelationship}
        </StyledFamilyRelationship>
        <StyledName>{name}</StyledName>
      </StyledCardText>
      <StyledButton>
        <Button
          text={buttonLabel}
          onClick={onClick}
          version={
            isSelected ? ButtonStates.PRIMARY : ButtonStates.PRIMARY_GHOST_LARGE
          }
        />
        {enableDelete && (
          <RubbishCanWrapper>
            <RubbishCanIcon onClick={onRemove} data-testid="remove-button" />
          </RubbishCanWrapper>
        )}
      </StyledButton>
    </StyledContentWrapper>
  </CardContainer>
);

export default FamilyCard;
