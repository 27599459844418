import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Input from "../../../../../../legacyComponents/Form/input/Input/Input.component";
import { PageMetaType } from "../../types";
import AddressFields from "../../../../../components/AddressFields";
import { Field, Form, Formik } from "formik";
import { onHideModal, storePrevBQPage } from "../../../../../store/ui/actions";
import { showBQPage } from "../../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import withResponsiveModal from "../../utils/withResponsiveModal";
import { bqIndividualSelector } from "../../../../../../components/Wills/selectors";
import { updateIndividualBequestAPI } from "../../../../../../components/Wills/actions/bequest";
import {
  bqUpdateIndividual,
  bqUpdateIndividualInList,
} from "../../../../../../components/Wills/actions";
import store from "../../../../../store";

export const editIndividualMeta: PageMetaType = {
  heading: "Edit individual",
  subheading: "",
  backPage: "LANDING",
};

const FormContainer = styled(Form)`
  display: grid;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: {
      firstName: string;
      lastName: string;
      emailAddress?: string;
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      postcode?: string;
      relationship: string;
    };
    touched: {
      firstName: string;
      lastName: string;
      emailAddress?: string;
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      postcode?: string;
      relationship: string;
    };
  };
};

const EditIndividual: React.FC = () => {
  const { LANDING, ESTATE_ALLOCATION } = GiftsAndBequestPageEnum;

  const dispatch = useDispatch<typeof store.dispatch>();
  const bqIndividual = useSelector(bqIndividualSelector);

  const initVals = {
    firstName: bqIndividual.firstName,
    lastName: bqIndividual.lastName,
    emailAddress: bqIndividual.emailAddress,
    addressLine1: bqIndividual?.address?.addressLine1,
    addressLine2: bqIndividual?.address?.addressLine2,
    city: bqIndividual?.address?.city,
    postcode: bqIndividual?.address?.postcode,
    relationship: bqIndividual.relationship,
  };

  return (
    <Formik
      initialValues={initVals}
      onSubmit={async ({
        firstName,
        lastName,
        emailAddress,
        relationship,
        addressLine1,
        addressLine2,
        city,
        postcode,
      }) => {
        const returnedData = await dispatch(
          updateIndividualBequestAPI({
            data: {
              id: bqIndividual.id,
              firstName,
              lastName,
              emailAddress,
              relationship,
              islamicInheritor: bqIndividual.islamicInheritor,
              address: {
                addressLine1,
                addressLine2,
                city,
                postcode,
              },
            },
          })
        ).unwrap();
        dispatch(bqUpdateIndividual(returnedData));
        dispatch(bqUpdateIndividualInList(returnedData));
        dispatch(storePrevBQPage());
        dispatch(showBQPage(ESTATE_ALLOCATION));
      }}
    >
      {({ values }) => (
        <FormContainer>
          <Field name="firstName">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                heading="First name (including any middle names)"
                {...field}
                errorMessage={errors.firstName ? touched.firstName : undefined}
              />
            )}
          </Field>
          <Field name="lastName">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                heading="Last name"
                {...field}
                errorMessage={errors.lastName ? touched.lastName : undefined}
              />
            )}
          </Field>

          <AddressFields isOpen={Boolean(values.addressLine1)} />

          <Field name="emailAddress">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                type="email"
                heading="Email"
                {...field}
                errorMessage={
                  errors.emailAddress ? touched.emailAddress : undefined
                }
              />
            )}
          </Field>
          <Field name="relationship">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                heading="Relationship"
                {...field}
                errorMessage={
                  errors.relationship ? touched.relationship : undefined
                }
              />
            )}
          </Field>
          <FormButton
            text="Save & Continue"
            leftArrowLabel="Back"
            type="submit"
            leftArrowOnClick={() => {
              dispatch(showBQPage(LANDING));
              dispatch(onHideModal());
            }}
          />
        </FormContainer>
      )}
    </Formik>
  );
};

export default withResponsiveModal(EditIndividual, editIndividualMeta);
