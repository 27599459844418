import { BequestCharityInterface } from "@ifgengineering/hip-app-domain";
import { WillsState } from "../../constants/types";

const updateCharityInListReducer = (
  state: WillsState,
  action: {
    payload: BequestCharityInterface;
  }
) => {
  const { payload } = action;
  const updatedCharityList = state.will.bequests.bequestCharity?.map(
    (charity) =>
      charity.id == payload.id ? { ...charity, ...payload } : charity
  );
  state.will.bequests.bequestCharity = updatedCharityList;
};

export default updateCharityInListReducer;
