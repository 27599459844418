import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

type Props = {
  clickable?: boolean;
  hasError?: boolean;
  focused?: boolean;
};

const DropDownWrapper = styled.div<Props>`
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  background-color: ${theme.colors.Primary02};
  font-size: 1rem;
  color: ${theme.colors.Font02};
  padding: 0 1rem;
  height: 3rem;
  user-select: none;
  outline: transparent;
  border-radius: 12px;
  border: ${({ hasError, focused }) => {
    switch (true) {
      case hasError:
        return `1px ${theme.colors.ErrorRed} solid`;
      case focused:
        return `1px ${theme.colors.Primary01} solid`;
      default:
        return `1px ${theme.colors.Grey02} solid`;
    }
  }};
`;
export default DropDownWrapper;
