import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const DropDownBox = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: ${theme.colors.Primary02};
  height: 11.75rem;
  box-shadow: 0 0 23px 0 rgba(39, 55, 122, 0.15);
  margin-top: 0.5rem;
  overflow: scroll;
  margin-bottom: 2rem;
`;

export default DropDownBox;
