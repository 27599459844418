import React from "react";
import styled from "styled-components";
import CardContainer from "../CardContainer/CardContainer.component";
import AddItemIcon from "../../assets/icons/AddItem.svg";

type StyledComponentsProps = {
  disabled?: boolean;
};

const StyledContentWrapper = styled.div<StyledComponentsProps>`
  display: flex;
  padding: 20px 10px;
  opacity: ${({ disabled }) => (!disabled ? "1" : "0.3")};
`;

const StyledCardText = styled.div`
  flex-grow: 1;
  font-family: "Inter", sans-serif;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

const StyledButton = styled.button<StyledComponentsProps>`
  background: none;
  border: none;
  height: 24px;
  padding: 0;
  width: 24px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
  opacity: ${({ disabled }) => (!disabled ? "1" : "0.3")};
`;

type AddItemCardProps = {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
};

const AddItemCard = ({
  text,
  disabled,
  onClick,
}: AddItemCardProps): JSX.Element => {
  const cardContainerProps = disabled ? {} : { onClick };

  return (
    <CardContainer {...cardContainerProps}>
      <StyledContentWrapper disabled={disabled}>
        <StyledCardText>{text}</StyledCardText>
        <StyledButton data-testid="add-button" disabled={disabled}>
          <AddItemIcon />
        </StyledButton>
      </StyledContentWrapper>
    </CardContainer>
  );
};

export default AddItemCard;
