import styled from "styled-components";

export const CheckboxInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-right: 1rem;
`;

export default CheckboxInputContainer;
