import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/reducers";
import { portfolioDataReducer } from "./portfolioData/reducers";
import { uiReducer } from "./ui/reducers";
import { charityReducers } from "./charity/reducers";
import persistReducer from "redux-persist/es/persistReducer";
import resourcesReducer from "./resources";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["isAuthenticated"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  ui: uiReducer,
  portfolioData: portfolioDataReducer,
  resources: resourcesReducer,
  charity: charityReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
