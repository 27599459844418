import Icon from "@icon-park/react/es/all";
import React from "react";
import { theme } from "../../styles";
import Text from "../Text";
import ErrorMessageContainer from "./styled/ErrorMessageContainer";
import StyledCheckboxInputGroup from "./styled/StyledCheckboxInputGroup";

export enum CheckboxInputDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export type CheckboxInputGroupProps = {
  children: React.ReactNode;
  direction?: CheckboxInputDirection;
  testId?: string;
  gap?: string;
  errorMessage?: string;
};

const CheckboxInputGroup: React.FC<CheckboxInputGroupProps> = ({
  direction = CheckboxInputDirection.VERTICAL,
  children,
  testId,
  gap,
  errorMessage,
}) => (
  <StyledCheckboxInputGroup
    data-testid={testId}
    direction={direction}
    gap={gap}
  >
    {children}
    {errorMessage && (
      <ErrorMessageContainer>
        <Icon
          type="Caution"
          size={14}
          fill={theme.colors.ERROR800}
          theme="filled"
        />
        <Text type="P14" color="ERROR800">
          {errorMessage}
        </Text>
      </ErrorMessageContainer>
    )}
  </StyledCheckboxInputGroup>
);

export default CheckboxInputGroup;
