import styled from "styled-components";

export const RadioInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
`;

export default RadioInputContainer;
