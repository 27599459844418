import { AxiosStatic } from "axios";

const passwordlessStart =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (email: string, isSignup?: boolean): Promise<void> => {
    await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/passwordless/start`,
      {
        user: {
          email,
          isSignup,
        },
      },
      {
        withCredentials: true,
      }
    );
  };

export default passwordlessStart;
