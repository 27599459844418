import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  margin: 0 0.375rem;
  align-items: center;
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  border: 2px solid ${theme.colors.Font02};
  border-radius: 4px;
  position: relative;

  ${Label}:hover & {
    background: ${theme.colors.LightBlue};
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.063rem;
    left: 0.313rem;
    width: 0.188rem;
    height: 0.5rem;
    border: solid ${theme.colors.Font02};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const LabelText = styled.p`
  color: ${theme.colors.Font02};
  cursor: pointer;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  margin-left: 0.375rem;
`;

type Props = {
  checked: boolean;
  value?: string;
  onChange?: () => void;
  label?: string;
};

export const Checkbox: React.FunctionComponent<Props> = ({
  value,
  checked,
  onChange,
  label,
}) => (
  <Label>
    <Input
      type="checkbox"
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <Indicator />
    <LabelText>{label}</LabelText>
  </Label>
);

export default Checkbox;
