/**
 * Add here the errors you want to filter out
 *
 * As the blockedException() function will match based on a Regex, you can add
 * anything present in the error stack trace, from the error message to the function crashing.
 *
 * Examples:
 *
 * 1 - The Gtag error
 * The stack trace will be something like
 *
 * TypeError: Failed to fetch
 * at <anonymous>(webpack://@ifg/client-invest/../../node_modules/@sentry/utils/esm/instrument.js:148:28)
 * at c(/gtag/js:238:309)
 *
 * We can't use the message "Failed to fetch" as it is too generic.
 * In this case we want to filter it based on the "gtag"
 *
 * 2 - Loading chunk error
 * The error message will to be something like "Loading chunk 891 failed."
 * We can't use the entire error message as the chunk number every build
 * As the blockedException() will match the string, we can use the first part
 * "Loading chunk" and it will be enough.
 *
 * 3 - Non-Error promise rejection captured with value: Timeout
 * This one we can filter by the error message.
 *
 */
const logDecision: Record<string, boolean> = {
  gtag: true,
  "Loading chunk": true,
  "Unexpected token": true,
  "Non-Error promise rejection captured": true,
  "Network Error": true,
  "You need to call FB.init()": true,
  "Received status code 401": true,
  "Failed to load Stripe.js": true,
};

export const blockedException = (error: string): boolean =>
  Object.keys(logDecision).some((item) => error.match(new RegExp(item, "gi")));
