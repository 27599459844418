import React from "react";
import { theme } from "../../../styles";
import {
  Wrapper,
  TopLogoWrapper,
  CloseIcon,
  ChildrenWrapper,
  BottomLogoWrapper,
  LogoContainer,
} from "../styled";
import { NavigationDrawerProps } from "../types";

export const NavigationDrawer = ({
  primaryLogo,
  secondaryLogo,
  bgColor,
  onClose,
  children,
}: NavigationDrawerProps): JSX.Element => {
  return (
    <Wrapper bgColor={bgColor}>
      <TopLogoWrapper>
        {primaryLogo && (
          <LogoContainer>
            <img src={primaryLogo} alt="primary logo" />
          </LogoContainer>
        )}
        <CloseIcon
          data-testid="mobile-close-icon"
          type="close"
          size={20}
          fill={theme.colors.SLATE100}
          onClick={onClose}
        />
      </TopLogoWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {secondaryLogo && (
        <BottomLogoWrapper>
          <LogoContainer>
            <img src={secondaryLogo} alt="secondary logo" />
          </LogoContainer>
        </BottomLogoWrapper>
      )}
    </Wrapper>
  );
};
