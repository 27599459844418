import React, { FC } from "react";
import styled from "styled-components";
import { DefaultLoader, Spinner } from "./styled";

const PageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  backdrop-filter: blur(6px);
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
`;

export interface LoaderProps {
  size?: number;
  backColor?: string;
  frontColor?: string;
  thickness?: number;
}

const Loader: FC<LoaderProps> = ({
  size = 90,
  thickness = 5,
  frontColor = "black",
  backColor = "#e3e3e3",
  children,
}) => (
  <PageOverlay data-testid="loader-page-overlay">
    {children || (
      <Spinner>
        <DefaultLoader
          size={size}
          backColor={backColor}
          frontColor={frontColor}
          thickness={thickness}
          position="absolute"
          data-testid="loader"
        />
      </Spinner>
    )}
  </PageOverlay>
);

export default Loader;
