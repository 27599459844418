import axios from "axios";
import { InitializeApi } from "@ifgengineering/client-auth-sdk";
import { AxiosResponse } from "axios";
import { CUR8_SERVER, CORE_API } from "gatsby-env-variables";

const getRefreshToken = async (): Promise<AxiosResponse<never> | null> => {
  const authSdk = InitializeApi({
    serverURL: CUR8_SERVER || CORE_API,
    appAxios: axios,
  });

  return authSdk
    .refreshAccessToken()
    .then((response) => response)
    .catch((error) => error);
};

export default getRefreshToken;
