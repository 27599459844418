import { createReducer } from "@reduxjs/toolkit";
import {
  addItemsToShortlistStore,
  addItemToShortlistStore,
  removeItemFromShortlistStore,
} from "./shortlist.actions";
import { ShortlistState } from "./shortlist.types";

export const initialState: ShortlistState = {
  loading: false,
  items: [],
};

export const shortlistReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addItemToShortlistStore, (state, { payload }) => {
      const items = [...state.items, payload];

      return {
        ...state,
        items,
      };
    })
    .addCase(removeItemFromShortlistStore, (state, { payload }) => ({
      ...state,
      items: state.items.filter((item) => item.product.id !== payload.id),
    }))
    .addCase(addItemsToShortlistStore, (state, { payload }) => ({
      ...state,
      items: [...payload],
    }));
});
