import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const HeadingText = styled.h2<{ fontSize?: string }>`
  color: ${theme.colors.BrandNavy};
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  font-weight: 700;
`;

export const SubHeadingText = styled.p`
  color: ${theme.colors.Font02};
`;

export const LinkText = styled.a`
  color: ${theme.colors.Primary01};
`;

export const GroupingHeaders = styled.h3`
  font-size: 1.1rem;
  font-weight: 700;
`;

interface PageTextsProps {
  heading?: string;
  subheading?: string;
  help?: boolean;
}

const PageTextsContainer = styled.div`
  display: grid;
  gap: 0.85em;
`;

export const PageTexts: React.FC<PageTextsProps> = ({
  heading,
  subheading,
  help,
}) => (
  <PageTextsContainer>
    {heading && <HeadingText>{heading}</HeadingText>}
    {subheading && <SubHeadingText>{subheading}</SubHeadingText>}
    {/* {help && <LinkText>Important Information</LinkText>} */}
  </PageTextsContainer>
);

export const Container = styled.div`
  display: grid;
  gap: 2em;
`;

export const AllocationsContainer = styled.div`
  display: grid;
  gap: 1.2em;
`;

export const AddItemCardContainer = styled.div`
  display: grid;
  gap 1em;
`;

export const TextButton = styled.button`
  color: ${theme.colors.Primary01};
  font-size: 0.8em;
`;
