import { AxiosStatic } from "axios";

const logout =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async (): Promise<void> => {
    await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/logout`,
      {},
      {
        withCredentials: true,
      }
    );
  };

export default logout;
