import * as Sentry from "@sentry/react";

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // This method allow us to change the store before we send it to sentry
  stateTransformer: (state) => {
    const sentryState: Record<string, string> = {};

    // If we send the store as is, Sentry will stringify it and will only show the first level
    // If we stringify it ourselves, sentry will block it because it is expecting an object and not a string
    // So I'm making an object with the first level keys and stringifying all its content and setting as value
    // It will fool Sentry and we will have the full store on our alert reports
    Object.keys(state).forEach(
      (key: string) => (sentryState[key] = JSON.stringify(state[key]))
    );

    return {
      state: sentryState,
    };
  },
});
