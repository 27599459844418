import * as Sentry from "@sentry/browser";
import { blockedException } from "./logDecisionObject";

export const initSentry = (dsn: string): void => {
  Sentry.init({
    dsn,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()],

    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
    ],

    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],

    beforeSend(event, hint) {
      const error = hint.originalException;

      // It filters the "Non-Error promise rejection captured with value: Timeout" error report
      if (error === "Timeout") return null;

      if (typeof error === "string" && blockedException(error)) {
        return null;
      }

      // When returning null, we tell Sentry to don't log this error
      if (typeof error?.stack === "string" && blockedException(error?.stack)) {
        return null;
      }

      // When returning the event we're telling it to log it.
      return event;
    },
  });
};
