import Text from "../Text";
import { ColorType } from "../../styles/theme";
import React from "react";
import { StyledTag } from "./styled";
import { TagSize } from "./types";

type TagProps = {
  backgroundColor?: ColorType;
  color?: ColorType;
  size?: TagSize;
  text: string;
  testId?: string;
};

export const Tag = ({
  backgroundColor = "SLATE100",
  color = "SLATE900",
  size = "regular",
  text,
  testId = "tag",
}: TagProps): JSX.Element => (
  <StyledTag
    data-testid={testId}
    backgroundColor={backgroundColor}
    color={color}
  >
    <Text
      type={size === "regular" ? "U14" : "U12"}
      color={color}
      data-testid={`${testId}-text`}
    >
      {text}
    </Text>
  </StyledTag>
);

export default Tag;
