import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import Button, {
  ButtonStates,
} from "../../../../legacyComponents/Button/Button.component";
import { theme } from "../../../../styles/legacy-theme";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import stringToArray from "../../../views/forms/giftsAndBequest/utils/stringToArray";

const Title = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

const Description = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1rem;
`;

const InnerContainer = styled.div`
  display: grid;
  gap: 0.7em;
`;

const InputWrapper = styled.div`
  padding: 0.5em 0;
`;

type BequestAllocationItemPropos = {
  title?: string;
  items?: string;
  description?: string;
  buttonText?: string;
  fieldPlaceholder?: string;
  errorMessage?: string;
  onAddItem: (item: string) => void;
};

const GiftItemsListContainer = styled.ul<{ isItemsAdded: boolean }>`
  filter: ${(props) => `grayscale(${props.isItemsAdded ? 0 : 100}%)`};
  opacity: ${(props) => `${props.isItemsAdded ? 100 : 50}%`};
  transition: filter 2s, opacity 1s linear;
`;

const ListDecoration = styled.span`
  letter-spacing: 0.5em;
`;

const BequestAllocationItem = ({
  title = "An Item",
  items = "",
  description,
  buttonText = "Add a new item",
  fieldPlaceholder = "e.g A weeding ring",
  onAddItem,
  errorMessage,
}: BequestAllocationItemPropos): JSX.Element => {
  const [item, setItem] = useState<string>(items || "");
  const [isItemsAdded, setIsItemsAdded] = useState<boolean>(false);

  useEffect(() => {
    setItem(items);
  }, [items]);

  const addItem = () => {
    onAddItem(item);
    setIsItemsAdded(true);
  };

  return (
    <CardContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <InnerContainer>
        <InputWrapper>
          <Input
            onChange={(e) => {
              setItem((e.target as HTMLInputElement).value);
              item.length == 0 && setIsItemsAdded(false);
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                onAddItem("");
              }
            }}
            value={item}
            placeholder={fieldPlaceholder}
            errorMessage={errorMessage}
            data-testid="bequest-allocation-input-field"
          />
        </InputWrapper>
        {item.length > 0 && (
          <div>
            <GiftItemsListContainer isItemsAdded={isItemsAdded}>
              {item.length &&
                stringToArray(item).map((bqItem, i) => (
                  <li key={i}>
                    <ListDecoration>●</ListDecoration>
                    {bqItem.trim()}
                  </li>
                ))}
            </GiftItemsListContainer>
          </div>
        )}
        <Button
          onClick={addItem}
          text={buttonText}
          version={ButtonStates.PRIMARY}
        />
      </InnerContainer>
    </CardContainer>
  );
};

export default BequestAllocationItem;
