import React, { useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { MEDIA_QUERIES } from "../../../components/mediaQueries";
import { theme } from "../../styles/theme";
import CauseCard from "./CauseCard.component";
import { PartnerCharityCauseInterface } from "@ifgengineering/hip-app-domain";

const CausesOutterContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CausesContainer = styled.div`
  display: grid;
  justify-content: center;
  gap: 1em;
`;

const CausesList = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "repeat(2, auto)" : "repeat(4, 1fr)"};
  justify-content: center;
  gap: 1em;
`;

const ViewMoreButton = styled.button`
  color: ${theme.colors.Primary01};
  text-decoration-color: ${theme.colors.Primary01};
  text-decoration: underline;
`;

export interface CausesProps {
  data?: PartnerCharityCauseInterface[];
  handleSelect: (causeId?: string) => void;
  selectedCauses: Set<string | undefined>;
}

const Causes: React.FC<CausesProps> = ({
  data,
  handleSelect,
  selectedCauses,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const [currPage, setCurrPage] = useState(1);
  const causesListRef = useRef<HTMLElement>(
    null
  ) as React.MutableRefObject<HTMLInputElement>;

  if (!data) {
    return null;
  }

  const dataSize = data.length;
  const hidePagination =
    (!isMobile && data.length < 8) || (isMobile && data.length < 4);
  const itemsPerRender = isMobile ? 4 : 8;
  const totalPages = Math.ceil(dataSize / itemsPerRender);
  const end = currPage === totalPages;

  const scrollToLastCauses = () => {
    const lastChild = causesListRef.current.lastElementChild;
    lastChild?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handlePagination = () => {
    if (end) {
      setCurrPage(1);
      return;
    }
    flushSync(() => {
      setCurrPage(currPage + 1);
    });
    scrollToLastCauses();
    return;
  };

  return (
    <CausesOutterContainer>
      <CausesContainer ref={causesListRef}>
        <CausesList isMobile={isMobile}>
          {data.slice(0, itemsPerRender * currPage).map((cause) => (
            <CauseCard
              key={cause.id}
              {...cause}
              onSelect={() => handleSelect(cause.id)}
              selected={selectedCauses.has(cause.id)}
            />
          ))}
        </CausesList>

        {!hidePagination && (
          <ViewMoreButton onClick={handlePagination}>
            {end ? "View less" : "View more"}
          </ViewMoreButton>
        )}
      </CausesContainer>
    </CausesOutterContainer>
  );
};

export default Causes;
