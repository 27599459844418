exports.components = {
  "component---src-wills-pages-app-tsx": () => import("./../../../src/Wills/pages/app.tsx" /* webpackChunkName: "component---src-wills-pages-app-tsx" */),
  "component---src-wills-pages-auth-[page]-tsx": () => import("./../../../src/Wills/pages/auth/[page].tsx" /* webpackChunkName: "component---src-wills-pages-auth-[page]-tsx" */),
  "component---src-wills-pages-auth-index-tsx": () => import("./../../../src/Wills/pages/auth/index.tsx" /* webpackChunkName: "component---src-wills-pages-auth-index-tsx" */),
  "component---src-wills-pages-index-tsx": () => import("./../../../src/Wills/pages/index.tsx" /* webpackChunkName: "component---src-wills-pages-index-tsx" */),
  "component---src-wills-pages-payment-confirmation-tsx": () => import("./../../../src/Wills/pages/payment/confirmation.tsx" /* webpackChunkName: "component---src-wills-pages-payment-confirmation-tsx" */),
  "component---src-wills-pages-payment-purchase-normal-tsx": () => import("./../../../src/Wills/pages/payment/purchase-normal.tsx" /* webpackChunkName: "component---src-wills-pages-payment-purchase-normal-tsx" */),
  "component---src-wills-pages-payment-styled-tsx": () => import("./../../../src/Wills/pages/payment/styled.tsx" /* webpackChunkName: "component---src-wills-pages-payment-styled-tsx" */),
  "component---src-wills-pages-payment-tsx": () => import("./../../../src/Wills/pages/payment.tsx" /* webpackChunkName: "component---src-wills-pages-payment-tsx" */)
}

