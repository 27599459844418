import React, { useState } from "react";
import styled from "styled-components";
import General from "./pages/general";
import Contact from "./pages/contact";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../../../components/Wills/selectors";
import { WillsState } from "../../../../components/Wills/constants/types";

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 327px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export enum AboutYouPages {
  general,
  contact,
}

export type AboutYouChild = {
  setPage: (page: number) => void;
  initialState: WillsState["will"]["userInfo"];
};

const AboutYou: React.FC = () => {
  const [page, setPage] = useState(AboutYouPages.general);
  const initialState = useSelector(userInfoSelector);
  return (
    <Layout>
      <FormWrapper>
        {page === AboutYouPages.general && (
          <General initialState={initialState} setPage={setPage} />
        )}
        {page === AboutYouPages.contact && (
          <Contact initialState={initialState} setPage={setPage} />
        )}
      </FormWrapper>
    </Layout>
  );
};

export default AboutYou;
