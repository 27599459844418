import React from "react";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BequestCharityInterface } from "@ifgengineering/hip-app-domain";
import Input from "../../../../../../legacyComponents/Form/input/Input/Input.component";
import { bqCharitySelector } from "../../../../../../components/Wills/selectors";
import {
  onHideModal,
  showBQPage,
  storePrevBQPage,
} from "../../../../../store/ui/actions";
import withResponsiveModal from "../../utils/withResponsiveModal";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import { GiftsAndBequestPageEnum } from "../../types";
import { updateCharityBequestAPI } from "../../../../../../components/Wills/actions/bequest";
import {
  bqUpdateCharity,
  bqUpdateCharityInList,
} from "../../../../../../components/Wills/actions";
import store from "../../../../../store";

const FormContainer = styled(Form)`
  display: grid;
  // gap: 0.5em;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
  form: {
    errors: BequestCharityInterface;
    touched: BequestCharityInterface;
  };
};

const EditCharity: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const bqCharity = useSelector(bqCharitySelector);
  const initVals = {
    ...bqCharity,
  };

  const { LANDING, CHARITY_ESTATE } = GiftsAndBequestPageEnum;

  return (
    <Formik
      initialValues={initVals}
      onSubmit={async (values) => {
        const payload = { ...bqCharity, ...values };
        delete payload.partnerCharityCauses;
        delete payload.partnerCharity;
        const returnedCharityData = await dispatch(
          updateCharityBequestAPI({ data: payload })
        ).unwrap();
        dispatch(bqUpdateCharity(returnedCharityData));
        dispatch(bqUpdateCharityInList(returnedCharityData));
        dispatch(storePrevBQPage());
        dispatch(showBQPage(CHARITY_ESTATE));
      }}
    >
      {(_) => (
        <FormContainer>
          <Field name="fullName">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                heading="Charity name"
                {...field}
                errorMessage={errors.fullName ? touched.fullName : undefined}
              />
            )}
          </Field>
          <Field name="charityID">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                heading="Charity number (optional)"
                {...field}
                errorMessage={errors.charityID ? touched.charityID : undefined}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, form: { errors, touched } }: FieldType) => (
              <Input
                isLong
                heading="Charity Description"
                description="Add as many details about the charity to help correctly identify it. You can also add causes you would like to support e.g. I would like to support their madrasa programme at my local masjid located in Bradford."
                {...field}
                errorMessage={
                  errors.description ? touched.description : undefined
                }
              />
            )}
          </Field>
          <FormButton
            text="Save & Continue"
            leftArrowLabel="Back"
            type="submit"
            leftArrowOnClick={() => {
              dispatch(showBQPage(LANDING));
              dispatch(onHideModal());
            }}
          />
        </FormContainer>
      )}
    </Formik>
  );
};

export default withResponsiveModal(EditCharity, {
  backPage: "LANDING",
  heading: "Edit charity",
  subheading:
    "Please tell us a little bit about your chosen charity so we can make sure the right people get your donation",
});
