import React, { FC, useState } from "react";
import styled from "styled-components";
import Icon, { IconType } from "@icon-park/react/es/all";
import { ColorType, theme } from "../../styles/theme";
import Text from "../Text";
import { MEDIA_QUERIES } from "../../styles";

const Container = styled.div<{ bgColor: ColorType; closable?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ closable }) => (closable ? "18px 24px" : "8px 16px")};
  background-color: ${({ bgColor }) => theme.colors[bgColor]};
  @media ${MEDIA_QUERIES.TAB_PORT} {
    flex-direction: ${({ closable }) => closable && "column-reverse"};
  }
`;

const CTAContainer = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 18px;
  cursor: pointer;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    position: static;
    margin-bottom: 8px;
    margin-left: auto;
  }
`;

export interface BannerProps {
  text: string | React.ReactElement;
  textColor?: ColorType;
  CTAText?: string;
  CTATextColor?: ColorType;
  testId?: string;
  bgColor?: ColorType;
  onClickCTA?: VoidFunction;
  closable?: boolean;
  closeIcon?: IconType;
  closeIconSize?: number;
  closeIconColor?: ColorType;
  onClickClose?: VoidFunction;
}

export const Banner: FC<BannerProps> = ({
  text,
  textColor = "SLATE900",
  CTAText,
  CTATextColor = "BLUE600",
  onClickCTA,
  bgColor = "CAUTION200",
  testId,
  closable,
  closeIcon = "CloseOne",
  closeIconSize = 20,
  closeIconColor = "ERROR800",
  onClickClose,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  if (isHidden) return null;

  const handleClose = () => {
    setIsHidden(true);
    onClickClose && onClickClose();
  };
  return (
    <Container bgColor={bgColor} closable={closable}>
      <Text type="H16" color={textColor}>
        {text}{" "}
        {CTAText && (
          <CTAContainer>
            <Text
              data-testid={testId}
              as="a"
              type="H16"
              color={CTATextColor}
              onClick={onClickCTA}
            >
              {CTAText}
            </Text>
          </CTAContainer>
        )}
      </Text>
      {closable && (
        <StyledIcon
          type={closeIcon}
          size={closeIconSize}
          fill={theme.colors[closeIconColor]}
          onClick={handleClose}
          data-testid="banner-close-icon"
        />
      )}
    </Container>
  );
};

export default Banner;
