import { Selector } from "./constants/types";

export const getAuthLoading: Selector<boolean> = (state) => state.auth.loading;
export const isAuthenticatedSelector: Selector<boolean> = (state) =>
  state.auth.isAuthenticated;
export const getUserEmail: Selector<string> = (state) =>
  state.auth?.user?.email || "";
export const appRecordsSelector: Selector<{
  track: boolean;
  zakat: boolean;
  wills: boolean;
  compare: boolean;
}> = (state) => state?.auth?.user?.appRecord;
export const neverLoadedAuthSelector: Selector<boolean> = (state) =>
  state.auth.neverLoaded;
