import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import Select, { components, OptionProps } from "react-select";
import { InputWrapper } from "@ifgengineering/component-library";
import Text from "../Text";
import { theme } from "../../styles/theme";
import { customStyles } from "./constants/customStyles";

export type DropDownValue = {
  label: string;
  value: string;
};

type ComboProps = {
  options: DropDownValue[];
  onChange?: (value: DropDownValue | null) => void;
  value?: DropDownValue | null;
  placeholder?: string;
  heading?: string;
  errorMessage?: string;
};

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  cursor: pointer;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

// We've decided to go with react-select and override the styles as it just works much better than anything we'd create in a timely manner
// Accessibility and usability is a huge bonus with this package

const SearchSelect: React.FC<ComboProps> = ({
  options,
  value,
  onChange,
  heading,
  errorMessage,
  placeholder = "Dropdown",
}) => {
  const { Option } = components;
  const IconOption = (props: OptionProps<DropDownValue, false>) => (
    <Option {...props}>
      <OptionContainer>
        <Text
          type={"P14"}
          color={"SLATE800"}
          data-testId={`search-select-item-${props.data.label}`}
        >
          {props.data.label}
        </Text>
        {value === props.data && (
          <Icon type="check" size={17} fill={theme.colors.G300} />
        )}
      </OptionContainer>
    </Option>
  );

  return (
    <InputWrapper heading={heading} errorMessage={errorMessage}>
      <Select
        styles={customStyles}
        value={value?.value ? value : null}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
      />
    </InputWrapper>
  );
};

export default SearchSelect;
