import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain";

export type PortfolioPayload = {
  payload: PortfolioDataInterface;
};

/* 
This reducer takes an entire portfolio object as a payload and sets it to the store replacing all the data.
It is intended to be used to populate the redux store with the result of the query portfolio.
The progressive store updates are performed by each individual portfolio item CRUD cycle
*/

export const updatePortfolioReducer = (
  state: PortfolioDataInterface,
  { payload }: PortfolioPayload
): PortfolioDataInterface => {
  if (!payload) {
    return state;
  }

  const keys = Object.keys(payload) as Array<keyof typeof payload>;

  keys.forEach((key) => {
    if (payload[key] === null) {
      /* 
      When an item of the portfolio is missing, the API still returns the key but with the value as null.
      The entire front-end implementation expects that if there is no data, there is no key for that entity.
      So, to avoid confusing the code, when the specif key has null as a value, 
      I'm deleting it and only saving to the Redux store those we have values for.
      */
      delete payload[key];
    }
  });

  return payload;
};
