import { logException } from "@helpers/logException";
import axios from "axios";
import getRefreshToken from "./getRefreshToken";
const excludedEndpoints = [
  "/auth/refresh-access-token",
  "/auth/2fa/details",
  "/auth/login",
  "/auth/forgot-password/login",
  "/auth/reset-password",
];

const isExcludedEndpoint = (url: string) =>
  excludedEndpoints.some((ex) => {
    const r = new RegExp(ex, "g");
    return url.match(r);
  });

export const axiosErrorInterceptor = (): number => {
  return axios.interceptors.response.use(
    (response) => response,
    async function (e) {
      if (e.response.status === 400 || e.response.status >= 500) {
        logException({
          tag: "axiosErrorInterceptor",
          exception: e,
        });
      }

      const originalRequest = e.config;

      if (!e.response) {
        return Promise.reject(e);
      }

      if (
        e.response.status === 401 &&
        isExcludedEndpoint(originalRequest.url)
      ) {
        return Promise.reject(e);
      }

      if (e.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const res = await getRefreshToken();

        if (res && res.status === 201) {
          return axios(originalRequest);
        }

        if (res && res.status === 401) {
          return Promise.reject(e);
        }
      }

      return Promise.reject(e);
    }
  );
};
