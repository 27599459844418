import axios from "axios";
import { InitializeApi } from "@ifgengineering/client-auth-sdk";
import { CORE_API } from "gatsby-env-variables";

const authSdk = InitializeApi({
  serverURL: CORE_API,
  appAxios: axios,
});

export default authSdk;
