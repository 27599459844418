import React, { useState, useRef, useEffect } from "react";
import { theme } from "../../styles/legacy-theme";
import ArrowDown from "../../assets/icons/keyboard_arrow_down.svg";
import ArrowUp from "../../assets/icons/keyboard_arrow_up.svg";
import Tick from "../../assets/icons/tick.svg";
import Checkbox from "./styled/Checkbox";
import DropDownWrapper from "./styled/DropDownWrapper";
import Container from "./styled/Container";
import DropDownItem from "./styled/DropDownItem";
import DropDownBox from "./styled/DropDownBox";
import StyledText from "./styled/StyledText";
import IconWrapper from "./styled/IconWrapper";
import HeaderContainer from "./styled/HeaderContainer";
import ErrorContainer from "../Form/input/styled/ErrorContainer";
import Heading from "../Form/input/styled/Heading";
import Text from "../Form/input/styled/Text";

export type DropDownValue<V = any> = {
  label: string;
  value: V;
};

type Props = {
  options: DropDownValue[];
  onChange?: (value?: DropDownValue) => void;
  onChangeMulti?: (value: DropDownValue[]) => void;
  multiValue?: DropDownValue[];
  value?: DropDownValue | null;
  placeholder?: string;
  description?: string;
  heading?: string;
  testId?: string;
  errorMessage?: string;
};

const DropDown: React.FC<Props> = ({
  options,
  value,
  multiValue,
  onChange,
  onChangeMulti,
  placeholder,
  description,
  heading,
  testId,
  errorMessage,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const isMulti = !!multiValue;
  const isAllSelected = isMulti && multiValue?.length === options.length;
  const Arrow = open ? ArrowUp : ArrowDown;

  // useEffect to handle outside click event... may be worth making this a hook to reuse
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) =>
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      setOpen(false);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onMultiSelect = (selectedItem: DropDownValue) => {
    if (onChangeMulti && multiValue && !multiValue.includes(selectedItem)) {
      onChangeMulti([...multiValue, selectedItem]);
    } else {
      const newArr =
        multiValue?.filter((item) => item.value !== selectedItem.value) || [];
      onChangeMulti && onChangeMulti(newArr);
    }
  };

  const onSingleSelect = (selectedItem: DropDownValue) => {
    onChange && onChange(selectedItem);
    !isMulti && setOpen(false);
  };

  const onSelect = isMulti ? onMultiSelect : onSingleSelect;

  const getValue = () =>
    isMulti && multiValue?.length
      ? multiValue?.map(({ label }, key) =>
          key !== multiValue.length - 1 ? `${label}, ` : label
        )
      : value?.label || placeholder;

  const handleSelectAll = () =>
    onChangeMulti &&
    (isAllSelected ? onChangeMulti([]) : onChangeMulti(options));

  const handleUnSelectAll = () => onChangeMulti && onChangeMulti([]);

  const renderOptions = () =>
    options.map((option) => {
      const isSelected = isMulti
        ? !!multiValue?.includes(option)
        : option.value === value?.value;
      const testId = option.label.toLowerCase().replace(" ", "-");
      return (
        <DropDownItem
          data-testid={testId}
          key={option.value}
          onClick={() => onSelect(option)}
          isSelected={isSelected}
        >
          {option.label}
          {isSelected && <Tick fill={theme.colors.Font02} />}
        </DropDownItem>
      );
    });

  const renderMultiHeader = () =>
    isMulti && (
      <HeaderContainer>
        <Checkbox
          checked={isAllSelected}
          label="Select all"
          onChange={handleSelectAll}
        />
        <StyledText onClick={handleUnSelectAll} clickable>
          Unselect all
        </StyledText>
      </HeaderContainer>
    );

  return (
    <Container ref={ref}>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      <DropDownWrapper
        hasError={!!errorMessage}
        data-testid={`${testId}-wrapper`}
        clickable
        onClick={() => setOpen((prevState) => !prevState)}
        focused={open}
      >
        <StyledText>{getValue()}</StyledText>
        <IconWrapper>
          <Arrow fill={theme.colors.Font02} />
        </IconWrapper>
      </DropDownWrapper>
      {open && (
        <DropDownBox data-testid={`${testId}-box`}>
          {renderMultiHeader()}
          {renderOptions()}
        </DropDownBox>
      )}
      <ErrorContainer>
        {errorMessage && <Text hasError={!!errorMessage}>{errorMessage}</Text>}
      </ErrorContainer>
    </Container>
  );
};

export default DropDown;
