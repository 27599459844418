import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Input from "../../../../../../legacyComponents/Form/input/Input/Input.component";
import { PageMetaType } from "../../types";
import AddressFields from "../../../../../components/AddressFields";
import { Field, Form, Formik } from "formik";
import { onHideModal, storePrevBQPage } from "../../../../../store/ui/actions";
import { showBQPage } from "../../../../../store/ui/actions";
import { GiftsAndBequestPageEnum } from "../../types";
import FormButton from "../../../../../../legacyComponents/FormButton/FormButton.component";
import withResponsiveModal from "../../utils/withResponsiveModal";
import { bqIndividualSelector } from "../../../../../../components/Wills/selectors";
import { bqAddNewIndividual } from "../../../../../../components/Wills/actions";
import { newIndividualSchema } from "../../formValidations";

export const newInheritorMeta: PageMetaType = {
  heading: "New individual",
  subheading: "",
  backPage: "LANDING",
};

const FormContainer = styled(Form)`
  display: grid;
`;

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

export const NewIndividual: React.FC = () => {
  const { LANDING, ESTATE_ALLOCATION } = GiftsAndBequestPageEnum;

  const dispatch = useDispatch();
  const bqIndividual = useSelector(bqIndividualSelector);

  const initVals = {
    firstName: bqIndividual.firstName,
    lastName: bqIndividual.lastName,
    emailAddress: bqIndividual.emailAddress,
    addressLine1: bqIndividual?.address?.addressLine1,
    addressLine2: bqIndividual?.address?.addressLine2,
    city: bqIndividual?.address?.city,
    postcode: bqIndividual?.address?.postcode,
    relationship: bqIndividual.relationship,
  };

  return (
    <Formik
      initialValues={initVals}
      validationSchema={newIndividualSchema}
      onSubmit={({
        firstName,
        lastName,
        emailAddress,
        relationship,
        addressLine1,
        addressLine2,
        city,
        postcode,
      }) => {
        dispatch(
          bqAddNewIndividual({
            ...bqIndividual,
            firstName,
            lastName,
            emailAddress,
            relationship,
            islamicInheritor: false,
            address: {
              addressLine1,
              addressLine2,
              city,
              postcode,
            },
          })
        );
        dispatch(storePrevBQPage());
        dispatch(showBQPage(ESTATE_ALLOCATION));
      }}
    >
      {({ errors, touched }) => (
        <FormContainer>
          <Field name="firstName">
            {({ field }: FieldType) => (
              <Input
                heading="First name (including any middle names)"
                {...field}
                errorMessage={
                  touched.firstName && errors.firstName ? errors.firstName : ""
                }
              />
            )}
          </Field>
          <Field name="lastName">
            {({ field }: FieldType) => (
              <Input
                heading="Last name"
                {...field}
                errorMessage={
                  errors.lastName && touched.lastName ? errors.lastName : ""
                }
              />
            )}
          </Field>

          <AddressFields
            isOpen={Boolean(
              touched.addressLine1 || touched.city || touched.postcode
            )}
          />

          <Field name="emailAddress">
            {({ field }: FieldType) => (
              <Input
                type="email"
                heading="Email"
                {...field}
                errorMessage={
                  errors.emailAddress && touched.emailAddress
                    ? errors.emailAddress
                    : ""
                }
              />
            )}
          </Field>
          <Field name="relationship">
            {({ field }: FieldType) => (
              <Input
                heading="Relationship"
                {...field}
                errorMessage={
                  errors.relationship && touched.relationship
                    ? errors.relationship
                    : ""
                }
              />
            )}
          </Field>
          <FormButton
            text="Save & Continue"
            leftArrowLabel="Back"
            type="submit"
            leftArrowOnClick={() => {
              dispatch(showBQPage(LANDING));
              dispatch(onHideModal());
            }}
          />
        </FormContainer>
      )}
    </Formik>
  );
};

export default withResponsiveModal(NewIndividual, newInheritorMeta);
