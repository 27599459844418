import React from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../../store";
import {
  bqAddCharityToList,
  bqUpdateCharity,
  bqUpdateCharityInList,
} from "@components/Wills/actions";
import { updateCharityBequestAPI } from "@components/Wills/actions/bequest";
import { bqCharitySelector } from "@components/Wills/selectors";
import { showBQPage } from "@willsStore/ui/actions";
import { prevBQPage } from "@willsStore/ui/selectors";
import WhatWouldYouLikeToLeave, {
  WhatWouldYouLikeToLeaveBequest,
} from "../../forms/WhatWouldYouLikeToLeave";
import { GiftsAndBequestPageEnum } from "../../types";

const BequestCharityEstate: React.FC = () => {
  const bqCharity = useSelector(bqCharitySelector);
  const previousPage = useSelector(prevBQPage);
  const dispatch = useDispatch<typeof store.dispatch>();

  const { LANDING, NEW_CHARITY, EDIT_CHARITY } = GiftsAndBequestPageEnum;

  const handleSubmit = async (values: WhatWouldYouLikeToLeaveBequest) => {
    const payload = { ...bqCharity, ...values };
    delete payload.partnerCharityCauses;
    delete payload.partnerCharity;
    const returnedCharityData = await dispatch(
      updateCharityBequestAPI({
        data: payload,
      })
    ).unwrap();

    switch (previousPage) {
      case NEW_CHARITY:
        dispatch(bqUpdateCharity(returnedCharityData));
        dispatch(bqAddCharityToList(returnedCharityData));
        break;
      case EDIT_CHARITY:
        dispatch(bqUpdateCharity(returnedCharityData));
        dispatch(bqUpdateCharityInList(returnedCharityData));
        break;
      default:
        break;
    }

    dispatch(showBQPage(LANDING));
  };

  return (
    <WhatWouldYouLikeToLeave
      bequest={bqCharity}
      charityName={bqCharity.fullName}
      onSubmit={(values) => handleSubmit(values)}
      leftArrowOnClick={() => dispatch(showBQPage(LANDING))}
    />
  );
};

export default BequestCharityEstate;
